"use client";
import { IRootState, useAppStore } from "app/_contexts/ReduxProvider";
import { useIsMobile } from "app/_hooks/useIsMobile";
import ILog from "app/_lib/global/Log";
import { useSearchParams } from "next/navigation";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useLocalStorage } from "usehooks-ts";

interface DragNavProps {
   topSlot: React.ReactNode;
   bottomSlot: React.ReactNode;
   dragKey: string;
   minHeight: number;
}

export default function TopDragNav(props: DragNavProps) {
   const { dragKey, minHeight } = props;
   const [isDragging, setIsDragging] = useLocalStorage(dragKey, false);
   const topPx = useSelector((state: IRootState) => state.app.topNavPx);
   const [startingTopPx, setStartingTopPx] = useState(0);
   const [endingTopPx, setEndingTopPx] = useState(0);
   const bottomNavOpen = useSelector((state: IRootState) => state.app.bottomNavOpen);
   const livekitToken = useSelector((state: IRootState) => state.meeting.livekitToken);
   const inMeeting = !!livekitToken;
   const searchParams = useSearchParams();
   const password = searchParams.get("password");
   const onJoinPage = !!password && !!inMeeting;
   const store = useAppStore();
   const { isMobile, height } = useIsMobile();
   ILog.v("TopDragNav.tsx", { topPx, inMeeting, topSlot: props.topSlot, bottomNavOpen, livekitToken });

   return (
      // grid grid-cols-1 grid-rows-[minmax(0px,max-content)_10px_1fr]
      <div id="top-drag-nav" className={` flex flex-col h-full `}>
         {inMeeting && (
            <Fragment>
               <div
                  id="top-drag-nav-top-slot"
                  style={{ height: bottomNavOpen && !onJoinPage ? (isMobile ? `${height / (bottomNavOpen ? 2 : 1)}px` : `${topPx}px`) : undefined }}
                  className={`h-full ${bottomNavOpen && !onJoinPage ? "" : ""}`}
               >
                  {props.topSlot}
               </div>
               {!onJoinPage && !isMobile && (
                  <div id="top-drag-nav-handle" className="h-[10px] ">
                     <div className="flex flex-col h-full">
                        <div
                           draggable={true}
                           onPointerMove={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              if (!isDragging) return;

                              if (topPx >= minHeight && e.clientY < minHeight) return;
                              if (e.clientY > window.innerHeight - minHeight) return;
                              setEndingTopPx(e.clientY);
                              if (e.clientY === topPx) return;
                              store.dispatch({ type: "app/setTopNavPx", payload: e.clientY });
                           }}
                           onPointerUp={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              // setEndingTopPx(e.clientY);
                              // ILog.v("TopDragNav_onPointerUp", { e, timestamp: e.timeStamp });
                              e.currentTarget.releasePointerCapture(e.pointerId);

                              setIsDragging(false);
                              store.dispatch({ type: "app/setIsDragging", payload: false });

                              setStartingTopPx(0);
                              setEndingTopPx(0);
                              if (endingTopPx !== startingTopPx) return;
                              ILog.v("TopDragNav_onPointerUp", { e, bool: endingTopPx === startingTopPx });
                              store.dispatch({ type: "app/setBottomNavOpen", payload: !bottomNavOpen });
                           }}
                           onPointerDown={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setStartingTopPx(e.clientY);
                              setEndingTopPx(e.clientY);
                              ILog.v("TopDragNav_onPointerDown", { e, timestamp: e.timeStamp });
                              e.currentTarget.setPointerCapture(e.pointerId);
                              store.dispatch({ type: "app/setIsDragging", payload: true });
                              setIsDragging(true);
                           }}
                           // rounded-b-md
                           className={"w-[100%] bg-slate-300 focused:bg-yellow-600 hover:bg-yellow-600 h-[10px] hover:cursor-ns-resize "}
                        />
                     </div>
                  </div>
               )}
            </Fragment>
         )}

         {(bottomNavOpen || !inMeeting) && !onJoinPage && (
            <div id="top-drag-nav-bottom-slot" className="w-full overflow-y-auto grow">
               {props.bottomSlot}
            </div>
         )}
      </div>
   );
}
