import { VariantProps, cva } from "class-variance-authority";
import * as React from "react";
import { useMemo } from "react";
import { cn } from "../../_styles/tailwindUtils";

const listIteratorVariants = cva("flex flex-col", {
   variants: {
      variant: {
         default: ""
      },
      size: {
         default: "",
         sm: "",
         lg: ""
      }
   },
   defaultVariants: {
      variant: "default",
      size: "default"
   }
});

export interface ListIteratorProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof listIteratorVariants> {
   listLabel?: string;
   items?: any[];
   noLengthLabel?: string;
   property?: string;
}

const ListIterator = React.forwardRef<HTMLDivElement, ListIteratorProps>(({ property, items, listLabel, noLengthLabel, className, children, variant, size, ...props }, ref) => {
   const { labels } = useMemo(() => {
      if (property && items && items?.length > 0) {
         return {
            labels: items.map((item) => {
               const label = item[property] as string;
               return label;
            })
         };
      } else {
         return {
            labels: []
         };
      }
   }, [items]);

   if (labels && labels?.length > 0) {
      return (
         <div className={cn(listIteratorVariants({ variant, size, className }))}>
            {listLabel && <h5 className="mb-2 text-xl">{listLabel}</h5>}
            {labels.map((item, index) => (
               <p key={`${item}-${index}`}>{item}</p>
            ))}
         </div>
      );
   }
   return (
      <div className={cn(listIteratorVariants({ variant, size, className }))}>
         <p>{noLengthLabel}</p>
      </div>
   );
});
ListIterator.displayName = "ListIterator";

export { ListIterator, listIteratorVariants };
