"use client";
import { MeetingContext } from "app/(platform)/meeting-room/_contexts/MeetingContext";
import { R } from "app/(platform)/meeting-room/_types";
import Header from "app/_components_v2/data-display/Header";
import _Button from "app/_components_v2/inputs/_Button";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";
import { LocalParticipant, Participant } from "livekit-client";
import { useContext } from "react";
import { toast } from "react-toastify";

export default function ButtonListeningRequest(props: { listeningRequest: R.ListeningRequest.ListeningRequest; localParticipant: LocalParticipant; participants: Participant[] }) {
   const { listeningRequest } = props;
   const { LK } = useContext(MeetingContext);

   if (listeningRequest) {
      return (
         <_DivUNSAFE className="flex flex-col gap-3 p-3 rounded-md h-fit w-fit">
            <Header level="h3" label={`Listen to ${listeningRequest.speakerName}?`} />

            <_DivUNSAFE className="flex flex-row gap-3 flex-nowrap">
               <_Button
                  variant="primary"
                  id="accept-listening-request-button"
                  testId={`accept-listening-request-button-${listeningRequest.speakerName}`}
                  // className="p-2 rounded-md w-14 bg-slate-300 text-slate-900"
                  value={listeningRequest.speakerIdentity}
                  data-listeningrequestid={listeningRequest.id}
                  data-speakeridentity={listeningRequest.speakerIdentity}
                  data-speakername={listeningRequest.speakerName}
                  data-turnid={listeningRequest.turnId}
                  onButtonClick={(e) => {
                     e.preventDefault();
                     LK.ListeningRequest({ listeningRequest: listeningRequest }).acceptListeningRequest();
                     //      LK.Turn({turn: {}}).createTurn({breakoutId: currentBreakout?.id})
                     toast.dismiss(listeningRequest?.id);
                  }}
               >
                  Yes
               </_Button>
               <_Button
                  variant="primary"
                  id="decline-listening-request-button"
                  testId={`decline-listening-request-button-${listeningRequest.speakerName}`}
                  className="p-2 bg-red-400 rounded-md w-14 text-slate-900"
                  value={listeningRequest.speakerIdentity}
                  data-listeningrequestid={listeningRequest.id}
                  data-speakeridentity={listeningRequest.speakerIdentity}
                  data-speakername={listeningRequest.speakerName}
                  data-turnid={listeningRequest.turnId}
                  onButtonClick={(e) => {
                     e.preventDefault();

                     LK.ListeningRequest({ listeningRequest: listeningRequest }).declineListeningRequest();
                     toast.dismiss(listeningRequest?.id);
                  }}
               >
                  No
               </_Button>
            </_DivUNSAFE>
         </_DivUNSAFE>
      );
   }
   return null;
}
