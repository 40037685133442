import { CheckIcon } from "@heroicons/react/24/outline";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import { R } from "app/(platform)/meeting-room/_types";
import Header from "app/_components_v2/data-display/Header";
import _Paragraph from "app/_components_v2/data-display/Paragraph";
import _Icon from "app/_components_v2/data-display/_Icon";
import DefaultXIcon from "app/_components_v2/icons/DefaultXIcon";
import LeanInput from "app/_components_v2/inputs/LeanInput";
import _Button from "app/_components_v2/inputs/_Button";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";
import _Stack from "app/_components_v2/layout/_Stack";
import ErrorBase from "app/_components_v2/todo/Error";
import ILog from "app/_lib/global/Log";
import { DateTime } from "luxon";
import React, { BaseSyntheticEvent, useContext, useEffect, useMemo, useState } from "react";
import { Controller, FieldErrorsImpl, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { useCurrentBreakout, useIsLocalFacilitator } from "../_contexts/BreakoutContext";
import { MeetingContext } from "../_contexts/MeetingContext";
import inputCalculation from "../_helpers/inputCalculation";
import outputCalculation from "../_helpers/outputCalculation";
import { selectActiveAgendaItem, selectAgendaItemStatus, selectAgendaItemsByBreakoutId, selectNextAgendaItem } from "../_services/agendaItemSlice";
import { selectRoundsByAgendaItemId } from "../_services/roundSlice";
import ContainerRounds from "./menu/ContainerRounds";
import { IRootState } from "app/_contexts/ReduxProvider";

function Agenda() {
   const currentBreakout = useCurrentBreakout();
   const agendaItems = useSelector((state: IRootState) => selectAgendaItemsByBreakoutId(state, currentBreakout?.id));

   ILog.v("Agenda", { currentBreakout, agendaItems });
   if (agendaItems.length === 0) {
      return <Header className="text-slate-900" label={`No Agenda Items.`} level="h3" />;
   }

   return (
      <_Stack padding={"sm"}>
         <Agenda.Adjourned />
         <Agenda.Start />
         {agendaItems.map((item, index) => (
            <Agenda.Card key={item.id} agendaItemIndex={index} defaultValues={item} />
         ))}
      </_Stack>
   );
}

function Start() {
   const { LK } = useContext(MeetingContext);
   const currentBreakout = useCurrentBreakout();
   const isLocalFacilitator = useIsLocalFacilitator();
   const agendaItems = useSelector((state: IRootState) => selectAgendaItemsByBreakoutId(state, currentBreakout?.id));

   if (isLocalFacilitator && currentBreakout && !currentBreakout.agendaStarted && agendaItems.length > 0) {
      return (
         <_Button
            variant="primary"
            type="button"
            id="buttonStartAgenda"
            testId="start-agenda-button"
            // className="m-2 w-[80%] place-self-center rounded-md bg-slate-300 p-2 text-slate-900 hover:drop-shadow-md"
            onButtonClick={(e) => {
               e.preventDefault();
               LK.AgendaItem({ agendaItem: {} }).startAgenda(currentBreakout.id);
            }}
         >
            Start Agenda
         </_Button>
      );
   }
   return null;
}

function End() {
   const currentBreakout = useCurrentBreakout();
   const isLocalFacilitator = useIsLocalFacilitator();
   const agendaItems = useSelector((state: IRootState) => selectAgendaItemsByBreakoutId(state, currentBreakout?.id));

   return null;
}

function Adjourned() {
   const meetingAdjourned = useSelector((state: IRootState) => state.meeting.meetingAdjourned);
   if (meetingAdjourned) {
      return <Header label="Meeting Adjourned" id="meetingAdjourned" className="p-4 text-xl text-slate-900" />;
   }
   return null;
}

function Card(props: { defaultValues: R.AgendaItem.AgendaItem; agendaItemIndex: number }) {
   const dispatch = useDispatch();

   // instead of passing room through props, which will trigger renders, just get room data from redux
   const { defaultValues, agendaItemIndex } = props;
   const { empathyCafe, LK } = useContext(MeetingContext);
   const [editingItem, setEditingItem] = useState(false);
   const currentBreakout = useCurrentBreakout();
   const isLocalFacilitator = useIsLocalFacilitator();

   const status = useSelector((state: IRootState) => selectAgendaItemStatus(state, defaultValues?.id));
   const rounds = useSelector((state: IRootState) => selectRoundsByAgendaItemId(state, defaultValues?.id));
   const activeRound = rounds.find((round) => round?.roundActive);
   const activeAgendaItem = useSelector((state: IRootState) => selectActiveAgendaItem(state, currentBreakout?.id));
   const isActiveAgendaItem = activeAgendaItem && activeAgendaItem.id === defaultValues.id;
   const nextAgendaItem = useSelector((state: IRootState) => selectNextAgendaItem(state, currentBreakout?.id));
   const isNextAgendaItem = nextAgendaItem && nextAgendaItem.id === defaultValues.id;
   const empathyCafeModalOpen = useSelector((state: IRootState) => state.meeting.empathyCafeModalOpen);

   useEffect(() => {
      if (empathyCafeModalOpen) {
         setEditingItem(true);
      }
   }, [empathyCafeModalOpen]);

   const handleCompleteEdit = () => {
      setEditingItem(false);
      dispatch({ type: "meeting/setEditingAgendaItem", payload: false });
      dispatch({ type: "meeting/setEmpathyCafeModalOpen", payload: false });
   };
   ILog.v("Card_Agenda", {
      defaultValues,
      agendaItemIndex,
      editingItem,
      currentBreakout,
      isLocalFacilitator,
      status,
      rounds,
      activeRound,
      activeAgendaItem,
      isActiveAgendaItem,
      nextAgendaItem,
      isNextAgendaItem,
      empathyCafeModalOpen
   });
   if (!currentBreakout?.id) return null;
   return (
      <_Stack data-index={`agendaItem-${agendaItemIndex}`} testId={`agenda-item-${defaultValues?.title}`}>
         {!editingItem && (
            <_DivUNSAFE className="flex flex-col transition-all duration-100 ease-in-out hover:border-l-2 border-slate-900">
               <_DivUNSAFE className="flex flex-col items-start">
                  <_Stack gap="sm" flexBehavior={"row"}>
                     <Header label={`${defaultValues?.title}`} className="text-lg " level="h3" />
                     {editingItem === false && isLocalFacilitator && !defaultValues.itemComplete && (
                        <_Icon textColor={"primary"} size={"xs"}>
                           {({ iconClassname }) => (
                              <PencilSquareIcon
                                 className={iconClassname}
                                 id={`buttonEditAgendaItem-${agendaItemIndex}`}
                                 type="button"
                                 onMouseDown={() => {
                                    setEditingItem(true);
                                    dispatch({
                                       type: "meeting/setEditingAgendaItem",
                                       payload: true
                                    });
                                 }}
                              />
                           )}
                        </_Icon>
                     )}

                     {defaultValues.itemComplete && (
                        <_Icon textColor={"primary"} size={"xs"} disabled={"noPointer"}>
                           {({ iconClassname }) => <CheckIcon className={iconClassname} data-testid={`agenda-item-complete-${defaultValues?.title}`} />}
                        </_Icon>
                     )}
                  </_Stack>
                  <_DivUNSAFE className="whitespace-pre-wrap place-self-start">{`${defaultValues.description}`}</_DivUNSAFE>
               </_DivUNSAFE>
               {status === "Active" && !activeRound && (
                  <_DivUNSAFE data-testid={`agenda-item-active-${defaultValues?.title}`} className="flex flex-row w-full border-t-4 border-red-300">
                     <_DivUNSAFE className="flex flex-row w-full border-t-4 border-red-300" />
                     <_Paragraph className="p-1 text-red-300 rounded-md" label={`Active`} />
                  </_DivUNSAFE>
               )}
               {!empathyCafe && isLocalFacilitator && isActiveAgendaItem && (
                  <_Button
                     variant="primary"
                     type="button"
                     id="end-agenda-item-button"
                     testId="end-agenda-item-button"
                     // className="p-2 m-1 rounded-md bg-slate-300 text-slate-900 hover:cursor-pointer hover:drop-shadow-md"
                     onButtonClick={(e) => {
                        e.preventDefault();
                        LK.AgendaItem({ agendaItem: {} }).endAgendaItem(currentBreakout?.id);
                     }}
                  >
                     {`End Agenda Item`}
                  </_Button>
               )}
               {!empathyCafe && isLocalFacilitator && currentBreakout && currentBreakout.agendaStarted && !activeAgendaItem && isNextAgendaItem && (
                  <_Button
                     variant="primary"
                     type="button"
                     id="start-agenda-item-button"
                     testId="start-agenda-item-button"
                     aria-label="Start Agenda Item"
                     // className="p-2 m-1 rounded-md bg-slate-300 text-slate-900 hover:cursor-pointer hover:drop-shadow-md"
                     onButtonClick={(e) => {
                        e.preventDefault();
                        LK.AgendaItem({ agendaItem: {} }).startAgendaItem(currentBreakout?.id);
                     }}
                  >
                     {`Start Agenda Item`}
                  </_Button>
               )}
               {rounds?.length > 0 && <ContainerRounds rounds={rounds} agendaItemIndex={agendaItemIndex} agendaItemComplete={defaultValues.itemComplete} />}
            </_DivUNSAFE>
         )}
         {editingItem === true && isLocalFacilitator && !defaultValues.itemComplete && (
            <Agenda.Form defaultAgendaItemValues={defaultValues} defaultRoundValues={rounds} existingItem={true} handleStartEdit={undefined} handleCompleteEdit={handleCompleteEdit} />
         )}
      </_Stack>
   );
}
const schema = yup.object().shape({
   id: yup.string().required(),
   breakoutId: yup.string().required(),
   void: yup.boolean().notRequired(),
   empathyCafe: yup.boolean(),
   empathyCafeConfig: yup
      .object()
      .shape({
         agendaItemId: yup.string().notRequired(),
         empathyMode: yup.string().notRequired(),
         strictTime: yup.boolean().notRequired(),
         speakerTime: yup.number().notRequired(),
         intermissionTime: yup.number().notRequired(),
         updated: yup.number().notRequired()
      })
      .notRequired()
      .nullable(),
   title: yup.string().min(1).max(125).required(),
   description: yup.string().min(0).max(255),
   itemComplete: yup.boolean().required(),
   itemActive: yup.boolean().required(),
   itemPaused: yup.boolean().required(),
   presenterTime: yup.number().min(0).max(3600),
   totalTime: yup.number(),
   sort: yup.number(),
   created: yup.number(),
   updated: yup.number(),
   rounds: yup.array().of(
      yup.object().shape({
         id: yup.string().required(),
         breakoutId: yup.string().required(),
         agendaItemId: yup.string().required(),
         roundComplete: yup.boolean().notRequired(),
         roundActive: yup.boolean().notRequired(),
         roundPaused: yup.boolean().notRequired(),
         empathyMode: yup.string().notRequired(),
         strictTime: yup.boolean().notRequired(),
         speakerTime: yup.number().notRequired(),
         intermissionTime: yup.number().notRequired(),
         created: yup.number()
      })
   )
});
function Form(props: { defaultRoundValues?: R.Round.Round[]; defaultAgendaItemValues: R.AgendaItem.AgendaItem; existingItem: boolean; handleStartEdit?: any; handleCompleteEdit?: any }) {
   const { empathyCafe, LK } = useContext(MeetingContext);
   const currentBreakout = useCurrentBreakout();
   const isLocalFacilitator = useIsLocalFacilitator();
   // TODO TEST defaultAgendaItemValues.id may be incorrect
   const { defaultAgendaItemValues, defaultRoundValues, existingItem, handleCompleteEdit } = props;

   const propsDefaultValues = {
      ...defaultAgendaItemValues,
      rounds: defaultRoundValues?.map((round) => {
         return {
            ...round,
            roundPaused: round.roundPaused || false
         };
      })
   };
   const [editingItem, setEditingItem] = useState(false);
   const editingAgendaItem = useSelector((state: IRootState) => state.meeting.editingAgendaItem);

   const {
      register,
      handleSubmit,
      watch,
      getValues,
      control: parentControl,
      formState: { errors, isSubmitted, isValid, isSubmitSuccessful, isDirty, dirtyFields },
      reset
   } = useForm({
      resolver: yupResolver(schema),
      // TODO TEST: see if rounds property is passing in through propsDefaultValues
      defaultValues: propsDefaultValues
   });

   // Possible append(rounds) needs to come back
   const { fields, append, prepend, remove, swap, move, insert, replace, update } = useFieldArray({
      control: parentControl,
      name: "rounds"
   });

   useEffect(() => {
      if (existingItem) {
      }
   }, [existingItem, propsDefaultValues, defaultAgendaItemValues]);

   /*
    useEffect(() => {
       if (isSubmitSuccessful) {
          reset();
       }
    }, [isSubmitSuccessful, reset]);
    */

   const onSubmit = (data: any, e: any) => {
      if (!currentBreakout?.id) throw new Error("No breakout id found");
      if (existingItem && empathyCafe) {
         LK.sendDataPacket({
            payload: {
               type: "agendaItem/updateAgendaItem",
               payload: {
                  id: data.id,
                  breakoutId: currentBreakout.id,
                  empathyCafe: data.empathyCafe,
                  empathyCafeConfig: {
                     agendaItemId: data.empathyCafeConfig.agendaItemId,
                     empathyMode: data.empathyCafeConfig.empathyMode,
                     strictTime: data.empathyCafeConfig.strictTime,
                     speakerTime: data.empathyCafeConfig.speakerTime,
                     intermissionTime: data.empathyCafeConfig.strictTime === true ? data.empathyCafeConfig.intermissionTime : 0
                  },
                  title: data.title,
                  description: data.description,
                  itemComplete: data.itemComplete,
                  itemActive: data.itemActive,
                  itemPaused: data.itemPaused,
                  presenterTime: data.presenterTime,
                  totalTime: data.totalTime,
                  sort: data.sort,
                  created: data.created,
                  updated: DateTime.now().toMillis(),
                  fallback: {
                     id: data.id,
                     title: data.title,
                     description: data.description,
                     itemComplete: data.itemComplete,
                     itemActive: data.itemActive,
                     itemPaused: data.itemPaused,
                     presenterTime: data.presenterTime,
                     totalTime: data.totalTime,
                     sort: data.sort,
                     created: data.created,
                     updated: DateTime.now().toMillis(),
                     void: false
                  }
               }
            }
         });

         handleCompleteEdit();
         setEditingItem(false);
      } else if (existingItem) {
         LK.sendDataPacket({
            payload: {
               type: "agendaItem/updateAgendaItem",
               payload: {
                  id: data.id,
                  breakoutId: currentBreakout?.id,
                  title: data.title,
                  description: data.description,
                  itemComplete: data.itemComplete,
                  itemActive: data.itemActive,
                  itemPaused: data.itemPaused,
                  presenterTime: data.presenterTime,
                  totalTime: data.totalTime,

                  sort: data.sort,
                  created: data.created,
                  updated: DateTime.now().toMillis(),
                  fallback: {
                     id: data.id,
                     title: data.title,
                     description: data.description,
                     itemComplete: data.itemComplete,
                     itemActive: data.itemActive,
                     itemPaused: data.itemPaused,
                     presenterTime: data.presenterTime,
                     totalTime: data.totalTime,

                     sort: data.sort,
                     created: data.created,
                     updated: DateTime.now().toMillis(),
                     void: false
                  }
               }
            }
         });

         const existingRounds = defaultRoundValues;
         const deletedRounds = existingRounds?.filter(
            (round) => !data.rounds.some((r: R.Round.Round) => r.id === round.id) // if the round id is not in the data.rounds array, then it has been deleted
         ); // get the rounds that have been deleted
         deletedRounds?.forEach((round) => {
            LK.sendDataPacket({
               payload: {
                  type: "round/removeRound",
                  payload: round.id
               }
            });
         });
         const updatedAndNewRounds = data.rounds;

         LK.sendDataPacket({
            payload: {
               type: "round/upsertRounds",
               payload: updatedAndNewRounds
            }
         });
         handleCompleteEdit();
         setEditingItem(false);
      } else if (!existingItem) {
         LK.sendDataPacket({
            payload: {
               type: "agendaItem/addAgendaItem",
               payload: {
                  id: getValues("id"),
                  title: data.title,
                  breakoutId: currentBreakout?.id,
                  description: data.description,
                  itemComplete: data.itemComplete,
                  itemActive: data.itemActive,
                  itemPaused: data.itemPaused,
                  presenterTime: data.presenterTime,
                  totalTime: data.totalTime,
                  empathyCafe: data.empathyCafe,
                  created: DateTime.now().toMillis(),
                  sort: data.sort,
                  void: false
               } satisfies R.AgendaItem.AgendaItem
            }
         });
         const rounds = data.rounds;
         rounds.forEach((round: R.Round.Round) => {
            LK.sendDataPacket({
               payload: {
                  type: "round/addRound",
                  payload: round
               }
            });
         });
         setEditingItem(false);
         handleCompleteEdit();
      }
   };

   const onError = (errors: Partial<FieldErrorsImpl<{ [x: string]: any }>>, e: BaseSyntheticEvent<object, any, any> | undefined) => {};
   useEffect(() => {
      if (existingItem) {
         setEditingItem(true);
      }
   }, [existingItem]);

   const handleReset = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setEditingItem(false);
      // Warning: This reset() is sus
      reset();
      if (existingItem) {
         handleCompleteEdit();
      }
   };

   const handleAdd = () => {
      reset({
         id: uuidv4(),
         breakoutId: currentBreakout?.id,
         title: "",
         empathyCafe: false,
         description: "",
         itemComplete: false,
         itemActive: false,
         itemPaused: false,
         presenterTime: 0,
         totalTime: 0,
         sort: 0,
         created: DateTime.now().toMillis(),
         void: false,
         rounds: []
      });
   };

   const { empathyCafeStrictTime } = useMemo(() => {
      const empathyCafe = getValues("empathyCafe");
      if (empathyCafe) {
         const strictTime = getValues("empathyCafeConfig.strictTime");
         return {
            empathyCafeStrictTime: strictTime
         };
      }
      return {
         empathyCafeStrictTime: false
      };
   }, [isDirty, dirtyFields]);

   if (isLocalFacilitator) {
      return (
         <_DivUNSAFE className="flex justify-center ">
            {!empathyCafe && !existingItem && editingItem === false && editingAgendaItem === false && (
               <_Button
                  variant="primary"
                  type="button"
                  id="add-agenda-item-button"
                  testId="add-agenda-item-button"
                  // className="p-3 m-2 rounded-lg cursor-pointer w-120 h-100 bg-slate-300 hover:drop-shadow-sm"
                  onButtonClick={() => {
                     setEditingItem(true);
                     handleAdd();
                  }}
               >
                  + Agenda Item
               </_Button>
            )}
            {editingItem === true && (
               <form className="grid grid-cols-1 gap-2 p-1 w-[100%] " onSubmit={handleSubmit(onSubmit, onError)} data-form-index={`formAgendaItem-${defaultAgendaItemValues?.id}`}>
                  <_Stack className=" w-[100%]">
                     <Controller
                        control={parentControl}
                        name="title"
                        render={({ field: { onChange, value }, fieldState: { error }, formState }) => {
                           return (
                              <LeanInput.Text
                                 label="Title"
                                 testId={"agenda-item-title-input"}
                                 onChange={onChange}
                                 required
                                 autoComplete="off"
                                 type="text"
                                 defaultValue={defaultAgendaItemValues?.title}
                              />
                           );
                        }}
                     />
                     <Controller
                        control={parentControl}
                        name="description"
                        render={({ field: { onChange, value }, fieldState: { error }, formState }) => {
                           return (
                              <LeanInput.Text
                                 label="Description"
                                 testId={"agenda-item-description-input"}
                                 onChange={onChange}
                                 autoComplete="off"
                                 type="text"
                                 defaultValue={defaultAgendaItemValues?.description}
                              />
                           );
                        }}
                     />

                     {errors && <ErrorBase.Many errors={errors} />}
                     <Header level="h1" label={"Round Settings"} />

                     {empathyCafe ? (
                        <React.Fragment>
                           <_DivUNSAFE className="mb-2">
                              <label className="p-1">
                                 Speaker Time:{" "}
                                 <Controller
                                    render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                                       <>
                                          <select
                                             id={`ec.speaker-minutes-select`}
                                             data-testid={`ec.speaker-minutes-select`}
                                             ref={ref}
                                             className="mr-1 bg-white rounded-md border-slate-200 pr-9"
                                             onChange={(e) => onChange(outputCalculation(e))}
                                             defaultValue={inputCalculation(value ?? 0).minutes}
                                          >
                                             <option value={0}>0</option>
                                             <option value={1}>1</option>
                                             <option value={2}>2</option>
                                             <option value={3}>3</option>
                                             <option value={4}>4</option>
                                             <option value={5}>5</option>
                                             <option value={6}>6</option>
                                             <option value={7}>7</option>
                                             <option value={8}>8</option>
                                             <option value={9}>9</option>
                                             <option value={10}>10</option>
                                          </select>
                                          {":"}{" "}
                                          <select
                                             id={`ec.speaker-seconds-select`}
                                             data-testid={`ec.speaker-seconds-select`}
                                             ref={ref}
                                             className="bg-white rounded-md border-slate-200 pr-9"
                                             onChange={(e) => onChange(outputCalculation(e))}
                                             defaultValue={inputCalculation(value ?? 0).seconds}
                                          >
                                             <option value={0}>00</option>
                                             <option value={15}>15</option>
                                             <option value={30}>30</option>
                                             <option value={45}>45</option>
                                          </select>
                                       </>
                                    )}
                                    name={`empathyCafeConfig.speakerTime`}
                                    control={parentControl}
                                 />
                              </label>
                           </_DivUNSAFE>
                           {empathyCafeStrictTime && (
                              <_DivUNSAFE className="mb-2">
                                 <label className="p-1">
                                    Handoff Time:{" "}
                                    <Controller
                                       render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                                          <>
                                             <select
                                                id={`ec.intermission-minutes-select`}
                                                data-testid={`ec.intermission-minutes-select`}
                                                ref={ref}
                                                className="bg-white border rounded-md border-slate-200 pr-9"
                                                onChange={(e) => onChange(outputCalculation(e))}
                                                defaultValue={inputCalculation(value ?? 0).minutes}
                                             >
                                                <option value={0}>0</option>
                                                <option value={1}>1</option>
                                             </select>
                                             {":"}{" "}
                                             <select
                                                id={`ec.intermission-seconds-select`}
                                                data-testid={`ec.intermission-seconds-select`}
                                                ref={ref}
                                                className="bg-white border rounded-md border-slate-200 pr-9"
                                                onChange={(e) => onChange(outputCalculation(e))}
                                                defaultValue={inputCalculation(value ?? 0).seconds}
                                             >
                                                <option value={0}>00</option>
                                                <option value={5}>05</option>
                                                <option value={10}>10</option>
                                                <option value={15}>15</option>
                                                <option value={20}>20</option>
                                                <option value={25}>25</option>
                                                <option value={30}>30</option>
                                             </select>
                                          </>
                                       )}
                                       name={`empathyCafeConfig.intermissionTime`}
                                       control={parentControl}
                                    />
                                 </label>
                              </_DivUNSAFE>
                           )}
                           {/* <_DivUNSAFE className="mb-2">
                               <label>
                                  Strict Time:{" "}
                                  <Controller
                                     render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                                        <>
                                           <input
                                              type="checkbox"
                                              className="p-2 bg-white border rounded-md border-slate-200"
                                              onChange={onChange}
                                              onBlur={onBlur} // notify when input is touched
                                              value={value === true ? 1 : 0}
                                              // return updated value
                                              checked={value} // return updated value
                                              // defaultValue={value} // set default value
                                              ref={ref} // set ref for focus management
                                           ></input>
                                        </>
                                     )}
                                     name={`empathyCafeConfig.strictTime`}
                                     control={parentControl}
                                  />
                               </label>
                            </_DivUNSAFE> */}
                        </React.Fragment>
                     ) : (
                        <React.Fragment>
                           <ul className="p-1">
                              {fields.map((item, index) => {
                                 return (
                                    <li key={item.id} className="mt-2 border-t-4 border-slate-200">
                                       {/*                   <label>
                     <input
                       {...register(`rounds.${index}.speakerTime`, {
                         required: true
                       })}
                     />{" "}
                     Speaker Time
                   </label>
  */}
                                       <_DivUNSAFE className="flex flex-row gap-3 mt-2 mb-2">
                                          <Controller
                                             render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                                                <React.Fragment>
                                                   <select
                                                      id={`${index}.empathy-mode-select`}
                                                      data-testid={`${index}.empathy-mode-select`}
                                                      className="bg-white rounded-md border-slate-200 pr-9"
                                                      onChange={onChange} // send value to hook form
                                                      onBlur={onBlur} // notify when input is touched
                                                      value={value || undefined} // return updated value
                                                      ref={ref} // set ref for focus management
                                                   >
                                                      <option data-testid={`${index}.empathy-mode-active`} value={"active"}>
                                                         Listening
                                                      </option>
                                                      <option data-testid={`${index}.empathy-mode-reflective`} value={"reflective"}>
                                                         Active Listening
                                                      </option>
                                                   </select>{" "}
                                                   <_Button
                                                      variant={"primary"}
                                                      size="none"
                                                      testId={`remove-round-${index}`}
                                                      // className="p-1 m-1 bg-red-400 rounded-lg cursor-pointer hover:drop-shadow-sm"
                                                      type="button"
                                                      onButtonClick={() => remove(index)}
                                                   >
                                                      <_Icon variant={"transparent"} size="sm">
                                                         {({ iconClassname }) => <DefaultXIcon />}
                                                      </_Icon>
                                                   </_Button>
                                                </React.Fragment>
                                             )}
                                             name={`rounds.${index}.empathyMode`}
                                             control={parentControl}
                                          />
                                       </_DivUNSAFE>
                                       <_DivUNSAFE className="mb-2">
                                          <label className="p-1">
                                             Speaker Time:{" "}
                                             <Controller
                                                render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                                                   <React.Fragment>
                                                      <select
                                                         id={`${index}.speaker-minutes-select`}
                                                         data-testid={`${index}.speaker-minutes-select`}
                                                         ref={ref}
                                                         className="mr-1 bg-white rounded-md border-slate-200 pr-9"
                                                         onChange={(e) => onChange(outputCalculation(e))}
                                                         defaultValue={inputCalculation(value ?? 0).minutes}
                                                      >
                                                         <option value={0}>0</option>
                                                         <option value={1}>1</option>
                                                         <option value={2}>2</option>
                                                         <option value={3}>3</option>
                                                         <option value={4}>4</option>
                                                         <option value={5}>5</option>
                                                         <option value={6}>6</option>
                                                         <option value={7}>7</option>
                                                         <option value={8}>8</option>
                                                         <option value={9}>9</option>
                                                         <option value={10}>10</option>
                                                      </select>
                                                      {":"}{" "}
                                                      <select
                                                         id={`${index}.speaker-seconds-select`}
                                                         data-testid={`${index}.speaker-seconds-select`}
                                                         ref={ref}
                                                         className="bg-white rounded-md border-slate-200 pr-9"
                                                         onChange={(e) => onChange(outputCalculation(e))}
                                                         defaultValue={inputCalculation(value ?? 0).seconds}
                                                      >
                                                         <option value={0}>00</option>
                                                         <option value={15}>15</option>
                                                         <option value={30}>30</option>
                                                         <option value={45}>45</option>
                                                      </select>
                                                   </React.Fragment>
                                                )}
                                                name={`rounds.${index}.speakerTime`}
                                                control={parentControl}
                                             />
                                          </label>
                                       </_DivUNSAFE>
                                       {item.strictTime && (
                                          <_DivUNSAFE className="mb-2">
                                             <label className="p-1">
                                                Handoff Time:{" "}
                                                <Controller
                                                   render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                                                      <React.Fragment>
                                                         <select
                                                            id={`${index}.intermission-minutes-select`}
                                                            ref={ref}
                                                            className="bg-white border rounded-md border-slate-200 pr-9"
                                                            onChange={(e) => onChange(outputCalculation(e))}
                                                            defaultValue={inputCalculation(value ?? 0).minutes}
                                                         >
                                                            <option value={0}>0</option>
                                                            <option value={1}>1</option>
                                                         </select>
                                                         {":"}{" "}
                                                         <select
                                                            id={`${index}.intermission-seconds-select`}
                                                            ref={ref}
                                                            className="bg-white border rounded-md border-slate-200 pr-9"
                                                            onChange={(e) => onChange(outputCalculation(e))}
                                                            defaultValue={inputCalculation(value ?? 0).seconds}
                                                         >
                                                            <option value={0}>00</option>
                                                            <option value={5}>05</option>
                                                            <option value={10}>10</option>
                                                            <option value={15}>15</option>
                                                            <option value={20}>20</option>
                                                            <option value={25}>25</option>
                                                            <option value={30}>30</option>
                                                         </select>
                                                      </React.Fragment>
                                                   )}
                                                   name={`rounds.${index}.intermissionTime`}
                                                   control={parentControl}
                                                />
                                             </label>
                                          </_DivUNSAFE>
                                       )}
                                       {/*  <_DivUNSAFE className="mb-2">
                                           <label>
                                              Strict Time:{" "}
                                              <Controller
                                                 render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                                                    <>
                                                       <input
                                                          type="checkbox"
                                                          className="p-2 bg-white border rounded-md border-slate-200"
                                                          onChange={() => {
                                                             if (item.strictTime === true) {
                                                                update(index, {
                                                                   ...item,
                                                                   strictTime: false,
                                                                   intermissionTime: 0
                                                                });
                                                             } else {
                                                                update(index, {
                                                                   ...item,
                                                                   strictTime: true,
                                                                   intermissionTime: 10
                                                                });
                                                             }
                                                          }} // send value to hook form
                                                          onBlur={onBlur} // notify when input is touched
                                                          value={value === true ? 1 : 0} // return updated value
                                                          checked={value} // return updated value
                                                          // defaultValue={value} // set default value
                                                          ref={ref} // set ref for focus management
                                                       ></input>
                                                    </>
                                                 )}
                                                 name={`rounds.${index}.strictTime`}
                                                 control={parentControl}
                                              />
                                           </label>
                                        </_DivUNSAFE> */}
                                    </li>
                                 );
                              })}
                           </ul>
                           <_DivUNSAFE className="flex justify-center">
                              <_Button
                                 variant="primary"
                                 type="button"
                                 id="add-round-button"
                                 testId="add-round-button"
                                 // className="p-4 m-4 rounded-lg cursor-pointer bg-slate-300 hover:drop-shadow-sm"
                                 onButtonClick={() => {
                                    if (currentBreakout) {
                                       append({
                                          id: uuidv4(),
                                          agendaItemId: getValues("id"),
                                          breakoutId: currentBreakout.id,
                                          roundComplete: false,
                                          roundActive: false,
                                          roundPaused: false,
                                          empathyMode: "reflective",
                                          strictTime: false,
                                          speakerTime: 180,
                                          intermissionTime: 0,
                                          created: DateTime.now().toMillis()
                                       } satisfies R.Round.Round);
                                    }
                                 }}
                              >
                                 + Round
                              </_Button>
                           </_DivUNSAFE>
                        </React.Fragment>
                     )}
                     <_DivUNSAFE className="grid content-between grid-cols-2 grid-rows-1">
                        <_Button
                           variant="primary"
                           testId="cancel-form-agenda-item"
                           // className="p-2 m-1 rounded-lg cursor-pointer bg-slate-300 hover:drop-shadow-sm"
                           type="reset"
                           onButtonClick={handleReset}
                        >
                           Cancel
                        </_Button>
                        <_Button
                           variant="primary"
                           id="submit-form-agenda-item"
                           testId="submit-form-agenda-item"
                           // className="p-2 m-1 rounded-lg cursor-pointer bg-slate-300 hover:drop-shadow-sm"
                           type="submit"
                           onButtonClick={handleSubmit(onSubmit, onError)}
                        >
                           Submit
                        </_Button>
                     </_DivUNSAFE>
                  </_Stack>
               </form>
            )}
         </_DivUNSAFE>
      );
   }
   return null;
}

Agenda.Start = Start;
Agenda.End = End;
Agenda.Adjourned = Adjourned;
Agenda.Card = Card;
Agenda.Form = Form;

export default Agenda;
