import { CheckIcon } from "@heroicons/react/24/solid";
import { R } from "app/(platform)/meeting-room/_types";
import _Icon from "app/_components_v2/data-display/_Icon";
import _Paragraph from "app/_components_v2/data-display/Paragraph";
import _Button from "app/_components_v2/inputs/_Button";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";
import _Stack from "app/_components_v2/layout/_Stack";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCurrentBreakout, useIsLocalFacilitator } from "../../_contexts/BreakoutContext";
import { MeetingContext } from "../../_contexts/MeetingContext";
import { selectActiveRound, selectNextInactiveRound } from "../../_services/roundSlice";
import { IRootState } from "app/_contexts/ReduxProvider";

const CardRound = (props: { round: R.Round.Round; roundIndex: number; agendaItemIndex: number }) => {
   const { round, roundIndex, agendaItemIndex } = props;
   const { LK } = useContext(MeetingContext);
   const currentBreakout = useCurrentBreakout();
   const isLocalFacilitator = useIsLocalFacilitator();
   const [speakerTime, setSpeakerTime] = useState("");
   const [intermissionTime, setIntermissionTime] = useState("");
   /*    const [cardOpen, setCardOpen] = useState(round.roundComplete ? false : true); */
   const startableRound = useSelector((state: IRootState) => selectNextInactiveRound(state, currentBreakout?.id));
   const isStartable = startableRound && startableRound.id === round.id;
   const activeRound = useSelector((state: IRootState) => selectActiveRound(state, currentBreakout?.id));
   const isActive = activeRound && activeRound.id === round.id;
   // TODO ZW: Implement startableRound
   const handleInputCalculation = (value: number) => {
      const minutes = Math.floor(value / 60);
      const seconds = value % 60;
      let humanReadable = `${minutes}:${seconds}`;
      if (seconds === 0) {
         humanReadable = `${minutes}:00`;
      }

      return { minutes, seconds, humanReadable };
   };

   useEffect(() => {
      if (round) {
         const { humanReadable: speakerTime } = handleInputCalculation(round.speakerTime);
         const { humanReadable: intermissionTime } = handleInputCalculation(round.intermissionTime);

         setSpeakerTime(speakerTime);

         setIntermissionTime(intermissionTime);
      }
   }, [round]);

   // .
   if (!currentBreakout?.id) return null;
   return (
      <_DivUNSAFE data-testid={`card-round-${roundIndex}-${agendaItemIndex}`} data-round-index={`roundIndex-${roundIndex}-${agendaItemIndex}`}>
         <_Stack flexBehavior={"row"} padding={"sm"}>
            <_Paragraph label={`${roundIndex + 1}. ${round.empathyMode === "reflective" ? "Active Listening, " : ""}${speakerTime} minute turns`} />

            {round?.intermissionTime > 0 && <_Paragraph label={`Handoff Time: ${intermissionTime}`} />}
            {isLocalFacilitator && isStartable && (
               <_Button
                  variant={"primary"}
                  type="button"
                  id={`start-round-button-${roundIndex}-${agendaItemIndex}`}
                  testId={`start-round-button-${roundIndex}-${agendaItemIndex}`}
                  onButtonClick={(e) => {
                     e.preventDefault();
                     LK.Round({ round: startableRound || {} }).startRound(currentBreakout.id);
                  }}
               >
                  {`Start Round ${roundIndex + 1}`}
               </_Button>
            )}
            {isActive && isLocalFacilitator && (
               <_Button
                  variant={"primary"}
                  type="button"
                  id="button-end-round"
                  testId="button-end-round"
                  onButtonClick={(e) => {
                     e.preventDefault();

                     LK.Round({ round: activeRound || {} }).endRound(currentBreakout.id);
                  }}
               >
                  {`End Round ${roundIndex + 1}`}
               </_Button>
            )}
            {round.roundComplete && (
               <_Icon textColor={"primary"} size={"xs"} disabled={"noPointer"}>
                  {({ iconClassname }) => <CheckIcon className={iconClassname} />}
               </_Icon>
            )}
            {/* {round.itemReferredToBreakout && <AccountTreeIcon className="h-5 place-self-center text-slate-900" />} */}
         </_Stack>
         {round.roundActive && (
            <_DivUNSAFE className="flex flex-row w-full border-t-4 border-red-300">
               <_DivUNSAFE className="flex flex-row w-full border-t-4 border-red-300" />
               <_Paragraph className="p-1 text-red-300 no-underline rounded-md " label={`Active`} />
            </_DivUNSAFE>
         )}
      </_DivUNSAFE>
   );
};

export default React.memo(CardRound);
