import { R } from "app/(platform)/meeting-room/_types";
import { IRootState, useAppStore } from "app/_contexts/ReduxProvider";
import { SuccessRefreshType } from "app/_hooks/useSuccessRefresh";
import ILog from "app/_lib/global/Log";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { FieldErrorsImpl, UseFieldArrayReturn, UseFormReturn, useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { apiRoom } from "../_api/apiRoom";
import { MeetingContext } from "../_contexts/MeetingContext";
import { selectAgendaItemsByBreakoutId } from "../_services/agendaItemSlice";
import { selectActiveBreakouts, selectAllBreakoutIdentities, selectBreakoutByParticipantIdentity, selectIsBreakoutFacilitator, selectMainBreakout } from "../_services/breakoutSlice";

interface BreakoutFormContextType {
   EditingState: { editing: boolean; setEditing: React.Dispatch<React.SetStateAction<boolean>> };
   Success: SuccessRefreshType;
   ParentForm: {
      RHF: UseFormReturn<
         {
            breakouts: FormBreakoutProps[];
         },
         any,
         undefined
      >;
      onSubmit: (data: any) => void;
      onError: (errors: Partial<FieldErrorsImpl<{ [x: string]: any }>>) => void;
      removeBreakout: (index: number) => void;
      assignFacilitator: (participant: string, breakoutId: string, assignFacilitator: boolean) => void;
      assignBreakout: (e: React.ChangeEvent<HTMLSelectElement>, identity: string, oldBreakoutId: string) => void;
      handleReset: (e: React.MouseEvent<HTMLButtonElement>) => void;
      UseFieldArray: UseFieldArrayReturn<
         {
            breakouts: FormBreakoutProps[];

            // {
            //    id: string;
            //    active: boolean;
            //    facilitatorIdentity: string | undefined;
            //    participants: R.Breakout.FormBreakoutParticipantProps[];
            //    assignedAgendaItems: R.AgendaItem.AgendaItem[];
            //    mainBreakout: boolean;
            //    roomOpen: boolean;
            //    roomLocked: boolean;
            //    agendaStarted: boolean;
            //    agendaPaused: boolean;
            //    activeSpeakerIdentity?: { latest: string | undefined; previous: string | undefined } | undefined;
            //    activeHostIdentity?: string | undefined;
            //    screenShareIdentity?: string | undefined;
            //    closed: boolean;
            // }[];
         },
         "breakouts",
         "breakout-key"
      >;
   };
}

export const BreakoutFormContext = createContext<BreakoutFormContextType>({} as BreakoutFormContextType);

type FormBreakoutProps = R.Breakout.BreakoutRoom & { touchedIdentities: string[]; agendaItems: R.AgendaItem.AgendaItem[] };

export function useBreakoutFormContext(success: SuccessRefreshType) {
   const [editing, setEditing] = useState(false);
   const { LK, roomParticipants, localHost, localParticipant } = useContext(MeetingContext);

   const currentBreakoutParticipantsStore = useSelector((state: IRootState) => selectAllBreakoutIdentities(state));
   const allBreakouts = useSelector((state: IRootState) => selectActiveBreakouts(state));
   const mainBreakout = useSelector((state: IRootState) => selectMainBreakout(state));
   const [grantRole, { data: grantRoleData, error: grantRoleError, isLoading: grantRoleIsFetching, isSuccess: grantRoleIsSuccess, isError: grantRoleIsError }] = apiRoom.useGrantRoleMutation();
   const token = useSelector((state: IRootState) => state.meeting.livekitToken);
   const store = useAppStore();
   ILog.v("BreakoutFormContext.ts", { allBreakouts, mainBreakout });
   const RHF = useForm<{ breakouts: FormBreakoutProps[] }>({
      mode: "onSubmit",
      defaultValues: {
         breakouts: allBreakouts.map((b) => {
            return {
               ...b,
               touchedIdentities: [],
               agendaItems: selectAgendaItemsByBreakoutId(store.getState(), b.id)
            };
         })
      }
   });

   const UseFieldArray = useFieldArray({
      control: RHF.control,
      name: "breakouts",
      keyName: "breakout-key"
   });
   const { fields, remove, update } = UseFieldArray;

   function removeBreakout(index: number) {
      const oldBreakoutId = RHF.getValues(`breakouts.${index}.id`);
      const mainBreakout = fields.find((b) => b?.mainBreakout);
      if (!mainBreakout) return;
      const mainIndex = fields.indexOf(mainBreakout);
      const oldBreakout = fields.find((b) => b.id === oldBreakoutId);

      const breakoutNewParticipants = oldBreakout?.participants ? [...mainBreakout?.participants, ...oldBreakout?.participants] : mainBreakout?.participants;
      update(mainIndex, {
         ...mainBreakout,
         participants: breakoutNewParticipants
      });
      remove(index);
   }

   function onSubmit(data: { breakouts: FormBreakoutProps[] }) {
      success.startCountdown();
      setEditing(false);
      const closedBreakouts = allBreakouts.filter(
         (breakout) => !data.breakouts.some((b) => b.id === breakout.id) // if the breakout id is not in the data.breakouts array, then it has been closed
      );
      closedBreakouts?.forEach((breakout) => {
         LK.Breakout({ breakout }).close(breakout.id);
      });
      const updatedAndNewBreakouts = data.breakouts.map((b) => {
         return {
            ...b,
            id: b.id,
            active: true,
            facilitatorIdentity: b?.facilitatorIdentity,
            participants: b.participants,
            mainBreakout: b.mainBreakout,
            roomOpen: b.roomOpen,
            roomLocked: b.roomLocked,
            closed: false,
            agendaStarted: b.agendaStarted,
            agendaPaused: b.agendaPaused
         };
      });

      updatedAndNewBreakouts.forEach((breakout) => {
         if (breakout.facilitatorIdentity) {
            const currentFacilitator = selectIsBreakoutFacilitator(store.getState(), { targetIdentity: breakout.facilitatorIdentity });
            if (!currentFacilitator && breakout.facilitatorIdentity !== localParticipant?.identity) {
               grantRole({
                  payload: {
                     target_identity: breakout.facilitatorIdentity,
                     target_role: "coHost"
                  },
                  token: token
               });
            }
         }
         ILog.v("BreakoutFormContext.ts", "onSubmit", { breakout, allBreakouts, closedBreakouts, items: breakout.agendaItems, updatedAndNewBreakouts });
         LK.Breakout({ breakout }).upsert({ breakout, newAgendaItems: breakout.agendaItems });
      });
   }

   function onError(_errors: Partial<FieldErrorsImpl<{ [x: string]: any }>>) {}

   function handleReset(e: React.MouseEvent<HTMLButtonElement> | undefined) {
      e?.preventDefault();
      success.resetCountdown();
      RHF.reset(
         {
            breakouts: allBreakouts.map((b) => {
               return {
                  ...b,
                  touchedIdentities: [],
                  agendaItems: selectAgendaItemsByBreakoutId(store.getState(), b.id)
               };
            })
         },
         {
            keepDirty: false,
            keepIsSubmitted: false,
            keepTouched: false,
            keepIsValid: false,
            keepSubmitCount: false
         }
      );
      setEditing(false);
   }

   function addParticipant(participant: string, newBreakoutID: string) {
      const breakoutObj = fields.find((b) => b.id === newBreakoutID);
      if (!breakoutObj) return;
      const breakoutIndex = fields.indexOf(breakoutObj);

      const breakouts = RHF.getValues(`breakouts`);
      if (!breakouts) return;
      const breakout = breakouts[breakoutIndex];
      if (!breakout) return;
      let newParticipants = breakout?.participants || [];
      newParticipants?.push(participant);
      ILog.v("BreakoutFormContext.ts", "addParticipant", { breakout, newParticipants });
      update(breakoutIndex, {
         ...breakout,
         participants: newParticipants,
         touchedIdentities: [...breakout.touchedIdentities, participant]
      });
   }
   function removeParticipant(participantIdentity: string) {
      const breakouts = RHF.getValues(`breakouts`);
      if (!breakouts) {
         return;
      }
      const oldBreakout = breakouts.find((b) => b.participants.includes(participantIdentity));
      const oldBreakoutObj = fields.find((b) => b.id === oldBreakout?.id);
      if (!oldBreakoutObj) {
         return;
      }
      const oldBreakoutIndex = fields.indexOf(oldBreakoutObj);
      if (!oldBreakout) {
         return;
      }
      let oldParticipants = oldBreakout?.participants?.filter((p) => p !== participantIdentity) || [];

      const oldBreakoutNewParticipants = oldParticipants ? [...oldParticipants] : [];

      ILog.v("BreakoutFormContext.ts", "removeParticipant", { oldBreakout, oldParticipants, oldBreakoutNewParticipants });
      update(oldBreakoutIndex, {
         ...oldBreakout,
         participants: oldBreakoutNewParticipants
      });
   }

   function assignBreakout(e: React.ChangeEvent<HTMLSelectElement>, identity: string, oldBreakoutId: string) {
      e.preventDefault();
      const newBreakoutID = e.target.value;

      if (oldBreakoutId !== newBreakoutID) {
         removeParticipant(identity);
         addParticipant(identity, newBreakoutID);
      }
   }

   function assignFacilitator(participant: string, breakoutId: string, assignFacilitator: boolean) {
      const oldBreakoutObj = fields.find((b) => b.participants.includes(participant));
      const newBreakoutObj = fields.find((b) => b.id === breakoutId);
      if (!oldBreakoutObj || !newBreakoutObj) return;
      const breakouts = RHF.getValues(`breakouts`);
      const oldBreakoutIndex = fields.indexOf(oldBreakoutObj);
      const newBreakoutIndex = fields.indexOf(newBreakoutObj);

      const oldBreakout = breakouts[oldBreakoutIndex];
      const oldBreakoutParticipants = oldBreakout?.participants?.filter((p) => p !== participant) || [];
      const facilitator = LK.Breakout({ breakout: oldBreakout }).facilitatorFallback(oldBreakoutParticipants);
      const newBreakout = breakouts[newBreakoutIndex];
      if (oldBreakoutIndex !== newBreakoutIndex) {
         update(oldBreakoutIndex, {
            ...oldBreakout,
            facilitatorIdentity: assignFacilitator ? facilitator : undefined
         });
      }

      update(newBreakoutIndex, {
         ...newBreakout,

         facilitatorIdentity: assignFacilitator ? participant : undefined
      });
   }

   useEffect(() => {
      if (!RHF.formState.isDirty && RHF.formState.isSubmitSuccessful) {
         setEditing(false);
      } else if ((RHF.formState.dirtyFields && Object.keys(RHF.formState.dirtyFields)?.length > 0) || RHF.formState.isValidating) {
         success.count === 0 && success.resetCountdown();
         setEditing(true);
      }
   }, [RHF.formState.dirtyFields, RHF.formState.isSubmitSuccessful, RHF.formState.isDirty, RHF.formState.isValidating]);

   const { unassignedParticipants, exitedParticipants, untouchedAndMovedParticipants } = useMemo(() => {
      // const currentFormParticipants = fields.reduce((acc, breakout) => {
      //    const breakoutParticipants = breakout.participants;
      //    return [...acc, ...breakoutParticipants];
      // });
      const formBreakouts = RHF.getValues(`breakouts`);
      const currentFormParticipants = formBreakouts.map((b) => b.participants).flat();
      const touchedParticipants = formBreakouts.map((b) => b.touchedIdentities).flat();
      // Participants who left the room, but are still in the form
      const exitedParticipants = currentFormParticipants.filter((p) => !currentBreakoutParticipantsStore.find((p2) => p2 === p));
      // Participants who are in the room, but not in the form
      const unassignedParticipants = currentBreakoutParticipantsStore.filter((p) => !currentFormParticipants.find((p2) => p2 === p));

      // Participants who moved breakouts, but were not touched by the host in this form's lifecycle.
      const untouchedParticipants = currentFormParticipants.filter((p) => !touchedParticipants.find((p2) => p2 === p));
      const untouchedAndMovedParticipants = untouchedParticipants.filter((p) => {
         return Boolean(
            currentBreakoutParticipantsStore.find(
               (p2) => p2 === p && allBreakouts.find((b) => b.participants.find((bp) => bp === p2))?.id !== formBreakouts.find((b) => b.participants.find((bp) => bp === p2))?.id
            )
         );
      });

      return {
         unassignedParticipants: unassignedParticipants,
         exitedParticipants: exitedParticipants,
         untouchedAndMovedParticipants: untouchedAndMovedParticipants
      };
   }, [currentBreakoutParticipantsStore, roomParticipants, fields]);

   useEffect(() => {
      if (unassignedParticipants) {
         unassignedParticipants.forEach((p) => {
            if (mainBreakout?.id) {
               addParticipant(p, mainBreakout?.id);
            }
         });
      }
      if (exitedParticipants) {
         exitedParticipants.forEach((item) => {
            removeParticipant(item);
         });
      }
      if (untouchedAndMovedParticipants) {
         untouchedAndMovedParticipants.forEach((item) => {
            const currentBreakout = selectBreakoutByParticipantIdentity(store.getState(), item);
            ILog.v("BreakoutFormContext.ts", "untouchedAndMovedParticipants", { item, currentBreakout });
            if (currentBreakout?.id) {
               removeParticipant(item);
               addParticipant(item, currentBreakout?.id);
            } else {
               ILog.w("untouchedAndMovedParticipants_NO_BREAKOUT", { item, currentBreakout });
            }
         });
      }
   }, [unassignedParticipants, exitedParticipants, untouchedAndMovedParticipants]);

   useEffect(() => {
      if (!!mainBreakout && fields?.length === 0) {
         handleReset(undefined);
      }
   }, [mainBreakout]);

   return {
      EditingState: { editing, setEditing },
      Success: success,
      ParentForm: {
         RHF: RHF,
         onSubmit,
         onError,
         UseFieldArray,
         removeBreakout,
         assignFacilitator,
         assignBreakout,
         handleReset
      }
   };
}
