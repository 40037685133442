import CloseIcon from "@mui/icons-material/Close";
import { useMemo, useState } from "react";
import { FieldError, FieldErrors } from "react-hook-form";
import _Paragraph from "../data-display/Paragraph";

function ErrorBase(props: { dismissable?: boolean; message: string; id?: string }) {
   const { message, dismissable, id } = props;
   const [show, setShow] = useState(true);

   const handleDismiss = () => {
      setShow(false);
   };
   if (show) {
      return (
         <div className="flex flex-row gap-3 p-4 bg-red-400 rounded-xs shadow-md place-self-center whitespace-nowrap text-slate-900">
            <ErrorBase.Message message={message} />
            {dismissable && <ErrorBase.Dismiss handleDismiss={handleDismiss} />}
         </div>
      );
   }
   return null;
}

function Dismiss(props: { handleDismiss: () => void }) {
   const { handleDismiss } = props;

   return (
      <button className="" onMouseDown={handleDismiss}>
         <CloseIcon className="w-6 h-6 place-self-center" />
      </button>
   );
}

function Message(props: { message: string }) {
   const { message } = props;

   return <_Paragraph label={message} />;
}

function HookForm(props: { errors: FieldError | FieldErrors }) {
   const { errors } = props;

   const errorMessages = useMemo(() => {
      if (Array.isArray(errors)) {
         return errors.map((error: FieldError, index) => error.message);
      } else {
         return Object.values(errors)
            .map((error: FieldError | undefined, index) => error?.message)
            .filter(Boolean);
      }
   }, [errors]);

   return (
      <div className="flex flex-col gap-3 p-4 rounded-xs place-self-center whitespace-nowrap ">
         {errorMessages &&
            errorMessages.map((message, index) => {
               return <_Paragraph key={`${index}-error-message`} label={message} />;
            })}
      </div>
   );
}

ErrorBase.Message = Message;
ErrorBase.Dismiss = Dismiss;
ErrorBase.Many = HookForm;

export default ErrorBase;
