"use client";
import { ChatBubbleLeftIcon, ChevronLeftIcon, ClipboardDocumentCheckIcon, MagnifyingGlassIcon, UsersIcon } from "@heroicons/react/24/solid";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import GavelRoundedIcon from "@mui/icons-material/GavelRounded";
import PersonAddAlt1RoundedIcon from "@mui/icons-material/PersonAddAlt1Rounded";
import { AuthStatusContext } from "app/(platform)/(authentication)/_contexts/AuthenticatedSessionProvider";
import PersonalNav from "app/(platform)/@search/_components/PersonalNav";
import { InviteParticipantsToWorkspace } from "app/(platform)/meeting-room/_components/_forms/InviteParticipantsToWorkspace";
import Agenda from "app/(platform)/meeting-room/_components/Agenda";
import Breakout from "app/(platform)/meeting-room/_components/breakout";
import ButtonRecord from "app/(platform)/meeting-room/_components/button/ButtonRecord";
import ParticipantBase from "app/(platform)/meeting-room/_components/card/Participant";
import ChatThread from "app/(platform)/meeting-room/_components/chat/Thread";
import ContainerTurns from "app/(platform)/meeting-room/_components/layout/ContainerTurns";
import Password from "app/(platform)/meeting-room/_components/Password";
import { MeetingContext } from "app/(platform)/meeting-room/_contexts/MeetingContext";
import { selectAllAgendaItems } from "app/(platform)/meeting-room/_services/agendaItemSlice";
import { selectActiveRoundByBreakoutId } from "app/(platform)/meeting-room/_services/roundSlice";
import { selectActiveTurn } from "app/(platform)/meeting-room/_services/turnSlice";
import _Button from "app/_components_v2/inputs/_Button";
import ILog from "app/_lib/global/Log";
import { PublicEnv } from "app/_lib/global/PublicEnv";
import { Participant } from "livekit-client";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { v4 } from "uuid";

import { useCurrentBreakout, useIsLocalFacilitator } from "app/(platform)/meeting-room/_contexts/BreakoutContext";
import { EnsureParticipant } from "app/(platform)/meeting-room/_contexts/ParticipantContext";
import { IRootState, useAppStore } from "app/_contexts/ReduxProvider";
import _Icon from "../data-display/_Icon";
import _Stack from "./_Stack";
import LeftNavItem from "./LeftNavItem";

const defaultAgendaItemValues = {
   id: v4(),
   title: "",
   description: "",
   duration: 0,
   itemComplete: false,
   itemActive: false,
   itemPaused: false,
   presenterTime: 0,
   sort: 0,
   void: false,
   breakoutId: undefined
};

export default function LeftNav_V2(props: { children: React.ReactNode }) {
   const { children } = props;
   const openOverride = PublicEnv.AccordionOpenOverride === "true";
   const leftNavOpen = useSelector((state: IRootState) => state.app.leftNavOpen || openOverride);
   const directoryNavOpen = useSelector((state: IRootState) => state.app.directoryNavOpen || openOverride);
   const agendaNavOpen = useSelector((state: IRootState) => state.app.agendaNavOpen || openOverride);
   const chatNavOpen = useSelector((state: IRootState) => state.app.chatNavOpen || openOverride);
   const breakoutNavOpen = useSelector((state: IRootState) => state.app.breakoutNavOpen || openOverride);
   const participantNavOpen = useSelector((state: IRootState) => state.app.participantNavOpen || openOverride);
   const meetingControlNavOpen = useSelector((state: IRootState) => state.app.meetingControlNavOpen || openOverride);
   const listeningRequestNavOpen = useSelector((state: IRootState) => state.app.listeningRequestNavOpen || openOverride);

   const [editingAgendaItem, setEditingAgendaItem] = useState(false);
   const { empathyCafe, room, roomParticipants, localCoHost, localHost, localParticipant, LK, livekitToken, remoteParticipants } = useContext(MeetingContext);
   const currentBreakout = useCurrentBreakout();
   const isLocalFacilitator = useIsLocalFacilitator();
   const activeRound = useSelector((state: IRootState) => selectActiveRoundByBreakoutId(state, currentBreakout?.id));
   const participantsWithoutLocal = roomParticipants?.filter((participant) => participant.identity !== localParticipant?.identity);
   const meeting = useSelector((state: IRootState) => state.meeting);
   const allAgendaItems = useSelector((state: IRootState) => selectAllAgendaItems(state));

   const activeTurn = useSelector((state: IRootState) => selectActiveTurn(state, currentBreakout?.id));
   const { status } = useContext(AuthStatusContext);
   const store = useAppStore();

   const { headerMessage } = useMemo(() => {
      if (localHost && isLocalFacilitator) {
         return { headerMessage: "Host + Facilitator Controls" };
      } else if (localCoHost && isLocalFacilitator) {
         return { headerMessage: "Co-Host + Facilitator Controls" };
      } else if (localHost) {
         return { headerMessage: "Host Controls" };
      } else if (isLocalFacilitator) {
         return { headerMessage: "Facilitator Controls" };
      } else if (localCoHost) {
         return { headerMessage: "Co-Host Controls" };
      } else {
         return { headerMessage: undefined };
      }
   }, [localCoHost, localHost, isLocalFacilitator, meeting.empathyCafe, currentBreakout?.agendaStarted, allAgendaItems?.length]);

   useEffect(() => {
      if (!leftNavOpen) setEditingAgendaItem(false);
   }, [leftNavOpen]);
   const inMeeting = !!room;
   ILog.v("LeftNav_V2", { inMeeting, room, leftNavOpen, directoryNavOpen, editingAgendaItem, status });
   return (
      <nav id="left-nav" className={`flex h-full max-h-full max-w-[100%] flex-col gap-4 lg:gap-2 ${leftNavOpen ? "" : "place-items-center"} grow`}>
         <_Stack flexBehavior={"row"} alignment={"between"} className="-mb-4 lg:mb-0">
            <PersonalNav noAbsolutePositioning />
            {leftNavOpen && (
               <_Button
                  variant={"transparent"}
                  rounded={false}
                  id="close-left-nav"
                  testId="close-left-nav-button"
                  aria-label="Close Left Navigation"
                  onButtonClick={() => {
                     store.dispatch({ type: "app/setAllMenus", payload: { leftNavOpen: false } });
                  }}
               >
                  <_Icon variant={"transparent"}>{({ iconClassname }) => <ChevronLeftIcon className={iconClassname} />}</_Icon>
               </_Button>
            )}
         </_Stack>

         {inMeeting && (
            <Fragment>
               <LeftNavItem
                  otherMenuOpen={directoryNavOpen || meetingControlNavOpen || participantNavOpen || listeningRequestNavOpen || chatNavOpen || breakoutNavOpen}
                  onOpen={() => {
                     store.dispatch({
                        type: "app/setAllMenus",
                        payload: { leftNavOpen: true, agendaNavOpen: true }
                     });
                  }}
                  onClose={() => {
                     store.dispatch({
                        type: "app/setAllMenus",
                        payload: { leftNavOpen: true }
                     });
                  }}
                  Icon={(props) => <ClipboardDocumentCheckIcon {...props} />}
                  menuOpen={agendaNavOpen}
                  closeTestId="close-agenda-nav-item"
                  openLabel="Agenda"
                  openTestId="open-agenda-nav-item"
               >
                  <>
                     {!editingAgendaItem && <Agenda />}
                     {!empathyCafe && currentBreakout && (
                        <Agenda.Form
                           defaultAgendaItemValues={{
                              ...defaultAgendaItemValues,
                              breakoutId: currentBreakout?.id
                           }}
                           existingItem={false}
                           defaultRoundValues={[]}
                           handleCompleteEdit={() => setEditingAgendaItem(false)}
                           handleStartEdit={() => setEditingAgendaItem(true)}
                        />
                     )}
                  </>
               </LeftNavItem>
               <LeftNavItem
                  otherMenuOpen={directoryNavOpen || meetingControlNavOpen || participantNavOpen || listeningRequestNavOpen || agendaNavOpen || breakoutNavOpen}
                  onOpen={() => {
                     store.dispatch({
                        type: "app/setAllMenus",
                        payload: { leftNavOpen: true, chatNavOpen: true }
                     });
                  }}
                  onClose={() => {
                     store.dispatch({
                        type: "app/setAllMenus",
                        payload: { leftNavOpen: true }
                     });
                  }}
                  Icon={(props) => <ChatBubbleLeftIcon {...props} />}
                  menuOpen={chatNavOpen}
                  closeTestId="close-chat-nav-item"
                  openLabel="Chats"
                  openTestId="open-chat-nav-item"
               >
                  <ChatThread />
               </LeftNavItem>
               {activeRound && (
                  <LeftNavItem
                     otherMenuOpen={directoryNavOpen || meetingControlNavOpen || participantNavOpen || agendaNavOpen || chatNavOpen || breakoutNavOpen}
                     onOpen={() => {
                        store.dispatch({
                           type: "app/setAllMenus",
                           payload: { leftNavOpen: true, listeningRequestNavOpen: true }
                        });
                     }}
                     onClose={() => {
                        store.dispatch({
                           type: "app/setAllMenus",
                           payload: { leftNavOpen: true }
                        });
                     }}
                     Icon={(props) => <PersonAddAlt1RoundedIcon {...props} />}
                     menuOpen={listeningRequestNavOpen}
                     closeTestId="close-lr-nav-item"
                     openLabel={activeRound?.empathyMode === "reflective" ? "Listening Requests" : "Turns"}
                     openTestId="open-lr-nav-item"
                  >
                     <ContainerTurns />
                  </LeftNavItem>
               )}
               <LeftNavItem
                  otherMenuOpen={directoryNavOpen || meetingControlNavOpen || agendaNavOpen || chatNavOpen || breakoutNavOpen || listeningRequestNavOpen}
                  onOpen={() => {
                     store.dispatch({
                        type: "app/setAllMenus",
                        payload: { leftNavOpen: true, participantNavOpen: true }
                     });
                  }}
                  onClose={() => {
                     store.dispatch({
                        type: "app/setAllMenus",
                        payload: { leftNavOpen: true }
                     });
                  }}
                  Icon={(props) => <UsersIcon {...props} />}
                  menuOpen={participantNavOpen}
                  closeTestId="close-participant-nav-item"
                  openLabel="Participants"
                  openTestId="open-participant-nav-item"
               >
                  <_Stack gap={"sm"} className="mb-2">
                     <Password.SimpleCopy />
                     {localParticipant && (
                        <EnsureParticipant key={"LeftNav_V2_EnsureParticipant" + localParticipant?.identity} identity={localParticipant.identity}>
                           {({ participant, isLocal, pContext }) => <ParticipantBase.Card />}
                        </EnsureParticipant>
                     )}
                     {participantsWithoutLocal &&
                        participantsWithoutLocal.map((participant: Participant) => (
                           <EnsureParticipant key={"LeftNav_V2_EnsureParticipant" + participant?.identity} identity={participant.identity}>
                              {({ participant, isLocal, pContext }) => <ParticipantBase.Card />}
                           </EnsureParticipant>
                        ))}
                  </_Stack>
               </LeftNavItem>
               {headerMessage && (
                  <LeftNavItem
                     otherMenuOpen={directoryNavOpen || participantNavOpen || agendaNavOpen || chatNavOpen || breakoutNavOpen || listeningRequestNavOpen}
                     onOpen={() => {
                        store.dispatch({
                           type: "app/setAllMenus",
                           payload: { leftNavOpen: true, meetingControlNavOpen: true }
                        });
                     }}
                     onClose={() => {
                        store.dispatch({
                           type: "app/setAllMenus",
                           payload: { leftNavOpen: true }
                        });
                     }}
                     Icon={(props) => <GavelRoundedIcon {...props} />}
                     menuOpen={meetingControlNavOpen}
                     closeTestId="close-meeting-control-nav-item"
                     openLabel={headerMessage}
                     openTestId="open-meeting-control-nav-item"
                  >
                     <Fragment>
                        <ButtonRecord />
                        {localHost && !meeting.empathyCafe && currentBreakout && currentBreakout?.agendaStarted !== true && allAgendaItems?.length === 0 && (
                           <_Button
                              variant={"primary"}
                              type="button"
                              id="button-start-empathy-cafe"
                              testId="start-empathy-cafe-button"
                              onButtonClick={() => {
                                 LK.AgendaItem({ agendaItem: {} }).startEmpathyCafe();
                              }}
                           >
                              Start Empathy Cafe
                           </_Button>
                        )}
                        {isLocalFacilitator && activeTurn && (
                           <_Button
                              variant={"primary"}
                              type="button"
                              id="buttonVoidListeningRequests"
                              testId="void-listening-requests-button"
                              onButtonClick={() => {
                                 LK.Turn({ turn: activeTurn }).voidListeningRequestsByTurnId();
                              }}
                           >
                              Reset Listening Requests for this Turn
                           </_Button>
                        )}
                        {localHost && !meeting.meetingAdjourned && currentBreakout && currentBreakout.agendaStarted === true && allAgendaItems.length > 0 && (
                           <_Button
                              variant={"primary"}
                              type="button"
                              id="adjourn-meeting-button"
                              testId="adjourn-meeting-button"
                              onButtonClick={() => {
                                 LK.AgendaItem({ agendaItem: {} }).adjournMeeting(currentBreakout?.id);
                              }}
                           >
                              Adjourn the Meeting
                           </_Button>
                        )}
                        <InviteParticipantsToWorkspace />

                        <Password.Detailed />
                     </Fragment>
                  </LeftNavItem>
               )}
               <LeftNavItem
                  otherMenuOpen={directoryNavOpen || participantNavOpen || agendaNavOpen || chatNavOpen || listeningRequestNavOpen || meetingControlNavOpen}
                  onOpen={() => {
                     store.dispatch({
                        type: "app/setAllMenus",
                        payload: { leftNavOpen: true, breakoutNavOpen: true }
                     });
                  }}
                  onClose={() => {
                     store.dispatch({
                        type: "app/setAllMenus",
                        payload: { leftNavOpen: true }
                     });
                  }}
                  Icon={(props) => <AccountTreeIcon {...props} />}
                  menuOpen={breakoutNavOpen}
                  closeTestId="close-breakout-nav-item"
                  openLabel="Breakouts"
                  openTestId="open-breakout-nav-item"
               >
                  <Breakout />
               </LeftNavItem>
            </Fragment>
         )}
         {status === "authenticated" && (
            <LeftNavItem
               otherMenuOpen={agendaNavOpen || chatNavOpen || participantNavOpen || meetingControlNavOpen || listeningRequestNavOpen || breakoutNavOpen}
               onOpen={() => {
                  store.dispatch({
                     type: "app/setAllMenus",
                     payload: { leftNavOpen: true, directoryNavOpen: true }
                  });
               }}
               onClose={() => {
                  store.dispatch({
                     type: "app/setAllMenus",
                     payload: { leftNavOpen: true, directoryNavOpen: false }
                  });
               }}
               Icon={(props) => <MagnifyingGlassIcon {...props} />}
               menuOpen={directoryNavOpen}
               closeTestId="close-directory-nav-item"
               openLabel="Directory"
               openTestId="open-directory-nav-item"
               flexGrow={true}
            >
               {children}
            </LeftNavItem>
         )}
      </nav>
   );
}
