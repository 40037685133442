"use client";
import { MicrophoneIcon as MicrophoneIconOutline, VideoCameraSlashIcon } from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon, MicrophoneIcon as MicrophoneIconSolid, VideoCameraIcon } from "@heroicons/react/24/solid";
import { AuthStatusContext, LocalUserNameContext } from "app/(platform)/(authentication)/_contexts/AuthenticatedSessionProvider";
import { updateParticipantName } from "app/(platform)/meeting-room/_actions/updateParticipantName";
import { apiRoom } from "app/(platform)/meeting-room/_api/apiRoom";
import { MeetingContext } from "app/(platform)/meeting-room/_contexts/MeetingContext";
import { IParticipantContext } from "app/(platform)/meeting-room/_contexts/ParticipantContext";
import Header from "app/_components_v2/data-display/Header";
import _Paragraph from "app/_components_v2/data-display/Paragraph";
import _Icon from "app/_components_v2/data-display/_Icon";
import LoadingSquares from "app/_components_v2/feedback/loading/LoadingSquares";
import LeanInput from "app/_components_v2/inputs/LeanInput";
import _Button from "app/_components_v2/inputs/_Button";
import { FloatingNav } from "app/_components_v2/layout/FloatingNav";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";
import _Stack from "app/_components_v2/layout/_Stack";
import ILog from "app/_lib/global/Log";
import { apiNext } from "app/_services/redux/api/apiNext";
import { RemoteParticipant } from "livekit-client";
import { useSearchParams } from "next/navigation";
import { Fragment, useContext, useEffect, useState } from "react";
import "react-activity/dist/Squares.css";

// TEST TODO ZW: Won't re-render when host mutes a participant

// function ParticipantBase(props: { participant: Participant; isLocalParticipant: boolean }) {
//    const { participant, isLocalParticipant } = props;

//    const pContext = useParticipantContext(isLocalParticipant, participant);

//    // TODO ZW: Fix z-index problem. Has to do with positioning attributes of parent elements (absolute, relative, static, etc). Right now we're just using grid with no popover.
//    return (
//       <IParticipantContext.Provider value={pContext}>
//          <ParticipantBase.Card />
//       </IParticipantContext.Provider>
//    );
// }

function ParticipantBase(props: {}) {
   throw new Error("Dont use ParticipantBase");
}

function Card() {
   const { localCoHost, localHost, livekitToken, LK } = useContext(MeetingContext);
   const { status } = useContext(AuthStatusContext);
   const { fullName } = useContext(LocalUserNameContext);
   //  const [signIn, { isSuccess: signInIsSuccess, isUninitialized: signInIsUninitialized }] = apiNext.useActionMutation({ fixedCacheKey: `sign-in-cache` });
   const {
      Set: { setRefresh },
      State: { isHost, isCameraEnabled, isCoHost, isFacilitator, isMicrophoneEnabled, isSpeaking, name, isLocalParticipant, isLoading, identity }
   } = useContext(IParticipantContext);
   // const [updateName, { isLoading: updateNameIsLoading }] = apiNext.useActionWithArgumentsMutation();
   const [updateName, { isLoading: updateNameIsLoading, isUninitialized }] = apiNext.useActionMutation();

   async function updateParticipantNameAction({ livekit_token, targetIdentity, name }: { livekit_token: string; targetIdentity: string; name: string }) {
      ILog.v("ParticipantBaseCard_updateName_setRefresh", {});
      LK.onParticipantNameUpdated({ identity: targetIdentity, name });
      setRefresh(true);
      ILog.v("ParticipantBaseCard_updateName", { isLoading, isLocalParticipant, name, fullName, status, identity, livekitToken, isUninitialized });
      const res = await updateParticipantName({
         livekit_token,
         name,
         targetIdentity
      });
      ILog.v("ParticipantBaseCard_updateName_res", { res });
      return res;
   }
   useEffect(() => {
      if (!identity || !livekitToken || !isUninitialized || updateNameIsLoading) return;
      if (isLocalParticipant && status === "authenticated" && !!fullName && fullName !== name) {
         isLocalParticipant && ILog.v("ParticipantBaseCard_useEffect", { isLoading, isLocalParticipant, name, fullName, status, identity, livekitToken, isUninitialized });
         setTimeout(() => {
            updateName(async () => await updateParticipantNameAction({ livekit_token: livekitToken, targetIdentity: identity, name: fullName }));
         }, 100);
      }
   }, [fullName, name, isLocalParticipant, identity, livekitToken, status, isUninitialized, updateNameIsLoading]);

   isLocalParticipant && ILog.v("ParticipantBaseCard_FINAL", { isLoading, isLocalParticipant, name, fullName, status, identity, livekitToken, isUninitialized });
   return (
      // <_DivUNSAFE data-testid={`participant-list-card-${name}`} data-participant-name={name} className="flex justify-between p-1 bg-white rounded-md shadow-md ">
      <_Stack testId={`participant-list-card-${name}`} flexBehavior={"row"} gap="none" alignment={"between"} className="p-1 bg-white rounded-md shadow-md @container">
         <_Stack flexBehavior={"row"} gap="sm" alignment={"start"} className="ml-2 place-content-center place-items-center w-[40cqw]" wrap={false} fluid={false}>
            {(localHost || localCoHost || isLocalParticipant) && livekitToken && identity ? (
               <LeanInput.Text3
                  testId={`participant-rename-input-${name}`}
                  label={undefined}
                  focus={false}
                  value={name}
                  fullWidth
                  containerQuery="w-[40cqw]!"
                  onChangeEvent={({ e, localValue, setLocalValue, setError }) => {
                     ILog.v("SelectView_setLocalValue", { e, localValue, newValue: e.target.value });
                     setLocalValue(e.target.value);
                     if (e.target.value === "") {
                        setError("Name cannot be empty");
                     }
                  }}
                  onBlurEvent={({ e, localValue, setLocalValue, setError }) => {
                     ILog.v("SelectView_onBlur", { e, localValue });
                     setError(null);
                     LK.onParticipantNameUpdated({ identity, name: localValue });
                     updateName(async () => {
                        return await updateParticipantName({
                           livekit_token: livekitToken,
                           name: localValue,
                           targetIdentity: identity
                        });
                     });
                  }}
                  level="p"
               />
            ) : (
               <_Paragraph id={`participant-name-paragraph-${name}`} testId={`participant-name-paragraph-${name}`} label={`${name}`} wrap={false} />
            )}
         </_Stack>
         <_Stack flexBehavior={"row"} gap="sm" alignment={"center"} className="justify-end justify-items-center w-[60cqw]" wrap={true}>
            {isHost && <_Paragraph label={`Host${isCoHost || isFacilitator ? "," : ""}`} wrap={false} testId={`participant-${name}-is-host`} />}
            {isCoHost && <_Paragraph label={`Co-Host${isFacilitator ? "," : ""}`} wrap={false} testId={`participant-${name}-is-cohost`} />}
            {isFacilitator && <_Paragraph label={`Facilitator`} wrap={false} testId={`participant-${name}-is-facilitator`} />}
            {isLoading && <LoadingSquares />}
            {isCameraEnabled ? (
               <_Icon size="sm">{({ iconClassname }) => <VideoCameraIcon className={iconClassname} />}</_Icon>
            ) : (
               <_Icon size="sm" textColor={"red"}>
                  {({ iconClassname }) => <VideoCameraSlashIcon className={iconClassname} />}
               </_Icon>
            )}
            {isMicrophoneEnabled && !isSpeaking && <_Icon size="sm">{({ iconClassname }) => <MicrophoneIconSolid className={iconClassname} />}</_Icon>}
            {!isMicrophoneEnabled && (
               <_Icon size="sm" textColor={"red"}>
                  {({ iconClassname }) => <MicrophoneIconOutline className={iconClassname} />}
               </_Icon>
            )}
            {isSpeaking && (
               <_Icon size="sm" textColor={"green"}>
                  {({ iconClassname }) => <MicrophoneIconSolid className={iconClassname} />}
               </_Icon>
            )}
            {(localHost || localCoHost || isLocalParticipant) && <ParticipantBase.Options />}
         </_Stack>
      </_Stack>
   );
}

function Options() {
   const { localParticipant, localHost, localCoHost, room, LK } = useContext(MeetingContext);
   const {
      State: { name, isLocalParticipant, isCameraEnabled, isMicrophoneEnabled, participant }
   } = useContext(IParticipantContext);

   return (
      <FloatingNav
         referenceChild={
            <_Button testId={`participant-controls-${name}`} aria-label="Open Participant options" onButtonClick={() => {}}>
               <_Icon variant={"transparent"} size={"sm"}>
                  {({ iconClassname }) => <EllipsisVerticalIcon className={iconClassname} />}
               </_Icon>
            </_Button>
         }
         floatingChild={({ setHovered }) => (
            <_Stack
               className=""
               onMouseLeave={(e) => {
                  // setHovered(false);
               }}
            >
               {localParticipant && (
                  <Fragment>
                     {(localHost || localCoHost) && !isLocalParticipant && (
                        <Fragment>
                           {isMicrophoneEnabled ? (
                              <_Button
                                 testId={`mute-microphone-${name}`}
                                 onButtonClick={(e) => {
                                    e.preventDefault();
                                    LK.remoteAudioMute(participant as RemoteParticipant);
                                 }}
                              >
                                 Mute Microphone
                              </_Button>
                           ) : (
                              <_Button
                                 testId={`allow-unmute-microphone-${name}`}
                                 onButtonClick={(e) => {
                                    e.preventDefault();
                                    LK.remoteAudioMute(participant as RemoteParticipant);
                                 }}
                              >
                                 Allow Unmute Microphone
                              </_Button>
                           )}
                           {isCameraEnabled ? (
                              <_Button
                                 testId={`mute-camera-${name}`}
                                 onButtonClick={(e) => {
                                    e.preventDefault();
                                    LK.remoteCameraMute(participant as RemoteParticipant);
                                 }}
                              >
                                 Mute Camera
                              </_Button>
                           ) : (
                              <_Button
                                 testId={`request-unmute-camera-${name}`}
                                 onButtonClick={(e) => {
                                    e.preventDefault();
                                    LK.remoteCameraMute(participant as RemoteParticipant);
                                 }}
                              >
                                 Request Unmute Camera
                              </_Button>
                           )}
                           <ParticipantBase.Role />
                           <ParticipantBase.Kick />
                        </Fragment>
                     )}
                     {isLocalParticipant && (
                        <Fragment>
                           {localParticipant.isMicrophoneEnabled ? (
                              <_Button
                                 testId={"local-participant-mute-microphone"}
                                 onButtonClick={(e) => {
                                    e.preventDefault();
                                    LK.audioMute();
                                 }}
                              >
                                 Mute Microphone
                              </_Button>
                           ) : (
                              <_Button
                                 testId={"local-participant-unmute-microphone"}
                                 onButtonClick={(e) => {
                                    e.preventDefault();
                                    LK.audioMute();
                                 }}
                              >
                                 Enable Microphone
                              </_Button>
                           )}
                           {localParticipant.isCameraEnabled ? (
                              <_Button
                                 testId={"local-participant-mute-camera"}
                                 onButtonClick={(e) => {
                                    e.preventDefault();
                                    LK.videoMute();
                                 }}
                              >
                                 Mute Camera
                              </_Button>
                           ) : (
                              <_Button
                                 testId={"local-participant-unmute-camera"}
                                 onButtonClick={(e) => {
                                    e.preventDefault();
                                    LK.videoMute();
                                 }}
                              >
                                 Enable Camera
                              </_Button>
                           )}
                        </Fragment>
                     )}
                  </Fragment>
               )}
            </_Stack>
         )}
      />
   );
}

function Role() {
   const { room, localHost, localParticipant, livekitToken } = useContext(MeetingContext);
   const {
      State: { participant, targetParticipantIsCoHost, targetParticipantIsHost },
      Set: { setRefresh, setLoading, grantRole }
   } = useContext(IParticipantContext);
   const params = useSearchParams();
   const testName = params.get("test_name");

   return (
      <_Stack gap="none">
         {localHost && !targetParticipantIsHost && (
            <_Button
               testId={`grant-host-${participant.name}`}
               className="z-30 p-1 rounded-lg cursor-pointer place-self-start whitespace-nowrap hover:underline"
               onButtonClick={(e) => {
                  e.preventDefault();
                  grantRole({
                     payload: {
                        target_identity: participant.identity,
                        target_role: "host"
                     },
                     token: livekitToken
                  });
                  setRefresh(true);
                  setLoading(true);
               }}
            >
               {"Make Host"}
            </_Button>
         )}
         {localHost && !targetParticipantIsCoHost && (
            <_Button
               testId={`grant-cohost-${participant.name}`}
               className="z-30 p-1 rounded-lg cursor-pointer place-self-start whitespace-nowrap hover:underline"
               onButtonClick={(e) => {
                  e.preventDefault();
                  grantRole({
                     payload: {
                        target_identity: participant.identity,
                        target_role: "coHost"
                     },
                     token: livekitToken
                  });
                  setRefresh(true);
                  setLoading(true);
               }}
            >
               {"Make CoHost"}
            </_Button>
         )}
         {localHost && targetParticipantIsCoHost && (
            <_Button
               testId={`revoke-cohost-${participant.name}`}
               className="z-30 p-1 rounded-lg cursor-pointer place-self-start whitespace-nowrap hover:underline"
               onButtonClick={(e) => {
                  e.preventDefault();
                  grantRole({
                     payload: {
                        target_identity: participant.identity,
                        target_role: "attendee"
                     },
                     token: livekitToken
                  });
                  setRefresh(true);
                  setLoading(true);
               }}
            >
               {"Remove CoHost"}
            </_Button>
         )}
         {/* {localHost && targetParticipantCoHost && (
            <button
               className="z-30 p-1 rounded-lg cursor-pointer place-self-start whitespace-nowrap hover:underline"
               onClick={(e) => {
                  e.preventDefault();
                  setTargetRole("host");
                  setGrantIntent(true);
               }}
            >
               {"Re-Claim Host"}
            </button>
         )} */}
      </_Stack>
   );
}

function Kick() {
   const params = useSearchParams();
   const testName = params.get("test_name");
   const { room, localHost, localParticipant, livekitToken } = useContext(MeetingContext);
   const {
      State: { participant },
      Set: { setRefresh, setLoading }
   } = useContext(IParticipantContext);
   const [notice, setNotice] = useState<boolean>(false);

   const [kickParticipant, { data: banParticipantData, error: banParticipantError, isLoading: banParticipantIsFetching, isSuccess: banParticipantIsSuccess, isError: banParticipantIsError }] =
      apiRoom.useKickParticipantMutation();
   //-left-30 absolute -bottom-10

   useEffect(() => {
      if (banParticipantIsSuccess || banParticipantError) {
         setRefresh(true);
         setLoading(false);
      }
   }, [banParticipantIsSuccess, banParticipantError]);
   return (
      <_DivUNSAFE className="relative grid cursor-pointer grid-cols-1 grid-rows-[minmax(0,auto)_minmax(0,auto)] rounded-lg transition-all duration-200 ease-linear ">
         <_Button
            className="p-1 text-red-300 transition-all duration-200 ease-linear rounded-lg cursor-pointer place-self-start whitespace-nowrap hover:underline"
            onButtonClick={(e) => {
               e.preventDefault();
               setNotice(true);
            }}
            id={`kick-participant-${participant.name}`}
            testId={`kick-participant-${participant.name}`}
         >
            {"Kick Participant"}
         </_Button>
         {notice && (
            <_DivUNSAFE className="w-auto p-2 m-2 text-xs font-bold transition-all duration-100 rounded-md shadow-md min-w-max bg-slate-50">
               <_Stack gap="none">
                  <Header level="h3" label={"Confirm to Kick Participant"} />
                  <_Button
                     className="w-10/12 p-4 m-3 text-lg text-black bg-red-300 rounded-md place-self-center"
                     testId={`kick-participant-${participant.name}-confirm`}
                     onButtonClick={(e) => {
                        e.preventDefault();
                        setNotice(false);
                        setLoading(true);
                        kickParticipant({
                           token: livekitToken,
                           payload: {
                              target_identity: participant?.identity
                              // requesting_identity: localParticipant?.identity
                           }
                        });
                     }}
                  >
                     Kick Now
                  </_Button>
               </_Stack>
            </_DivUNSAFE>
         )}
      </_DivUNSAFE>
   );
}

ParticipantBase.Card = Card;
ParticipantBase.Options = Options;
ParticipantBase.Role = Role;
ParticipantBase.Kick = Kick;

export default ParticipantBase;
