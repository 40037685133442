import ILog from "app/_lib/global/Log";
import { LocalParticipant } from "livekit-client";
import { useContext, useMemo } from "react";
import { MeetingContext } from "../_contexts/MeetingContext";
import { ParticipantMetadata } from "../_types/metadata";
import useRoomMetadata from "./useRoomMetadata";

export default function useBreakoutRecorder({
   breakoutId,
   localParticipant,
   isLocalRecorder
}: {
   breakoutId: string | undefined;
   localParticipant: LocalParticipant | undefined;
   isLocalRecorder: boolean | undefined;
}) {
   const roomMetadata = useRoomMetadata();
   const { recorders } = useContext(MeetingContext);

   const { activeBreakoutRecorderMetadata } = useMemo(() => {
      if (localParticipant && isLocalRecorder === true) {
         const activeBreakoutRecorderMetadata = JSON.parse(localParticipant.metadata || "{}") as ParticipantMetadata;
         return { activeBreakoutRecorderMetadata };
      }
      if (!recorders.length || !breakoutId) {
         return { activeBreakoutRecorderMetadata: undefined };
      }
      const activeBreakoutRecorderParticipant = recorders.find((p) => {
         const metadata = JSON.parse(p.metadata || "{}") as ParticipantMetadata;
         return metadata.role === "recorder" && metadata?.egress?.breakoutId === breakoutId;
      });
      if (activeBreakoutRecorderParticipant) {
         const activeBreakoutRecorderMetadata = JSON.parse(activeBreakoutRecorderParticipant?.metadata || "{}") as ParticipantMetadata;
         return { activeBreakoutRecorderMetadata };
      }
      return { activeBreakoutRecorderMetadata: undefined };
   }, [recorders, breakoutId, roomMetadata]);

   isLocalRecorder && ILog.v("useBreakoutRecorder", { roomMetadata, recorders: JSON.stringify(recorders), breakoutId, localParticipant, activeBreakoutRecorderMetadata });
   ILog.v("useBreakoutRecorder", { activeBreakoutRecorderMetadata, breakoutId, localParticipant, isLocalRecorder, recorders });
   return { activeBreakoutRecorderMetadata };
}
