import { Query } from "@directus/sdk";
import { getMySession } from "app/(platform)/(authentication)/_helpers/getMySession";
import { apiWS } from "app/(platform)/@search/_api/apiWS";
import { tryDirectusWS } from "app/_lib/directus/ws";
import ILog from "app/_lib/global/Log";
import { REST } from "app/_services/rest/REST";
import { PA } from "app/_types/PATypes";
import { M } from "app/_types/Schema";
import { TAGS } from "../../../../_services/redux/api/TAGS";
import { handleRTKError, handleRTKTags } from "../../../../_services/redux/api/_helpers/rtkHelpers";
import { apiService } from "../../../../_services/redux/api/apiService";
export const apiCampaign = apiService.injectEndpoints({
   endpoints: (builder) => ({
      getOneCampaign: builder.query<
         M.Campaign | null,
         {
            query?: Query<M.CustomDirectusTypes, M.Campaign[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
            id?: string;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               const res = await REST.CampaignService.getOne({
                  token: token,
                  query: arg.query,
                  id: arg.id
               });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "apiCampaign_getOne" });
            }
         },

         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.CAMPAIGN, includeList: true }]
            })
      }),
      getManyCampaign: builder.query<
         M.Campaign[],
         {
            query: Query<M.CustomDirectusTypes, M.Campaign[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               ILog.v("getManyCampaign - query", { arg });
               const campaign = await REST.CampaignService.getMany({
                  token: token,
                  query: arg.query
               });
               ILog.v("getManyCampaign - resolved", { campaign });
               if (!campaign) throw new Error("No campaign found");

               return { data: campaign };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "getManyCampaign" });
            }
         },

         providesTags: (result, error, query) => {
            ILog.v("getManyCampaign - provideTags", { result, error, query });
            const tags = handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.CAMPAIGN, includeList: true }]
            });
            ILog.v("getManyCampaign - final, tags", { query, tags });
            return tags;
         }
      }),
      updateCampaignById: builder.mutation<M.Campaign, { role: PA.RoleQueries | undefined; token?: string | undefined; id: string; payload: Partial<M.Campaign> }>({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const response = await REST.CampaignService.updateById({ token, id: arg.id, payload: arg.payload });
               if (!response) throw new Error(TAGS.UNKNOWN_ERROR);
               return { data: response };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "updateCampaignById" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.CAMPAIGN, includeList: true }]
            })
      }),
      campaignWS: builder.query<
         M.Campaign[],
         {
            token: string | undefined;
            role: PA.RoleQueries | undefined;
            campaignId: string | undefined;
         }
      >({
         extraOptions: {
            maxRetries: 3
         },
         queryFn: async (arg, api, extraOptions, baseQuery) => {
            try {
               if (!arg.role) throw new Error("no token, roleId, or role found");

               const res = (await api
                  .dispatch(
                     apiWS.endpoints.emitWSMessage.initiate({
                        role: arg.role,
                        message: {
                           type: "items",
                           action: "read",
                           collection: "campaign",
                           query: { filter: { id: { _eq: arg.campaignId } } }
                        }
                     })
                  )
                  .unwrap()) as M.Campaign[] | null;

               if (!res) throw new Error("No data found");

               return { data: res };
            } catch (e) {
               return handleRTKError({ error: e, endpoint: "apiCampaign_getManyWS" });
            }
         },

         async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch, getCacheEntry }) {
            await tryDirectusWS<M.Campaign>({
               dispatch,
               cacheDataLoaded,
               role: arg.role,
               getCacheEntry,
               collection: "campaign",
               query: { filter: { id: { _eq: arg.campaignId } } },
               onUpdateCachedData(message) {
                  const { type, data, uid, event, status } = message;

                  if (event === "init" && data?.length > 0) {
                     // ILog.v("myCDU_updateCachedData_INIT", { data });
                     // updateCachedData((draft) => {
                     //    ILog.v("myCDU_updateCachedData_INIT_INSERT_ALL", { draft, data });
                     //    return data; // Update the type of the return value
                     // });
                  } else if (event === "create") {
                     data.forEach((d) => {
                        updateCachedData((draft) => {
                           ILog.v("myCDU_updateCachedData_POSTINIT_CREATE", { uid, data, type, event, d });
                           draft?.push(d);
                        });
                     });
                  } else if (event === "update") {
                     updateCachedData((draft) => {
                        const updated = data;
                        // entries should not include any entries with the same id as the updated array
                        let entries = draft?.filter((d) => !updated.some((u) => u.id === d.id));
                        entries?.push(...updated);
                        ILog.v("myCDU_updateCachedData_POSTINIT_UPDATE", { uid, data, type, event, entries });
                        return entries;
                     });
                  }
               },
               uidArgs: arg.campaignId,
               cacheEntryRemoved
            });

            ILog.v("listenForCollectionEvents_apiWS_CLOSING_WS", {});
         },
         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query
            })
      })
   }),
   overrideExisting: true
});
