import { getMySession } from "app/(platform)/(authentication)/_helpers/getMySession";

import { Directus } from "app/_lib/directus/DirectusRest";
import { Endpoints } from "app/_lib/global/Endpoints";
import { TAGS } from "app/_services/redux/api/TAGS";
import { handleRTKError, handleRTKTags } from "app/_services/redux/api/_helpers/rtkHelpers";
import { apiService } from "app/_services/redux/api/apiService";
import { PA } from "app/_types/PATypes";
import generateEmailInvite from "../_actions/generateEmailInvite";
import generatePublicJoinLink from "../_actions/generatePublicJoinLink";

export const apiCampaignDetails = apiService.injectEndpoints({
   endpoints: (builder) => ({
      banCampaignConnection: builder.mutation<PA.Flows.Campaign.BanCDU["Res"], { role: PA.RoleQueries | undefined; token?: string | undefined; payload: PA.Flows.Campaign.BanCDU["Req"] }>({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const { data: response } = await Directus.post<PA.Flows.Campaign.BanCDU["Res"], PA.Flows.Campaign.BanCDU["Req"]>({
                  endpoint: Endpoints.Flows.Campaign.BanCDU,
                  token: token,
                  payload: arg.payload
               });
               if (!response) throw new Error("Invalid Directus Response.");

               return { data: response };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "banCampaignConnection" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query
            })
      }),
      inviteByEmail: builder.mutation<PA.Flows.Campaign.InviteEmail["Res"], { role: PA.RoleQueries | undefined; token?: string | undefined; payload: PA.Flows.Campaign.InviteEmail["Req"] }>({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               if (!authenticatedSession?.user.id) throw new Error(TAGS.UNKNOWN_ERROR);

               const res = await generateEmailInvite(arg.payload.campaign, arg.payload.email, arg.payload.roles as any);

               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "inviteByEmail" });
            }
         },
         invalidatesTags: (result, error, query) => handleRTKTags<typeof result, typeof error, typeof query>({ result, error, query })
      }),
      inviteByLink: builder.mutation<string, { role: PA.RoleQueries | undefined; token?: string | undefined; payload: { campaign: string } }>({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               if (!authenticatedSession?.user.id) throw new Error(TAGS.UNKNOWN_ERROR);

               const res = await generatePublicJoinLink(arg.payload.campaign);
               if (!res?.data) throw new Error("Invalid inviteByLink Response.");
               return { data: res.data };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "inviteByLink" });
            }
         },
         invalidatesTags: (result, error, query) => handleRTKTags<typeof result, typeof error, typeof query>({ result, error, query })
      })
   }),

   overrideExisting: true
});
