import useAuthenticatedPublicRoles from "app/(platform)/(authentication)/_hooks/useAuthenticatedPublicRoles";
import { apiCampaignDetails } from "app/(platform)/workspaces/[campaign_id]/details/_api/details";
import _Button from "app/_components_v2/inputs/_Button";
import ILog from "app/_lib/global/Log";
import { useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { MeetingContext } from "../../_contexts/MeetingContext";
import useRoomMetadata from "../../_hooks/useRoomMetadata";

export function InviteParticipantsToWorkspace() {
   const { localHost, LK } = useContext(MeetingContext);

   const roomMetadata = useRoomMetadata();

   const { authenticatedRole } = useAuthenticatedPublicRoles();
   const [invite, { isSuccess, isError, isLoading, isUninitialized, data }] = apiCampaignDetails.useInviteByLinkMutation();

   useEffect(() => {
      if (!!data) {
         ILog.v("InviteParticipantsToWorkspace", "data", { data });
         LK.sendDataPacket({
            payload: {
               type: "meeting/setWorkspaceInviteToken",
               payload: data
            }
         });
         toast.success("Invitation sent!");
      }
   }, [data]);
   const campaignId = roomMetadata?.meetingCampaignId;
   if (localHost && !!authenticatedRole && !!campaignId) {
      return (
         <_Button
            variant={"primary"}
            type="button"
            id="invite-participants-to-workspace-button"
            testId="invite-participants-to-workspace-button"
            disabled={isLoading}
            onButtonClick={() => {
               invite({ role: authenticatedRole, payload: { campaign: campaignId } });
            }}
         >
            Invite Meeting Participants to Workspace
         </_Button>
      );
   }
}
