import { BreakoutFormContext, useBreakoutFormContext } from "app/(platform)/meeting-room/_hooks/useBreakoutFormContext";
import { SuccessRefreshType } from "app/_hooks/useSuccessRefresh";
import ILog from "app/_lib/global/Log";
import BreakoutBase from ".";

export default function Context(props: { success: SuccessRefreshType }) {
   const { success } = props;

   const context = useBreakoutFormContext(success);

   ILog.v("BreakoutContext", { context });
   return (
      <BreakoutFormContext.Provider value={context}>
         <BreakoutBase.Form />
      </BreakoutFormContext.Provider>
   );
}
