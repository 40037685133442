import Placeholder from "@tiptap/extension-placeholder";
import { MeetingContext } from "app/(platform)/meeting-room/_contexts/MeetingContext";
import { ComponentEditor } from "app/(platform)/workspaces/[campaign_id]/(editor)/_components/ComponentEditor";
import { IgnoreEnter } from "app/(platform)/workspaces/[campaign_id]/(editor)/_components/plugins/IgnoreEnter";
import _Stack from "app/_components_v2/layout/_Stack";
import ILog from "app/_lib/global/Log";
import { VariantProps, cva } from "class-variance-authority";
import { DateTime } from "luxon";
import { useContext, useState } from "react";
import { v4 } from "uuid";
import * as Y from "yjs";
import { useCurrentBreakout } from "../../_contexts/BreakoutContext";

type ChatFormProps = {} & VariantProps<typeof ChatFormVariants>;

const ChatFormVariants = cva("", {
   variants: {
      variant: {
         primary: ""
      }
   },
   compoundVariants: [
      {
         variant: [],
         class: ""
      }
   ],
   defaultVariants: {}
});

function ChatForm({}: ChatFormProps) {
   const [virtualDocumentId, setVirtualDocumentId] = useState<string>(v4());
   const { localParticipant, LK } = useContext(MeetingContext);
   const currentBreakout = useCurrentBreakout();
   //
   if (!localParticipant || !currentBreakout) return null;
   return (
      <_Stack className={`bg-white rounded`}>
         <ComponentEditor
            addTopPriorityExtensions={[IgnoreEnter, Placeholder.configure({ placeholder: "Type your message here..." })]}
            saveOnEnterCancelOnEscape={true}
            includeCommandItems={false}
            expectAuthentication={false}
            editorClassAttributes={`editor editor-content h-fit max-w-full mx-auto focus:outline-hidden border-black border-2 p-2`}
            role={undefined}
            token={undefined}
            documentId={virtualDocumentId}
            testId={""}
            editable={true}
            shouldConnectHocus={false}
            shouldSetEditor={false}
            shouldSetEditorOnCreate={false}
            saveContentVersion={true}
            contentOverride={null}
            callToAction="Send"
            callToCancel="Clear"
            defaultDirty={true}
            collaboratorName={undefined}
            onFocusProvider={(provider) => {}}
            onSubmit={(yDoc, editorInstance, provider) => {
               const message = Buffer.from(Y.encodeStateAsUpdate(yDoc)).toString("base64");
               ILog.v("onSubmit", { yDoc, message });
               LK.sendDataPacket({
                  payload: {
                     type: "chatMessage/addChatMessage",
                     payload: {
                        id: virtualDocumentId,
                        identity: localParticipant.identity,
                        breakoutId: currentBreakout.id,
                        message,
                        refreshId: virtualDocumentId,
                        createdAt: DateTime.now().toMillis(),
                        edited: false,
                        name: localParticipant.name
                     }
                  }
               });
               setVirtualDocumentId(v4());
               provider?.destroy();
               editorInstance.destroy();
            }}
         />
      </_Stack>
   );
}

export default ChatForm;
