"use client";

import { LiveKitRoom } from "@livekit/components-react";
import { useSearchParams } from "next/navigation";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";
import LayoutState from "./(platform)/LayoutState.client";
import { IRootState, useAppStore } from "./_contexts/ReduxProvider";
import ILog from "./_lib/global/Log";
import { PublicEnv } from "./_lib/global/PublicEnv";
import LivekitEnsureRoom from "./LivekitEnsureRoom.client";

export default function LivekitRoom_Client({ children, search, meeting }: { children: React.ReactNode; search: React.ReactNode; meeting: React.ReactNode }) {
   const livekitToken = useSelector((state: IRootState) => state.meeting.livekitToken);
   const params = useSearchParams();
   const recordertoken = params.get("recordertoken");
   const store = useAppStore();
   ILog.v("LivekitRoom_Client", { livekitToken });
   if (!livekitToken && !recordertoken) {
      return (
         <LayoutState search={search} meeting={meeting}>
            {children}
         </LayoutState>
      );
   }
   const finalToken = recordertoken || livekitToken || undefined;

   return (
      <ErrorBoundary fallback={<>Room error.</>}>
         <LiveKitRoom
            serverUrl={PublicEnv.LivekitHostURL}
            token={finalToken}
            // connect={ready}
            // options={roomOptions}
            onError={(error) => {
               ILog.v("onError_LiveKitRoom", { error });
               ILog.v("onError_LiveKitRoom", { error });
            }}
            onDisconnected={async () => {
               ILog.v("onDisconnected", {});
               ILog.v("onDisconnected", {});
               store.dispatch({ type: "meeting/setLivekitToken", payload: null });
            }}
            // onConnected={() => {}}
            video={true}
            audio={true}

            // connectOptions={{}}
            // options={{ disconnectOnPageLeave: false }}
         >
            <ErrorBoundary
               onError={(error) => {
                  ILog.v("onError_LivekitRoom2", { error });
               }}
               fallback={<>Room error.</>}
            >
               <LivekitEnsureRoom livekitToken={finalToken}>
                  <LayoutState search={search} meeting={meeting}>
                     {children}
                  </LayoutState>
               </LivekitEnsureRoom>
            </ErrorBoundary>
         </LiveKitRoom>
      </ErrorBoundary>
   );
}
