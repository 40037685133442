import ILog from "app/_lib/global/Log";
import { useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MeetingContext } from "../_contexts/MeetingContext";
import { selectAllBreakouts, selectMainBreakout } from "../_services/breakoutSlice";
import { IRootState } from "app/_contexts/ReduxProvider";

export default function useBreakoutConsistency() {
   const { roomParticipants, localHost, LK } = useContext(MeetingContext);

   const mainBreakout = useSelector((state: IRootState) => selectMainBreakout(state));
   const breakouts = useSelector((state: IRootState) => selectAllBreakouts(state));

   const { orphans } = useMemo(() => {
      if (localHost) {
         const includedParticipants = breakouts.map((b) => b.participants.map((p) => p).flat()).flat();
         const orphans = roomParticipants?.filter((p) => !includedParticipants.find((ip) => ip === p.identity)) || [];

         return { orphans: orphans || [] };
      }
      return { orphans: [] };
   }, [roomParticipants, breakouts, localHost]);

   const { exited } = useMemo(() => {
      if (localHost && roomParticipants?.length) {
         const breakoutParticipants = breakouts.map((b) => b.participants).flat();
         const exited = breakoutParticipants?.filter((p) => !roomParticipants.find((rp) => rp.identity === p)) || [];
         return { exited: exited };
      }
      return { exited: [] };
   }, [breakouts, localHost, roomParticipants, orphans]);

   const { duplicateUsers } = useMemo(() => {
      if (localHost) {
         const duplicateWithin = roomParticipants?.filter((p) => {
            const breakout = breakouts.find((b) => b.participants.filter((bp) => bp === p.identity).length > 1);
            return breakout;
         });
         const duplicateAcross = roomParticipants?.filter((p) => {
            const breakoutCount = breakouts.filter((b) => b.participants.filter((bp) => bp === p.identity)).length;
            return breakoutCount > 1;
         });

         return { duplicateUsers: duplicateWithin || duplicateAcross || [] };
      }
      return { duplicateUsers: [] };
   }, [roomParticipants, breakouts, localHost]);

   const { duplicateMains } = useMemo(() => {
      if (localHost) {
         const duplicateMains = breakouts.filter((b) => b.mainBreakout);
         const isDuplicateMain = duplicateMains?.length > 1;

         return { duplicateMains: isDuplicateMain ? duplicateMains : [] };
      }
      return { duplicateMains: [] };
   }, [breakouts, mainBreakout, localHost]);

   const { mainWithoutFacilitator } = useMemo(() => {
      if (localHost) {
         const mainFacilitator = mainBreakout?.facilitatorIdentity;
         const roomHasFacilitator = roomParticipants?.find((p) => p.identity === mainFacilitator);
         const mainWithoutFacilitator = mainBreakout && !roomHasFacilitator;
         return { mainWithoutFacilitator: mainWithoutFacilitator };
      } else {
         return { mainWithoutFacilitator: false };
      }
   }, [localHost, mainBreakout, roomParticipants, mainBreakout?.facilitatorIdentity]);

   useEffect(() => {
      if (localHost) {
         if (exited?.length) {
            exited.forEach((p) => {
               const breakout = breakouts.find((b) => b.participants.find((bp) => bp === p));
               ILog.v("Breakout", "Removing participant", breakout, p);
               breakout &&
                  LK.sendDataPacket({
                     payload: {
                        type: "breakout/removeParticipant",
                        payload: {
                           id: breakout?.id,
                           identity: p
                        }
                     }
                  });
            });
         }
         if (orphans?.length) {
            orphans.forEach((p) => {
               LK.Breakout({ breakout: mainBreakout }).defaultBreakout({ connectedParticipant: p, breakoutId: mainBreakout?.id });
            });
         }
         if (duplicateUsers?.length) {
            duplicateUsers.forEach((p) => {
               LK.Breakout({ breakout: mainBreakout }).checkDuplicateBreakout(p.identity);
            });
         }
         if (duplicateMains?.length) {
            let deletedMains = duplicateMains;
            deletedMains.pop();
            if (deletedMains) {
               deletedMains.forEach((b) => {
                  LK.sendDataPacket({
                     payload: {
                        type: "breakout/removeBreakout",
                        payload: {
                           id: b.id
                        }
                     }
                  });
               });
            }
         }
         if (mainWithoutFacilitator && mainBreakout?.participants?.length) {
            const newFacilitator = LK.Breakout({ breakout: mainBreakout }).facilitatorFallback(mainBreakout?.participants);

            newFacilitator &&
               LK.sendDataPacket({
                  payload: {
                     type: "breakout/setBreakoutFacilitator",
                     payload: {
                        id: mainBreakout.id,
                        identity: newFacilitator
                     }
                  }
               });
            toast.info("Main Room has no facilitator. Assigning a new one.", { toastId: "mainWithoutFacilitator" });
         }
      }
   }, [orphans, duplicateUsers, duplicateMains, mainBreakout, mainWithoutFacilitator, exited, localHost, breakouts, LK, orphans?.length]);
}
