"use client";

import { BellIcon } from "@heroicons/react/24/outline";
import useAuthenticatedPublicRoles from "app/(platform)/(authentication)/_hooks/useAuthenticatedPublicRoles";
import _Paragraph from "app/_components_v2/data-display/Paragraph";
import _Icon from "app/_components_v2/data-display/_Icon";
import _Button from "app/_components_v2/inputs/_Button";
import { FloatingNav } from "app/_components_v2/layout/FloatingNav";
import _Stack from "app/_components_v2/layout/_Stack";
import React from "react";
import useNotifications from "./useNotifications";

interface NotificationsProps {}
export default function Notifications({}: NotificationsProps) {
   const { notifications, updateNotification } = useNotifications({});
   const { authenticatedRole } = useAuthenticatedPublicRoles();

   const inboxCount = notifications?.filter((item) => item.status === "inbox").length;
   return (
      <FloatingNav
         referenceChild={
            <_Button testId="open-notifications-menu" onButtonClick={() => {}} color="black" aria-label="Open Notifications" disabled={false}>
               <_Icon variant={inboxCount && inboxCount > 0 ? "notification" : "primary"}>
                  {({ iconClassname }) => <React.Fragment>{inboxCount && inboxCount > 0 ? <span>{inboxCount}</span> : <BellIcon className="w-6 h-6" />}</React.Fragment>}
               </_Icon>
            </_Button>
         }
         floatingChild={({ setHovered }) =>
            inboxCount && inboxCount > 0 ? (
               <_Stack gap={"md"} className="m-2">
                  {notifications &&
                     notifications?.length > 0 &&
                     notifications.map((item) => {
                        return (
                           <_Stack key={item.id} gap={"md"} className="m-2">
                              <_Button
                                 testId={`notification-read-button`}
                                 onButtonClick={() => {
                                    updateNotification({
                                       id: String(item.id),
                                       role: authenticatedRole,
                                       payload: {
                                          status: "read"
                                       }
                                    });
                                 }}
                              />
                              <_Paragraph label={item.subject} />
                              <_Paragraph label={item.message} />
                           </_Stack>
                        );
                     })}
               </_Stack>
            ) : (
               <_Paragraph label="No items." />
            )
         }
      />
   );
}
