import { MeetingContext } from "app/(platform)/meeting-room/_contexts/MeetingContext";
import { selectAgendaItemsByBreakoutId } from "app/(platform)/meeting-room/_services/agendaItemSlice";
import { R } from "app/(platform)/meeting-room/_types";
import Header from "app/_components_v2/data-display/Header";
import _Button from "app/_components_v2/inputs/_Button";
import _Stack from "app/_components_v2/layout/_Stack";
import { ListIterator } from "app/_components_v2/todo/ListIterator";
import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useCurrentBreakout } from "../../_contexts/BreakoutContext";
import { IRootState } from "app/_contexts/ReduxProvider";

export default function Card(props: { breakout: R.Breakout.BreakoutRoom; index: number }) {
   const { breakout: breakout, index } = props;
   const { localParticipant, LK, roomParticipants } = useContext(MeetingContext);
   const currentBreakout = useCurrentBreakout();
   const agendaItems = useSelector((state: IRootState) => selectAgendaItemsByBreakoutId(state, breakout.id));
   const labeledParticipants = useMemo(() => {
      return roomParticipants
         ?.filter((participant) => {
            return breakout.participants.find((identity) => identity === participant.identity);
         })
         .map((participant) => {
            if (participant.identity === breakout.facilitatorIdentity) {
               return {
                  ...participant,
                  name: `${participant?.name} (Facilitator)`
               };
            } else {
               return participant;
            }
         });
   }, [breakout.facilitatorIdentity, breakout.participants.length, roomParticipants, roomParticipants?.length]);
   return (
      <_Stack>
         <_Stack flexBehavior={"row"}>
            <Header
               level="h2"
               className="mb-2 text-lg border-b-2 h-fit border-slate-600"
               label={`${breakout.mainBreakout ? "Main Room" : `Breakout ${index}`} ${currentBreakout?.id === breakout.id ? "-- You are here" : ""}`}
            />
            {currentBreakout && breakout.id !== currentBreakout.id && breakout.roomOpen && (
               <_Button
                  testId={`join-breakout-${index}-button`}
                  onButtonClick={(e) => {
                     e.preventDefault();
                     LK.Breakout({ breakout: breakout }).joinRequest({ targetParticipant: localParticipant });
                  }}
               >{`${breakout.mainBreakout ? "Join" : "Join"}`}</_Button>
            )}
         </_Stack>
         {breakout.active && (
            <_Stack className="ml-4">
               <ListIterator items={agendaItems} noLengthLabel="No Agenda Items" property={"title"} />

               <ListIterator items={labeledParticipants} noLengthLabel="No Participants" property={"name"} />
            </_Stack>
         )}
      </_Stack>
   );
}
