"use client";

import { useFallback } from "app/_hooks/useFallback";
import ILog from "app/_lib/global/Log";
import { M } from "app/_types/Schema";
import { useParams } from "next/navigation";
import { useContext, useMemo } from "react";
import { UserIdContext } from "../(authentication)/_contexts/AuthenticatedSessionProvider";
import useAuthenticatedPublicRoles from "../(authentication)/_hooks/useAuthenticatedPublicRoles";
import { apiCampaign } from "../workspaces/[campaign_id]/_api/campaign";
import useAllCDURoleQueries from "../workspaces/[campaign_id]/_hooks/useAllCDUQueries";
import { CampaignContext } from "./CampaignContext";

export default function CampaignProvider(props: { children: React.ReactNode; campaignIdOverride?: string | undefined; campaign?: M.Campaign | undefined }) {
   const { children, campaignIdOverride, campaign: propsCampaign } = props;
   const { userId } = useContext(UserIdContext);
   const propsCampaignId = campaignIdOverride || propsCampaign?.id;

   const { campaign_id: paramsId } = useParams() as { campaign_id: string };

   const campaignId = useMemo(() => {
      return propsCampaignId || paramsId;
   }, [propsCampaignId, paramsId]);
   const { connectionRole } = useAllCDURoleQueries({ campaignId });
   const { authenticatedRole, publicRole } = useAuthenticatedPublicRoles();
   const readRole = useFallback([connectionRole, authenticatedRole, publicRole]);
   const { data: campaignData } = apiCampaign.useGetOneCampaignQuery(
      {
         role: readRole,
         id: campaignId,
         query: {
            filter: { id: { _eq: campaignId } }
         }
      },
      { skip: !userId || !campaignId || !readRole || !!propsCampaign }
   );

   const campaign = useMemo(() => {
      return propsCampaign || campaignData || undefined;
   }, [campaignData, propsCampaign]);

   ILog.v("CampaignProvider", { campaignId, campaign: propsCampaign, campaignData });
   ILog.v("PROVIDER", {});

   return <CampaignContext.Provider value={{ campaign, campaignId }}>{children}</CampaignContext.Provider>;
}
