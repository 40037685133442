import { Query } from "@directus/sdk";
import { getMySession } from "app/(platform)/(authentication)/_helpers/getMySession";
import { REST } from "app/_services/rest/REST";
import { PA } from "app/_types/PATypes";
import { M } from "app/_types/Schema";
import { TAGS } from "../../../_services/redux/api/TAGS";
import { handleRTKError, handleRTKTags } from "../../../_services/redux/api/_helpers/rtkHelpers";
import { apiService } from "../../../_services/redux/api/apiService";
export const apiDirectusNotifications = apiService.injectEndpoints({
   endpoints: (builder) => ({
      getOneDirectusNotifications: builder.query<
         M.DirectusNotifications | null,
         {
            query?: Query<M.CustomDirectusTypes, M.DirectusNotifications[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
            id?: string;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });

               const res = await REST.DirectusNotificationsService.getOne({
                  token: token,
                  query: arg.query,
                  id: arg.id
               });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "apiDirectusNotifications_getOne" });
            }
         },

         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.DIRECTUS_NOTIFICATIONS, includeList: true }]
            })
      }),
      getManyDirectusNotifications: builder.query<
         M.DirectusNotifications[],
         {
            query: Query<M.CustomDirectusTypes, M.DirectusNotifications[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               const res = await REST.DirectusNotificationsService.getMany({
                  token: token,
                  query: arg.query
               });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "getManyDirectusNotifications" });
            }
         },

         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({ result, error, query, tagSelectors: [{ collectionTag: TAGS.DIRECTUS_NOTIFICATIONS, includeList: false }] })
      }),
      updateDirectusNotificationsById: builder.mutation<
         M.DirectusNotifications,
         { role: PA.RoleQueries | undefined; token?: string | undefined; id: string; payload: Partial<M.DirectusNotifications> }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const response = await REST.DirectusNotificationsService.updateById({ token, id: Number(arg.id), payload: arg.payload });
               return { data: response };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "updateDirectusNotificationsById" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({ result, error, query, tagSelectors: [{ collectionTag: TAGS.DIRECTUS_NOTIFICATIONS, includeList: false }] })
      }),
      createDirectusNotifications: builder.mutation<M.DirectusNotifications, { role: PA.RoleQueries | undefined; token?: string | undefined; payload: M.IOmit<M.DirectusNotifications> }>({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const res = await REST.DirectusNotificationsService.create({ token: token!, payload: arg.payload });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "createDirectusNotifications" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.DIRECTUS_NOTIFICATIONS, includeList: false }]
            })
      })
   }),
   overrideExisting: true
});
