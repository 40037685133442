import { BarsArrowDownIcon, BarsArrowUpIcon } from "@heroicons/react/24/solid";
import { R } from "app/(platform)/meeting-room/_types";
import _Icon from "app/_components_v2/data-display/_Icon";
import _Paragraph from "app/_components_v2/data-display/Paragraph";
import _Stack from "app/_components_v2/layout/_Stack";
import React, { useState } from "react";
import CardRound from "../card/CardRound";

const ContainerRounds = (props: { rounds: R.Round.Round[]; agendaItemIndex: number; agendaItemComplete: boolean }) => {
   const { rounds, agendaItemIndex, agendaItemComplete } = props;
   const [containerOpen, setContainerOpen] = useState(agendaItemComplete ? false : true);

   if (rounds) {
      return (
         <_Stack gap={"sm"}>
            <_Stack flexBehavior={"row"} gap={"sm"}>
               <_Paragraph label={`${rounds.length} ${rounds.length >= 2 ? "Rounds" : "Round"}`} />
               {containerOpen ? (
                  <_Icon textColor={"primary"} size={"xs"}>
                     {({ iconClassname }) => <BarsArrowUpIcon className={iconClassname} type="button" onMouseDown={() => setContainerOpen(false)} />}
                  </_Icon>
               ) : (
                  <_Icon textColor={"primary"} size={"xs"}>
                     {({ iconClassname }) => <BarsArrowDownIcon className={iconClassname} type="button" onMouseDown={() => setContainerOpen(true)} />}
                  </_Icon>
               )}
            </_Stack>

            {containerOpen && (
               <_Stack gap={"sm"}>
                  {rounds.map((round, index) => (
                     <CardRound key={round.id} round={round} roundIndex={index} agendaItemIndex={agendaItemIndex} />
                  ))}
               </_Stack>
            )}
         </_Stack>
      );
   }
   return null;
};

export default React.memo(ContainerRounds);
