"use client";

import ILog from "app/_lib/global/Log";
import LivekitRoom_Client from "app/LivekitRoom.client";
import CampaignProvider from "./_contexts/CampaignProvider";
import PathProvider from "./_contexts/PathProvider";

export default function Layout(props: { children: React.ReactNode; meeting: React.ReactNode; search: React.ReactNode }) {
   const { search, meeting, children } = props;

   ILog.v("_Layout", { meeting: props.meeting, search: props.search });
   return (
      <PathProvider>
         <CampaignProvider>
            <LivekitRoom_Client search={search} meeting={meeting}>
               {children}
            </LivekitRoom_Client>
         </CampaignProvider>
      </PathProvider>
   );
}
