import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { R } from "app/(platform)/meeting-room/_types";
import _Paragraph from "app/_components_v2/data-display/Paragraph";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";

export default function CardListeningRequest(props: { listeningRequest: R.ListeningRequest.ListeningRequest }) {
   const { listeningRequest } = props;

   return (
      <_DivUNSAFE data-testid={`listening-request-sent-${listeningRequest.speakerName}`} className="flex flex-row p-2 mb-2 bg-white rounded-md flex-nowrap text-slate-900 hover:shadow-lg">
         <_Paragraph key={`CardListeningRequest-${listeningRequest.speakerIdentity}`} label={`${listeningRequest.listenerName} -- Request Sent`} />
         <CheckCircleIcon className="w-5 h-5 text-green-500" aria-hidden="true" />
      </_DivUNSAFE>
   );
}
