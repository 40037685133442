"use client";

import { useMaybeRoomContext } from "@livekit/components-react";
import React, { Fragment } from "react";
import { useInterval } from "usehooks-ts";
import MeetingContextProvider from "./(platform)/meeting-room/_contexts/MeetingContextProvider";
import ILog from "./_lib/global/Log";
import LivekitEnsureBreakoutContext from "./LivekitEnsureBreakoutContext";

export default function LivekitEnsureRoom({ children, livekitToken }: { children: React.ReactNode; livekitToken: string | undefined }) {
   const room = useMaybeRoomContext();
   const [metadataDefined, setMetadataDefined] = React.useState(!!room?.metadata);
   useInterval(
      () => {
         ILog.v("LivekitEnsureRoom_interval", { livekitToken, room, metadata: room?.metadata });
         if (room?.metadata) {
            setMetadataDefined(true);
         }
      },
      !metadataDefined ? 1000 : null
   );
   if (!livekitToken || !room || !metadataDefined) {
      ILog.v("LivekitEnsureRoom_children", { livekitToken, room, metadata: room?.metadata });
      return <Fragment>{children}</Fragment>;
   }
   ILog.v("LivekitEnsureRoom3", { livekitToken, room, metadata: room.metadata });

   return (
      <MeetingContextProvider livekitToken={livekitToken}>
         <LivekitEnsureBreakoutContext>{children}</LivekitEnsureBreakoutContext>
      </MeetingContextProvider>
   );
}
