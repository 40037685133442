"use client";
import { usePathname } from "next/navigation";
import { PathContext } from "./PathContext";

export default function PathContextProvider(props: { children: React.ReactNode }) {
   const { children } = props;
   const pathname = usePathname();

   return <PathContext.Provider value={{ pathname }}>{children}</PathContext.Provider>;
}
