"use client";
import { useEnsureRoom, useParticipants } from "@livekit/components-react";
import Metadata from "app/(platform)/meeting-room/_services/livekit/metadata";
import { IRootState, useAppStore } from "app/_contexts/ReduxProvider";
import ILog from "app/_lib/global/Log";
import { DataPacket_Kind, Participant, RemoteParticipant, RemoteTrackPublication, RoomEvent, TrackPublication } from "livekit-client";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { MeetingContextType } from "../_contexts/MeetingContext";
import SVC from "../_services/livekit/service";
import useRoomMetadata from "./useRoomMetadata";

export default function useLivekit({ livekitToken }: { livekitToken: string }): MeetingContextType {
   const router = useRouter();
   const params = useSearchParams();
   const recorderToken = params.get("recordertoken");
   const { localCoHost, localHost, audioEnabled, videoEnabled, empathyCafe } = useSelector((state: IRootState) => state.meeting);
   const meeting = useSelector((state: IRootState) => state.meeting);
   const room = useEnsureRoom();
   const pathname = usePathname();
   const store = useAppStore();
   // const { localParticipant } = useLocalParticipant({ room });
   const localParticipant = room?.localParticipant;
   const roomMetadata = useRoomMetadata();

   const participants = useParticipants();

   const ISVC = useMemo(() => {
      return new SVC({ room, store });
   }, [room, room.state]);

   const stateRequested = useRef(false);

   ILog.v("useLivekit", "localParticipant", { localParticipant, room, meetingId: room?.name });
   useEffect(() => {
      if (localParticipant && room.state === "connected") {
         ILog.v("uselivekit_mounting_event_listeners", { isRecorder: !!recorderToken });
         if (!stateRequested.current) {
            stateRequested.current = true;
            ISVC.sendDataPacket({
               payload: {
                  type: "REQUEST_MEETING_STATE",
                  payload: {}
               }
            });
         }
         room
            .on(RoomEvent.ParticipantPermissionsChanged, (prevPermissions, changedParticipant) => {
               ISVC.Event().onParticipantPermissionsChanged(prevPermissions, changedParticipant);
            })
            .on(RoomEvent.ConnectionStateChanged, (connectionState) => {
               ISVC.Event().onConnectionStateChanged(connectionState, router);
            })

            .on(RoomEvent.DataReceived, (payload: Uint8Array, participant: RemoteParticipant | undefined, kind: DataPacket_Kind | undefined) => {
               ISVC.Event().onDataReceived(router, participant, payload);
            })

            .on(RoomEvent.RoomMetadataChanged, (roomMetadata) => {
               ISVC.Event().onRoomMetadataChanged(roomMetadata, router, pathname, !!recorderToken);
            })
            // if participant joined, host should send meeting state to participant
            .on(RoomEvent.ParticipantConnected, (connectedParticipant: RemoteParticipant) => {
               ISVC.Event().onParticipantConnected(connectedParticipant);
            })
            .on(RoomEvent.ParticipantDisconnected, (disconnectedParticipant) => {
               ISVC.Event().onParticipantDisconnected(disconnectedParticipant, participants);
            })
            .on(RoomEvent.ParticipantMetadataChanged, (prevMetadata, changedParticipant) => {
               ISVC.Event().onParticipantMetadataChanged(prevMetadata, changedParticipant);
            })
            .on(RoomEvent.TrackMuted, (track: TrackPublication) => {
               ISVC.Event().onTrackMuted(track);
            })
            .on(RoomEvent.TrackUnmuted, (track: TrackPublication, unmutedParticipant: Participant) => {
               ISVC.Event().onTrackUnmuted(track, unmutedParticipant);
            })
            .on(RoomEvent.TrackPublished, (publication: RemoteTrackPublication, participant) => {
               ISVC.Event().onTrackPublished(publication, participant);
            })
            // if ActiveSpeakerChanged, evaluate whether the participant is a host or coHost
            .on(RoomEvent.ActiveSpeakersChanged, (eventParticipants) => {
               ISVC.Event().onActiveSpeakersChanged(room, eventParticipants);
            })
            .on(RoomEvent.TrackUnpublished, (publication, participant) => {
               ISVC.Event().onTrackUnPublished(publication, participant);
            });
      } else if (room.state === "disconnected") {
         store.dispatch({ type: "meeting/setLivekitToken", payload: null });
      }

      return () => {};
   }, [room, room?.state, room?.localParticipant, room?.name, ISVC]);

   useEffect(() => {
      if (!roomMetadata) {
      } else {
         const { isHost, isCoHost } = Metadata.parseRoles(roomMetadata, localParticipant.identity);
         if ((!!isHost && !localHost) || (!isHost && !!localHost)) {
            store.dispatch({
               type: "meeting/updateLocalHost",
               payload: isHost ? true : false
            });
         }
         if ((!!isCoHost && !localCoHost) || (!isCoHost && !!localCoHost)) {
            store.dispatch({
               type: "meeting/updateLocalCoHost",
               payload: isCoHost ? true : false
            });
         }
      }
   }, [localHost, localCoHost, room?.localParticipant, roomMetadata, roomMetadata?.roomHost, roomMetadata?.coHosts]);

   useEffect(() => {
      if (localParticipant && localParticipant.isMicrophoneEnabled !== audioEnabled) {
         store.dispatch({
            type: "meeting/setAudioEnabled",
            payload: localParticipant.isMicrophoneEnabled
         });
      }
      if (localParticipant && localParticipant.isCameraEnabled !== videoEnabled) {
         store.dispatch({
            type: "meeting/setVideoEnabled",
            payload: localParticipant.isCameraEnabled
         });
      }
   }, [localHost, localCoHost, room, room?.localParticipant, room?.localParticipant?.isMicrophoneEnabled, audioEnabled, videoEnabled, room?.localParticipant?.isCameraEnabled]);

   useEffect(() => {
      if (meeting.meetingPassword === undefined && !!roomMetadata) {
         const { password } = roomMetadata;
         password &&
            store.dispatch({
               type: "meeting/setMeetingPassword",
               payload: password
            });
      }
   }, [roomMetadata, meeting?.meetingPassword]);

   const { remoteParticipants, recorders, roomParticipants } = useMemo(() => {
      if (participants && participants?.length > 0) {
         const remoteParticipants = participants.filter((participant) => participant.identity !== localParticipant?.identity && participant.permissions?.recorder !== true);
         const recorders = participants.filter((participant) => !!participant.permissions?.recorder);
         const roomParticipants = participants.filter((participant) => {
            ILog.v("useLivekit", "isAgent?", { participant, kind: participant.permissions?.recorder });
            // return !participant.isAgent;
            return !participant.permissions?.recorder;
         });

         return {
            remoteParticipants: remoteParticipants as RemoteParticipant[],
            recorders: recorders as RemoteParticipant[],
            roomParticipants: roomParticipants as Participant[]
         };
      }
      return {
         remoteParticipants: [] as RemoteParticipant[],
         recorders: [] as RemoteParticipant[],
         roomParticipants: [] as Participant[]
      };
   }, [participants, localParticipant, room]);

   const name = localParticipant?.name || "NONE";
   const permissions = localParticipant?.permissions?.recorder || "NONE";

   useEffect(() => {
      !!recorderToken &&
         ILog.v("useLivekit", {
            name,
            isLocalRecorder: !!recorderToken,
            permissions,
            localCoHost,
            localHost,
            empathyCafe,
            remoteParticipants,
            recorders,
            roomParticipants,
            roomMetadata
         });
   }, [localHost, localCoHost, recorders, recorderToken, name, permissions, empathyCafe]);
   ILog.v("useLivekit", { roomMetadata });
   return {
      isLocalRecorder: !!recorderToken,
      livekitToken,

      room,
      recorders,
      remoteParticipants,
      localParticipant,

      localCoHost,
      localHost,
      empathyCafe,
      LK: ISVC,
      error: undefined,

      roomParticipants
   };
}
