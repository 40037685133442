import { ClipboardDocumentIcon, PencilSquareIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import { MenuItem, Select } from "@mui/material";
import getAuthenticatedSessionClientside from "app/(platform)/(authentication)/_helpers/getAuthenticatedSessionClientside";
import cleanupDirectoryState from "app/(platform)/profile/preferences/_helpers/cleanupDirectoryState";
import _Icon from "app/_components_v2/data-display/_Icon";
import LeanInput from "app/_components_v2/inputs/LeanInput";
import _Button from "app/_components_v2/inputs/_Button";
import _Stack from "app/_components_v2/layout/_Stack";
import { useAppDispatch, useAppSelector, useAppStore } from "app/_contexts/ReduxProvider";
import ILog from "app/_lib/global/Log";
import { PublicEnv } from "app/_lib/global/PublicEnv";
import { directoryConfigurationActions, selectAllDirectoryConfigurations, selectDirectoryConfigurationById, selectDirectoryConfigurationName } from "app/_services/redux/slices/directoryConfiguration";
import { directoryItemActions, selectAllDirectoryItems } from "app/_services/redux/slices/directoryItem";
import { selectAllDirectoryItemLabels } from "app/_services/redux/slices/directoryItemLabel";
import { M } from "app/_types/Schema";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { useCopyToClipboard } from "usehooks-ts";

export default function SelectView(props: { isNav?: boolean }) {
   const { isNav } = props;
   const dispatch = useAppDispatch();
   const session = getAuthenticatedSessionClientside();
   const [_, copy] = useCopyToClipboard();

   const views = useAppSelector((state) => selectAllDirectoryConfigurations(state));
   const selectedView = useAppSelector((state) => selectDirectoryConfigurationName(state));
   const items = useAppSelector((state) => selectAllDirectoryItems(state));
   const labels = useAppSelector((state) => state.directoryItemLabel.entities);
   const selectedViewData = useAppSelector((state) => {
      if (selectedView === null || selectedView === undefined) return null;
      return selectDirectoryConfigurationById(state, selectedView);
   });
   const store = useAppStore();
   ILog.v("SelectView", { selectedView, selectedViewData, views });

   return (
      <React.Fragment>
         {/* disableUnderline is not a valid prop for the outlined variant */}
         <_Stack flexBehavior={"row"} className={isNav ? "h-10 bg-slate-100 justify-center font-semibold hover:underline text-slate-900 cursor-pointer rounded-full p-4 w-min" : ""}>
            <Select value={selectedView && selectedViewData ? selectedView : "Default"} /* variant={isNav ? "standard" : "outlined"} */ variant="standard" disableUnderline>
               {views.map((view, index) => {
                  if (view)
                     return (
                        <MenuItem
                           value={view.name}
                           key={view.name}
                           onClick={() => {
                              // dispatch(accordionItemActions.removeAllAccordionItems());
                              dispatch(directoryConfigurationActions.setSelectedDirectoryConfigurationName(view.name));
                              dispatch(directoryConfigurationActions.setRevalidate(null));
                           }}
                        >
                           {view.name}
                        </MenuItem>
                     );
               })}
            </Select>
            {isNav && (
               <_Button testId="" href={`/profile/preferences`}>
                  <_Icon textColor={"primary"} size={"sm"}>
                     {({ iconClassname }) => <PencilSquareIcon className={iconClassname} />}
                  </_Icon>
               </_Button>
            )}
            {!isNav && (
               <_Stack flexBehavior={"row"}>
                  {selectedView && selectedView !== "Default" && (
                     <LeanInput.Text2
                        testId=""
                        label={"Directory View name"}
                        focus={true}
                        value={selectedView}
                        onChangeEvent={({ e, localValue, setLocalValue, setError }) => {
                           ILog.v("SelectView_setLocalValue", { e, localValue, newValue: e.target.value });
                           setLocalValue(e.target.value);
                           const existingConfig = views.find((c) => c.name === e.target.value) && e.target.value !== selectedView;
                           if (e.target.value === "") {
                              setError("Name cannot be empty");
                           } else if (existingConfig) {
                              setError("A View with this name already exists");
                           } else {
                              setError(null);
                              dispatch(directoryConfigurationActions.updateDirectoryConfiguration({ id: selectedView, changes: { name: e.target.value } }));
                              dispatch(directoryConfigurationActions.setSelectedDirectoryConfigurationName(e.target.value));
                              dispatch(directoryConfigurationActions.setRevalidate(null));
                           }
                        }}
                        onBlurEvent={({ e, localValue, setLocalValue, setError }) => {
                           ILog.v("SelectView_onBlur", { e, localValue });
                           setError(null);
                        }}
                     />
                  )}
                  <_Button
                     title="Create a new view"
                     onButtonClick={(e) => {
                        const newViewName = createUniqueViewName({ name: "Draft View", configs: views });

                        dispatch(directoryConfigurationActions.addDirectoryConfiguration({ name: newViewName, items: [] }));

                        dispatch(directoryConfigurationActions.setSelectedDirectoryConfigurationName(newViewName));
                        dispatch(directoryConfigurationActions.setRevalidate(null));
                     }}
                     testId="new-profile"
                  >
                     <PlusIcon className="w-5 h-5 rounded-sm cursor-pointer " />
                  </_Button>
                  {selectedView && selectedViewData && (
                     <React.Fragment>
                        {selectedView !== "Default" && (
                           <Fragment>
                              <_Button
                                 onButtonClick={(e) => {
                                    if (!selectedViewData) return;
                                    dispatch(directoryConfigurationActions.removeDirectoryConfiguration(selectedViewData.name));
                                    dispatch(
                                       directoryItemActions.removeChildItems({
                                          id: selectedViewData.name,
                                          items: selectedViewData.items
                                       })
                                    );
                                    dispatch(directoryItemActions.removeDirectoryItems(selectedViewData.items));
                                    dispatch(directoryConfigurationActions.setSelectedDirectoryConfigurationName("Default"));
                                 }}
                                 title={`Delete ${selectedViewData.name}`}
                                 testId="delete-profile"
                              >
                                 <TrashIcon className="w-5 h-5 rounded-sm cursor-pointer " />
                              </_Button>
                              <_Button
                                 title="Copy Sharable URL"
                                 onButtonClick={(e) => {
                                    copy(`${PublicEnv.ApplicationBaseURL}/profile/preferences?user=${session?.user.id}&view=${encodeURI(selectedView)}`);
                                    toast.success("Profile URL copied. If you delete this profile, the URL will no longer work.");
                                 }}
                                 testId="copy-profile"
                              >
                                 <ClipboardDocumentIcon className="w-5 h-5 rounded-sm cursor-pointer " />
                              </_Button>
                           </Fragment>
                        )}
                     </React.Fragment>
                  )}
                  {PublicEnv.AllowDefaultProfileEdit === "true" && PublicEnv.NodeEnv === "development" && (
                     <Fragment>
                        <_Button
                           title="Copy Default Profile"
                           onButtonClick={(e) => {
                              const state = store.getState();
                              const allConfigs = selectAllDirectoryConfigurations(state);
                              const allItems = selectAllDirectoryItems(state);
                              const allLabels = selectAllDirectoryItemLabels(state);
                              const defaultConfigs = allConfigs.filter((config) => config.name === "Default" || config.name === "default");
                              const defaultItems = allItems.filter((item) => item.parentConfig === "Default" || item.parentConfig === "default");
                              const defaultLabels = allLabels.filter((label) => {
                                 return label.parentConfig === "Default" || label.parentConfig === "default";
                              });

                              const DirectusUsersDirectoryBuilder: M.DirectusUsers["directus_users_directory_builder"] = {
                                 configs: defaultConfigs,
                                 items: defaultItems,
                                 labels: defaultLabels
                              };
                              ILog.v("SelectView", { DirectusUsersDirectoryBuilder, defaultConfigs, defaultItems, defaultLabels });
                              copy(JSON.stringify(DirectusUsersDirectoryBuilder));
                           }}
                           testId="copy-default-profile"
                        >
                           Copy Default Profile
                        </_Button>
                        <_Button
                           title="Copy Other Profiles"
                           onButtonClick={(e) => {
                              const state = store.getState();
                              const allConfigs = selectAllDirectoryConfigurations(state);
                              const allItems = selectAllDirectoryItems(state);
                              const allLabels = selectAllDirectoryItemLabels(state);
                              const otherConfigs = allConfigs.filter((config) => config.name !== "Default" && config.name !== "default");
                              const otherItems = allItems.filter((item) => item.parentConfig !== "Default" && item.parentConfig !== "default");
                              const otherLabels = allLabels.filter((label) => {
                                 return label.parentConfig !== "Default" && label.parentConfig !== "default";
                              });

                              const DirectusUsersDirectoryBuilder: M.DirectusUsers["directus_users_directory_builder"] = {
                                 configs: otherConfigs,
                                 items: otherItems,
                                 labels: otherLabels
                              };
                              ILog.v("SelectView", { DirectusUsersDirectoryBuilder, otherConfigs, otherItems, otherLabels });
                              copy(JSON.stringify(DirectusUsersDirectoryBuilder));
                           }}
                           testId="copy-other-profile"
                        >
                           Copy Other Profiles
                        </_Button>
                        <_Button
                           title=""
                           onButtonClick={(e) => {
                              cleanupDirectoryState({ store });
                           }}
                           testId="clear-orphans"
                        >
                           Clear Orphans
                        </_Button>
                     </Fragment>
                  )}
               </_Stack>
            )}
         </_Stack>
      </React.Fragment>
   );
}

export function createUniqueViewName(props: { name: string; configs: M.DirectoryConfiguration[] }) {
   const { name, configs } = props;
   const nameExists = configs.find((c) => c.name === `${name}`);
   if (nameExists) {
      let i = 1;
      while (configs.find((c) => c.name === `${name}-${i}`)) i++;
      return `${name}-${i}`;
   } else return name;
}
