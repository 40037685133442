import { Checkbox } from "@mui/material";
import { BreakoutFormContext } from "app/(platform)/meeting-room/_hooks/useBreakoutFormContext";
import { selectAgendaItemsByBreakoutId } from "app/(platform)/meeting-room/_services/agendaItemSlice";
import { R } from "app/(platform)/meeting-room/_types";
import Header from "app/_components_v2/data-display/Header";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";
import { IRootState } from "app/_contexts/ReduxProvider";
import ILog from "app/_lib/global/Log";
import { DateTime } from "luxon";
import { ChangeEvent, useContext, useMemo, useState } from "react";
import { useController } from "react-hook-form";
import { useSelector } from "react-redux";
import { v4 } from "uuid";

export default function BreakoutAgenda(props: { breakoutRoom: R.Breakout.BreakoutRoom; index: number; agendaItem: R.AgendaItem.AgendaItem }) {
   const { breakoutRoom, index, agendaItem } = props;
   const {
      ParentForm: {
         RHF: { control, register: registerAssignedAgendaItemsField, setValue },
         UseFieldArray: { fields, update }
      }
   } = useContext(BreakoutFormContext);
   const breakoutItems = useSelector((state: IRootState) => selectAgendaItemsByBreakoutId(state, breakoutRoom.id));
   const {
      field,
      formState: { isDirty, submitCount }
   } = useController({
      control,
      name: `breakouts.${index}.agendaItems`
   });

   const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
      // e?.preventDefault();
      const existingItems = field.value;
      setChecked(e?.target.checked);
      const newItem = {
         ...agendaItem,
         id: v4(),
         clonedFrom: agendaItem.id,
         breakoutId: breakoutRoom.id,
         itemActive: false,
         itemComplete: false,
         itemPaused: false,
         created: DateTime.now().toMillis()
      } satisfies R.AgendaItem.AgendaItem;
      ILog.v(`BreakoutAgenda.tsx: BreakoutAgenda:`, { existingItems, agendaItem, newItem });
      if (existingItems && existingItems.length && e.target.checked) {
         field.onChange([...existingItems, newItem]);
      } else if (e.target.checked) {
         field.onChange([newItem]);
      } else {
         const newItems = existingItems?.filter((item) => item.clonedFrom !== agendaItem.id);
         field.onChange(newItems);
      }
   };
   const [checked, setChecked] = useState(false);
   const { disabled } = useMemo(() => {
      const valueArray = field.value;
      ILog.v(`BreakoutAgenda.tsx: BreakoutAgenda:`, { valueArray });
      if (!valueArray) return { disabled: false };
      return {
         disabled: Boolean(breakoutItems?.find((item) => item.clonedFrom === agendaItem.id))
      };
   }, [agendaItem, field, field.value, breakoutItems, isDirty, submitCount, index, fields]);

   ILog.v(`BreakoutAgenda.tsx: BreakoutAgenda:`, { checked, disabled });

   return (
      <_DivUNSAFE data-testid={`${agendaItem.title}-${disabled ? "assigned" : "not-assigned"}`}>
         <_DivUNSAFE className="flex flex-row items-center gap-3">
            <_DivUNSAFE className={`${disabled ? `hover:cursor-not-allowed` : `hover:cursor-pointer`}`}>
               <Checkbox
                  id="assigned-agenda-item"
                  data-testid={`checkbox-assign-agenda-item-${agendaItem.title}`}
                  aria-label="Assign Agenda Item to Breakout Room"
                  checked={checked || disabled}
                  disabled={disabled}
                  className={`h-6 w-6 rounded-md border-slate-300 text-slate-900 focus:ring-slate-500 `}
                  onChange={handleCheckbox}
                  inputProps={{ "aria-label": "controlled" }}
               />
            </_DivUNSAFE>

            {agendaItem.title && <Header level={"h3"} label={agendaItem.title} />}
            {/* {disabled && (
               <label aria-describedby="assigned-agenda-item" className="ml-2">
                  Item Assigned
               </label>
            )} */}
         </_DivUNSAFE>
      </_DivUNSAFE>
   );
}
