"use client";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { localParticipantListeningRequests } from "../_services/listeningRequestSlice";

import { toast, ToastContainer } from "react-toastify";
import ButtonListeningRequest from "./button/ButtonListeningRequest";

import ILog from "app/_lib/global/Log";
import { useBreakoutParticipants } from "../_contexts/BreakoutContext";
import { MeetingContext } from "../_contexts/MeetingContext";
import { IRootState } from "app/_contexts/ReduxProvider";

export default function ListeningRequestToastContainer() {
   const { localParticipant } = useContext(MeetingContext);
   const breakoutParticipants = useBreakoutParticipants();

   const listeningRequest = useSelector((state: IRootState) => localParticipantListeningRequests(state, localParticipant?.identity));

   useEffect(() => {
      if (listeningRequest && localParticipant && breakoutParticipants) {
         ILog.v("ListeningRequestToastContainer", "listeningRequest", listeningRequest);
         toast(<ButtonListeningRequest key={listeningRequest.id} listeningRequest={listeningRequest} localParticipant={localParticipant} participants={breakoutParticipants} />, {
            toastId: listeningRequest.id,
            autoClose: false
         });
      }
   }, [listeningRequest, localParticipant, breakoutParticipants]);

   return <ToastContainer />;
}
