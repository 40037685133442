"use client";

import { EnsureParticipant } from "app/(platform)/meeting-room/_contexts/ParticipantContext";
import { selectChatMessagesByBreakoutId } from "app/(platform)/meeting-room/_services/chatMessageSlice";
import _Stack from "app/_components_v2/layout/_Stack";
import ILog from "app/_lib/global/Log";
import { VariantProps, cva } from "class-variance-authority";
import { useSelector } from "react-redux";
import { useCurrentBreakout } from "../../_contexts/BreakoutContext";
import ChatForm from "./ChatForm";
import ChatMessage from "./Message";
import { IRootState } from "app/_contexts/ReduxProvider";

type ChatThreadProps = {} & VariantProps<typeof ChatThreadVariants>;

const ChatThreadVariants = cva("", {
   variants: {
      variant: {
         primary: ""
      }
   },
   compoundVariants: [
      {
         variant: [],
         class: ""
      }
   ],
   defaultVariants: {}
});

function ChatThread({}: ChatThreadProps) {
   const currentBreakout = useCurrentBreakout();
   const messages = useSelector((state: IRootState) => selectChatMessagesByBreakoutId(state, currentBreakout?.id));
   //    const sortedMessages = messages.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
   ILog.v("ChatThread", {});
   return (
      <_Stack className={`m-2 rounded-md`} fluid={false}>
         {messages.map((message) => (
            <EnsureParticipant
               key={`ensure-participant-${message.identity}-${message.id}`}
               identity={message.identity}
               fallback={<ChatMessage key={message.id} chatMessage={message} pContext={null} />}
            >
               {({ participant, isLocal, pContext }) => <ChatMessage key={message.id} chatMessage={message} pContext={pContext} />}
            </EnsureParticipant>
         ))}
         <ChatForm />
      </_Stack>
   );
}

export default ChatThread;
