"use client";
import { R } from "app/(platform)/meeting-room/_types";
import { Participant } from "livekit-client";
import { createContext } from "react";
import useRoundContext from "../_hooks/useRoundContext";

export const RoundContext = createContext<RoundContextType>({
   viewMode: "grid"
});

export type RoundContextType = {
   activeSpeaker?: Participant | null;
   activeListeners?: Participant[];
   currentParticipants?: Participant[];
   currentBreakout?: R.Breakout.BreakoutRoom;
   currentSpeaker?: Participant | null;
   currentListener?: Participant | null;
   currentHost?: Participant | null;
   nextListener?: Participant | null;
   nextSpeaker?: Participant | null;
   thirdSpeaker?: Participant | null;
   listeningCount?: number;
   gridCount?: number;
   paginatedGridCount?: number;
   memoizedOrder?: Participant[];
   itemOffset?: number;
   itemsPerPage?: number;
   pageCount?: number;
   handlePageIncrement?: (event: React.MouseEvent<HTMLButtonElement>) => void;
   handlePageDecrement?: (event: React.MouseEvent<HTMLButtonElement>) => void;
   viewGridParticipants?: Participant[];
   paginatedParticipants?: Participant[];
   activeRound?: R.Round.Round;
   activeTurn?: R.Turn.Turn;
   sortedInactiveTurns?: (R.Turn.Turn | undefined)[];
   allListeningRequests?: R.ListeningRequest.ListeningRequest[];
   viewMode: "spotlight" | "grid" | "listening";
   paginationBar?: boolean;
   viewListeningGrid?: boolean;
   viewRoomParentConfig?: string;
   selectedPageNumber?: number;
   isLastPage?: boolean;
   isFirstPage?: boolean;
};

export function RoundContextProvider({ children }: { children: React.ReactNode }) {
   const roundContext: RoundContextType = useRoundContext();

   return <RoundContext.Provider value={roundContext}>{children}</RoundContext.Provider>;
}
