import { MeetingContext } from "app/(platform)/meeting-room/_contexts/MeetingContext";
import { ParticipantContextType } from "app/(platform)/meeting-room/_contexts/ParticipantContext";
import { R } from "app/(platform)/meeting-room/_types";
import { ComponentEditor } from "app/(platform)/workspaces/[campaign_id]/(editor)/_components/ComponentEditor";
import { IgnoreEnter } from "app/(platform)/workspaces/[campaign_id]/(editor)/_components/plugins/IgnoreEnter";
import Header from "app/_components_v2/data-display/Header";
import DefaultXIcon from "app/_components_v2/icons/DefaultXIcon";
import _Button from "app/_components_v2/inputs/_Button";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";
import _Stack from "app/_components_v2/layout/_Stack";
import ILog from "app/_lib/global/Log";
import { VariantProps, cva } from "class-variance-authority";
import { DateTime } from "luxon";
import { useContext } from "react";
import { v4 } from "uuid";
import * as Y from "yjs";
import { useIsLocalFacilitator } from "../../_contexts/BreakoutContext";

type ChatMessageProps = {
   chatMessage: R.ChatMessage.ChatMessage;
   pContext: ParticipantContextType | null;
} & VariantProps<typeof ChatMessageVariants>;

const ChatMessageVariants = cva("", {
   variants: {
      variant: {
         primary: ""
      }
   },
   compoundVariants: [
      {
         variant: [],
         class: ""
      }
   ],
   defaultVariants: {}
});

function ChatMessage({ chatMessage, pContext }: ChatMessageProps) {
   const { localParticipant, roomParticipants, room, LK, localCoHost, localHost } = useContext(MeetingContext);
   const isLocalFacilitator = useIsLocalFacilitator();
   const canDelete = isLocalFacilitator || localCoHost || localHost || localParticipant?.identity === chatMessage.identity;
   const { breakoutId, id, message, refreshId, name, edited, createdAt } = chatMessage;

   const humanReadableTime = DateTime.fromMillis(createdAt).toLocaleString(DateTime.TIME_SIMPLE);
   ILog.v("ChatMessage", { chatMessage, pContext, humanReadableTime });

   return (
      <_Stack alignment={"start"} gap={"none"} className="bg-white rounded-md p-2">
         <_Stack flexBehavior={"row"} alignment={"between"}>
            <Header level="h5" label={`${/* pContext?.State?.name || */ name}, ${humanReadableTime} ${edited ? "(edited)" : ""}`} />
            {canDelete && (
               <_Button
                  testId={`delete-comment-button`}
                  onButtonClick={() => {
                     LK.sendDataPacket({
                        payload: {
                           type: "chatMessage/removeChatMessage",
                           payload: id
                        }
                     });
                  }}
                  disabled={false}
               >
                  <DefaultXIcon />
               </_Button>
            )}
         </_Stack>
         <_DivUNSAFE className={`${pContext?.State?.isLocalParticipant ? "hover:bg-white focus:bg-white" : ""} `}>
            <ComponentEditor
               addTopPriorityExtensions={[IgnoreEnter]}
               saveOnEnterCancelOnEscape={true}
               editorClassAttributes={`editor editor-content h-fit max-w-[100%] mx-auto focus:outline-hidden ${pContext?.State?.isLocalParticipant ? " focus:border-black focus:border-2 hover:border-2" : ""} p-2`}
               contentOverride={message}
               role={undefined}
               token={undefined}
               documentId={refreshId}
               testId={""}
               editable={!!pContext?.State?.isLocalParticipant}
               shouldConnectHocus={false}
               shouldSetEditor={false}
               shouldSetEditorOnCreate={false}
               saveContentVersion={true}
               callToAction="Save"
               includeCommandItems={false}
               expectAuthentication={false}
               collaboratorName={undefined}
               onFocusProvider={(provider) => {}}
               onSubmit={(yDoc, editorInstance, provider) => {
                  const message = Buffer.from(Y.encodeStateAsUpdate(yDoc)).toString("base64");
                  LK.sendDataPacket({
                     payload: {
                        type: "chatMessage/updateOneChatMessage",
                        payload: {
                           id,
                           changes: {
                              message,
                              refreshId: v4(),
                              edited: true
                           }
                        }
                     }
                  });
                  provider?.destroy();
                  editorInstance.destroy();
               }}
            />
         </_DivUNSAFE>
      </_Stack>
   );
}

export default ChatMessage;
