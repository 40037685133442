import { useScreen } from "usehooks-ts";

// import { useBattery, useMediaQuery as useMediaQuery2, useNetworkState, useOrientation, useWindowSize } from "@uidotdev/usehooks";
import ILog from "app/_lib/global/Log";
import { useMemo } from "react";
export function useIsMobile() {
   //    const { height, width } = useWindowSize();
   //    const orientation = useOrientation();
   //    const networkState = useNetworkState();
   //    const battery = useBattery();
   //    const isMobile = useMediaQuery("(max-height: 768px)");
   //    const isMobile2 = useMediaQuery2("(max-height: 768px)");

   const _screen = useScreen();
   //    ILog.v("useIsMobile", { isMobile, isMobile2, width, height, orientation, networkState, battery, _screen });
   const { isMobile } = useMemo(() => {
      const isMobile = _screen.height <= 916 || _screen.width <= 480;
      return { isMobile };
   }, [_screen.availHeight, _screen.availWidth]);

   ILog.v("useIsMobile", { _screen, isMobile });
   return { isMobile, orientation: _screen.orientation, width: _screen.availWidth, height: _screen.availHeight };
}
