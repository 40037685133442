import { UserIdContext } from "app/(platform)/(authentication)/_contexts/AuthenticatedSessionProvider";
import useAuthenticatedPublicRoles from "app/(platform)/(authentication)/_hooks/useAuthenticatedPublicRoles";
import { apiDirectusNotifications } from "app/(platform)/@search/_api/directusNotifications";
import { useContext } from "react";

interface UseNotificationsProps {}

export default function useNotifications({}: UseNotificationsProps) {
   const { userId } = useContext(UserIdContext);
   const { authenticatedRole } = useAuthenticatedPublicRoles();
   const { data: notifications } = apiDirectusNotifications.useGetManyDirectusNotificationsQuery(
      { query: { filter: { recipient: { _eq: userId } } }, role: authenticatedRole },
      { skip: !userId || !authenticatedRole }
   );
   const [updateNotification] = apiDirectusNotifications.useUpdateDirectusNotificationsByIdMutation();
   return {
      notifications,
      updateNotification
   };
}
