import _Button from "app/_components_v2/inputs/_Button";
import { IRootState } from "app/_contexts/ReduxProvider";
import { useIsMobile } from "app/_hooks/useIsMobile";
import ILog from "app/_lib/global/Log";
import { PublicEnv } from "app/_lib/global/PublicEnv";
import "app/_styles/globals.css";
import React from "react";
import { useSelector } from "react-redux";

// import { ClipboardDocumentCheckIcon as Outline } from "@heroicons/react/24/outline";

interface LeftNavItemProps {
   openTestId: string;
   closeTestId: string;
   openLabel: string;
   menuOpen: boolean;
   children: React.ReactNode;
   Icon: ({ fontSize, style, className }: { fontSize: "inherit"; style: React.CSSProperties | undefined; className: string }) => JSX.Element;
   onOpen: () => void;
   onClose: () => void;
   flexGrow?: boolean;
   otherMenuOpen: boolean;
}

export default function LeftNavItem(props: LeftNavItemProps) {
   const { menuOpen, otherMenuOpen, openLabel, onOpen, onClose, flexGrow } = props;
   const dragNavOpen = useSelector((state: IRootState) => state.app.leftNavOpen || PublicEnv.AccordionOpenOverride === "true");
   const { isMobile } = useIsMobile();
   ILog.v("LeftNavItem", { dragNavOpen, menuOpen, otherMenuOpen });
   return (
      <div
         id="left-nav-item"
         className={`flex flex-col ${dragNavOpen ? "pl-1 w-full" : "w-fit"} ${menuOpen ? "overflow-y-auto" : ""} ${flexGrow ? "grow flex-1" : ""} ${isMobile && !menuOpen && otherMenuOpen ? "hidden" : ""}`}
      >
         <div id="left-nav-item-toggle" className={`sticky top-0 flex max-h-fit ${dragNavOpen ? "" : "place-content-center place-items-center"}`}>
            {dragNavOpen ? (
               <_Button
                  type="button"
                  variant={"transparent"}
                  id={`${menuOpen ? "close-left-nav-item-button" : "open-left-nav-item-button"}`}
                  testId={`${menuOpen ? "close-left-nav-item-button" : "open-left-nav-item-button"}`}
                  className={` ${menuOpen ? "mb-2 border-b-4 border-slate-600" : ""} flex flex-row gap-3 text-lg hover:underline bg-white w-fit`}
                  onButtonClick={() => {
                     menuOpen ? onClose() : onOpen();
                  }}
               >
                  {props.Icon({ fontSize: "inherit", style: { height: "1.75rem", width: "1.75rem" }, className: "" })}
                  {openLabel}
               </_Button>
            ) : (
               <_Button
                  type="button"
                  variant={"transparent"}
                  className="flex flex-row gap-3 bg-white w-full place-self-center place-items-center"
                  testId="open-left-nav-item-button"
                  placement={"center"}
                  onButtonClick={() => {
                     onOpen();
                  }}
               >
                  {props.Icon({ fontSize: "inherit", style: { height: "1.75rem", width: "1.75rem" }, className: " border-slate-900 hover:border-b-2" })}
               </_Button>
            )}
         </div>
         <div
            id={`left-nav-item-content`}
            className={`flex flex-col gap-3 rounded-xs ${menuOpen && dragNavOpen ? "overflow-y-auto max-w-full overflow-x-hidden" : "hidden"} ${flexGrow ? "grow flex-1" : ""}`}
         >
            {props.children}
         </div>
      </div>
   );
}
