import { MeetingContext } from "app/(platform)/meeting-room/_contexts/MeetingContext";
import { R } from "app/(platform)/meeting-room/_types";
import _Button from "app/_components_v2/inputs/_Button";
import { Participant } from "livekit-client";
import { useContext } from "react";
import { v4 } from "uuid";
import { useCurrentBreakout } from "../../_contexts/BreakoutContext";

// TODO: Move the html data properties to a state setter.
export default function ButtonSendListeningRequest(props: { speakerTurn: R.Turn.Turn; listener: Participant; sentListeningRequest?: R.ListeningRequest.ListeningRequest }) {
   const { speakerTurn, listener } = props;
   const { localParticipant, LK } = useContext(MeetingContext);
   const currentBreakout = useCurrentBreakout();

   if (!listener) return null;
   return (
      <_Button
         variant={"primary"}
         key={`send-listening-request-button-${listener.identity}`}
         id={`send-listening-request-button`}
         testId={`send-listening-request-button-${listener.name}`}
         data-listeneridentity={listener.identity}
         data-listenername={listener.name}
         data-turnid={speakerTurn.id}
         data-speakeridentity={localParticipant?.identity}
         data-speakername={localParticipant?.name}
         onButtonClick={(event) => {
            //  setSent(true);
            event.preventDefault();
            if (!listener.name || !localParticipant?.name || !currentBreakout?.id) throw new Error("Error: No listener name, localParticipant name, or breakoutId");

            LK.ListeningRequest({
               listeningRequest: {
                  id: v4(),
                  turnId: speakerTurn.id,
                  speakerIdentity: localParticipant?.identity,
                  speakerName: localParticipant?.name,
                  listenerIdentity: listener.identity,
                  listenerName: listener.name,
                  listenerAccepted: undefined,
                  timeAccepted: undefined,
                  void: false,
                  breakoutId: currentBreakout?.id
               }
            }).sendListeningRequest();
         }}
      >
         {`${listener.name}`}
      </_Button>
   );
}
