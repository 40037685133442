import { yupResolver } from "@hookform/resolvers/yup";
import { BaseSyntheticEvent, useContext } from "react";
import { FieldErrorsImpl, useForm } from "react-hook-form";
import { MeetingContext } from "../../_contexts/MeetingContext";

import _Button from "app/_components_v2/inputs/_Button";
import _Stack from "app/_components_v2/layout/_Stack";
import * as yup from "yup";
import { useCurrentBreakout } from "../../_contexts/BreakoutContext";

const messageSchema = yup.object().shape({
   message: yup.string().required()
});
export default function Message() {
   const { LK } = useContext(MeetingContext);
   const currentBreakout = useCurrentBreakout();
   const { register, handleSubmit, reset } = useForm({
      resolver: yupResolver(messageSchema),
      defaultValues: {
         message: ""
      }
   });
   const onSubmit = (data: any) => {
      const { message } = data;
      currentBreakout && LK.Breakout({ breakout: currentBreakout }).breakoutToast(message, "info");
      reset();
   };
   const onError = (errors: Partial<FieldErrorsImpl<{ [x: string]: any }>>, e: BaseSyntheticEvent<object, any, any> | undefined) => {};
   return (
      <_Stack gap="none">
         <form className="grid grid-cols-1 gap-2 p-1" onSubmit={handleSubmit(onSubmit, onError)} data-form-index={`formBreakoutMessage`} data-testid={`breakout-message-form`}>
            <label>
               <span className="text-slate-700">Message All Breakout Rooms</span>
               <textarea data-testid={`breakout-message-all-rooms-textarea`} className="block w-full mt-1 form-textarea" rows={1} placeholder="Enter message here." {...register("message")} />
            </label>
            <_Button variant={"primary"} testId={`send-breakout-message-button`} type="submit" className="btn btn-primary" onButtonClick={handleSubmit(onSubmit, onError)}>
               Send Message
            </_Button>
         </form>
      </_Stack>
   );
}
