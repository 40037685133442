"use client";

import _Stack from "app/_components_v2/layout/_Stack";
import ErrorProvider from "app/_contexts/ErrorProvider";
import { IRootState } from "app/_contexts/ReduxProvider";
import { useRemSize } from "app/_hooks/useRemSize";
import ILog from "app/_lib/global/Log";
import { useSearchParams } from "next/navigation";
import React, { Fragment, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import LeftNav_V2 from "../_components_v2/layout/LeftNav_V2";
import { PublicEnv } from "../_lib/global/PublicEnv";
import { AuthStatusContext } from "./(authentication)/_contexts/AuthenticatedSessionProvider";
import PersonalNav from "./@search/_components/PersonalNav";
import LeftDragNav from "./_components/LeftDragNav";
import TopDragNav from "./_components/TopDragNav";
import ListeningRequestToastContainer from "./meeting-room/_components/ListeningRequestToastContainer";

export default function LayoutState({ children, search, meeting }: { children: React.ReactNode; search: React.ReactNode; meeting: React.ReactNode }) {
   const livekitToken = useSelector((state: IRootState) => state.meeting.livekitToken);
   const { status } = useContext(AuthStatusContext);
   const inMeeting = !!livekitToken;
   const hasItems = useMemo(() => {
      return status === "authenticated" || !!inMeeting || PublicEnv.AccordionOpenOverride === "true";
   }, [status, inMeeting, PublicEnv.AccordionOpenOverride]);
   const params = useSearchParams();
   const recorderToken = params.get("recordertoken");
   ILog.v("LayoutState", { hasItems, status, inMeeting, livekitToken, recorderToken, meeting, override: PublicEnv.AccordionOpenOverride });
   const { fontSize: minWidth } = useRemSize({ multiplier: 28, level: undefined });
   const { fontSize: minHeight } = useRemSize({ multiplier: 20, level: undefined });
   if (!!recorderToken)
      return (
         <_Stack flexBehavior={"none"} className=" h-screen ">
            {meeting}
         </_Stack>
      );
   return (
      <Fragment>
         <ErrorProvider>{<ListeningRequestToastContainer />}</ErrorProvider>
         {hasItems ? (
            <LeftDragNav
               minWidth={minWidth}
               dragKey="left-nav-main-drag"
               leftSlot={<LeftNav_V2>{search}</LeftNav_V2>}
               rightSlot={<TopDragNav minHeight={minHeight} dragKey="top-nav-main-drag" topSlot={meeting} bottomSlot={children} />}
            />
         ) : (
            <div className={` ${PublicEnv.AccordionOpenOverride === "true" ? "h-[10000px]" : "h-screen"} w-full `}>
               <PersonalNav />
               {children}
            </div>
         )}
      </Fragment>
   );
}
