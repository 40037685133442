import { R } from "app/(platform)/meeting-room/_types";
import { useSelector } from "react-redux";
import { selectActiveBreakouts } from "../../_services/breakoutSlice";

import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";
import ILog from "app/_lib/global/Log";
import BreakoutBase from ".";
import { IRootState } from "app/_contexts/ReduxProvider";

export default function List() {
   const activeBreakouts = useSelector((state: IRootState) => selectActiveBreakouts(state));
   ILog.v("BreakoutList", { activeBreakouts });
   return (
      <_DivUNSAFE className="flex flex-col justify-center gap-5 text-slate-900">
         {activeBreakouts.map((b: R.Breakout.BreakoutRoom, index) => {
            return (
               <_DivUNSAFE key={index} id="active-breakouts-container" data-testid="active-breakouts-container">
                  <BreakoutBase.Card breakout={b} index={index} />
               </_DivUNSAFE>
            );
         })}
      </_DivUNSAFE>
   );
}
