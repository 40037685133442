"use client";

import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useCopyToClipboard } from "usehooks-ts";

import { AuthStatusContext, TokenContext } from "app/(platform)/(authentication)/_contexts/AuthenticatedSessionProvider";
import { apiMeeting } from "app/(platform)/workspaces/[campaign_id]/meetings/_api/meeting";
import _Paragraph from "app/_components_v2/data-display/Paragraph";
import LoadingSquares from "app/_components_v2/feedback/loading/LoadingSquares";
import _Button from "app/_components_v2/inputs/_Button";
import _Stack from "app/_components_v2/layout/_Stack";
import ILog from "app/_lib/global/Log";
import { PublicEnv } from "app/_lib/global/PublicEnv";
import { useQueryStates } from "nuqs";
import { toast } from "react-toastify";
import { MeetingContext } from "../_contexts/MeetingContext";
import { meetingRoomParams } from "../meetingRoomParamsCache";
import { IRootState } from "app/_contexts/ReduxProvider";

function Password() {
   return <></>;
}

function Reset() {
   const { room, LK } = useContext(MeetingContext);
   const meetingId = room?.name;
   const { token } = useContext(TokenContext);
   const { status } = useContext(AuthStatusContext);
   const [{ password }, setValues] = useQueryStates(meetingRoomParams);
   const [updatePassword, { isLoading: updatePasswordIsLoading, isSuccess: meetingPasswordSuccess, data: meetingPasswordData }] = apiMeeting.useGenerateMeetingPasswordMutation();

   useEffect(() => {
      if (meetingPasswordSuccess && meetingPasswordData?.meeting_password) {
         LK.sendDataPacket({
            payload: {
               type: "meeting/setMeetingPassword",

               payload: meetingPasswordData.meeting_password
            }
         });
         setValues({ password: meetingPasswordData.meeting_password });
         toast.success(
            <>
               Password reset. <SimpleCopy />
            </>,
            { autoClose: 5000, toastId: meetingPasswordData.meeting_password }
         );
      }
   }, [meetingPasswordSuccess, meetingPasswordData]);

   return (
      <_Stack>
         <_Button
            variant="primary"
            type="button"
            id="button-reset-meeting-password"
            testId="reset-meeting-password-button"
            // className="p-2 mb-2 rounded-md bg-slate-300 text-slate-900 hover:shadow-lg"
            onButtonClick={() => {
               updatePassword({
                  meetingId: meetingId as string,
                  role: undefined,
                  token: token
               });
            }}
         >
            {updatePasswordIsLoading ? <LoadingSquares /> : "Reset Meeting Password"}
         </_Button>
         {status !== "authenticated" && <_Paragraph className="scale-0 hover:scale-100" label={`You must be signed in to reset meeting password.`} />}
      </_Stack>
   );
}

function SimpleCopy() {
   const meeting = useSelector((state: IRootState) => state.meeting);
   const campaignId = meeting.meetingWorkspace;
   const [value, copy] = useCopyToClipboard();
   if (meeting?.meetingPassword) {
      return (
         <_Stack flexBehavior={"row"} gap={"sm"}>
            <_Button
               variant="primary"
               testId="copy-meeting-link-button"
               className="peer place-self-center whitespace-nowrap"
               onButtonClick={() => copy(`${PublicEnv.ApplicationBaseURL}/meeting-room?password=${meeting.meetingPassword}`)}
            >
               Copy Meeting Link
            </_Button>

            <_Paragraph className="p-2 duration-200 scale-0 rounded-md place-self-center peer-focus:scale-100" label={`Copied!`} />
         </_Stack>
      );
   }
   return null;
}

function Detailed() {
   const meeting = useSelector((state: IRootState) => state.meeting);
   const campaignId = meeting.meetingWorkspace;
   const [value, copy] = useCopyToClipboard();
   ILog.v("Meeting Password", meeting);

   return <Password.Reset />;
}

Password.Reset = Reset;
Password.SimpleCopy = SimpleCopy;
Password.Detailed = Detailed;

export default Password;
