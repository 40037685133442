import { useLocalParticipant, useParticipantInfo, useRemoteParticipant } from "@livekit/components-react";
import { Participant, ParticipantEvent } from "livekit-client";
import { createContext, useEffect, useMemo, useState } from "react";

import Metadata from "app/(platform)/meeting-room/_services/livekit/metadata";
import ILog from "app/_lib/global/Log";
import React, { useContext } from "react";
import { useTimeout } from "usehooks-ts";
import { apiRoom } from "../_api/apiRoom";
import { GrantRoleMutationTrigger } from "../_components/_forms/FormParticipantControls/FormSelectHost";
import useRoomMetadata from "../_hooks/useRoomMetadata";
import { useCurrentBreakout } from "./BreakoutContext";
import { MeetingContext } from "./MeetingContext";

export interface ParticipantContextType {
   State: {
      isHost: boolean;
      isCoHost: boolean;
      isFacilitator: boolean;
      isSpeaking: boolean | undefined;
      isCameraEnabled: boolean | undefined;
      isMicrophoneEnabled: boolean | undefined;
      metadata: any;
      identity: string | undefined;
      name: string | undefined;
      isLocalParticipant: boolean;
      participant: Participant;
      targetParticipantIsHost: boolean;
      targetParticipantIsCoHost: boolean;
      isLoading: boolean;
   };
   Set: {
      setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
      setLoading: React.Dispatch<React.SetStateAction<boolean>>;
      grantRole: GrantRoleMutationTrigger;
   };
}

export const IParticipantContext = createContext<ParticipantContextType>({
   State: {
      isHost: false,
      isCoHost: false,
      isFacilitator: false,
      isSpeaking: false,
      isCameraEnabled: false,
      isMicrophoneEnabled: false,
      metadata: {},
      identity: "",
      name: "",
      isLocalParticipant: false,
      participant: {} as Participant,
      targetParticipantIsHost: false,
      targetParticipantIsCoHost: false,
      isLoading: false
   },
   Set: {
      setRefresh: () => {},
      setLoading: () => {},
      //@ts-ignore
      grantRole: ({ payload, token }) => {}
   }
});
export type EnsureParticipantProps = {
   identity: string;
   children: (props: { participant: Participant; isLocal: boolean; pContext: ParticipantContextType }) => React.ReactNode;
   fallback?: React.ReactNode;
};
export function EnsureParticipant({ identity, children, fallback }: EnsureParticipantProps) {
   const { localParticipant, roomParticipants, room } = useContext(MeetingContext);
   const isLocal = localParticipant?.identity === identity;
   const participant = roomParticipants?.find((p) => p.identity === identity);
   if (!participant && fallback) return fallback;
   if (!participant) return null;
   return (
      <WithParticipantContext participant={participant} isLocal={isLocal}>
         {({ pContext }) => children({ participant, isLocal, pContext })}
      </WithParticipantContext>
   );
}
export function WithParticipantContext({ children, participant, isLocal }: { children: (props: { pContext: ParticipantContextType }) => React.ReactNode; participant: Participant; isLocal: boolean }) {
   const pContext = useParticipantContext(isLocal, participant);

   return <IParticipantContext.Provider value={pContext}>{children({ pContext })}</IParticipantContext.Provider>;
}

export function useParticipantContext(isLocalParticipant: boolean, participant: Participant) {
   const { room, localHost, localParticipant } = useContext(MeetingContext);
   const roomMetadata = useRoomMetadata();
   const currentBreakout = useCurrentBreakout();
   const localP = useLocalParticipant({ room });
   const info = useParticipantInfo({ participant });
   const remoteP = useRemoteParticipant(participant.identity, {
      updateOnlyOn: [
         ParticipantEvent.TrackMuted,
         ParticipantEvent.TrackUnmuted,
         ParticipantEvent.TrackPublished,
         ParticipantEvent.TrackUnpublished,
         ParticipantEvent.ParticipantMetadataChanged,
         ParticipantEvent.ParticipantPermissionsChanged,
         ParticipantEvent.ParticipantMetadataChanged,
         ParticipantEvent.ParticipantNameChanged,
         ParticipantEvent.AttributesChanged,
         ParticipantEvent.IsSpeakingChanged
      ]
   });
   const [grantRole, { data: grantRoleData, error: grantRoleError, isLoading: grantRoleIsFetching, isSuccess: grantRoleIsSuccess, isError: grantRoleIsError }] = apiRoom.useGrantRoleMutation();

   ILog.v("Role", { grantRoleData, grantRoleError, grantRoleIsFetching, grantRoleIsSuccess, grantRoleIsError });
   useEffect(() => {
      if (grantRoleIsSuccess || grantRoleError) {
         setRefresh(true);
         setLoading(false);
      }
   }, [grantRoleIsSuccess, grantRoleError, grantRoleData, grantRoleIsFetching, grantRoleIsError]);
   const [refresh, setRefresh] = useState(false);
   const [loading, setLoading] = useState(false);
   const { isHost: targetParticipantIsHost, isCoHost: targetParticipantIsCoHost } = useMemo(() => {
      if (!roomMetadata) return { isHost: false, isCoHost: false };
      return Metadata.parseRoles(roomMetadata, participant.identity);
   }, [roomMetadata, participant]);

   const participantProps = isLocalParticipant ? localP : remoteP;
   const { identity, name, isCameraEnabled, isMicrophoneEnabled, metadata, isSpeaking } = useMemo(() => {
      if (isLocalParticipant) {
         return {
            // identity: localP?.localParticipant?.identity,
            // name: localP?.localParticipant?.name,
            // isCameraEnabled: localP?.isCameraEnabled,
            // isMicrophoneEnabled: localP?.isMicrophoneEnabled,
            // metadata: localP?.localParticipant?.metadata,
            // isSpeaking: localP?.localParticipant?.isSpeaking
            identity: localParticipant?.identity,
            name: localParticipant?.name,
            isCameraEnabled: localParticipant?.isCameraEnabled,
            isMicrophoneEnabled: localParticipant?.isMicrophoneEnabled,
            metadata: localParticipant?.metadata,
            isSpeaking: localParticipant?.isSpeaking
         };
      } else {
         return {
            identity: remoteP?.identity,
            name: remoteP?.name,
            isSpeaking: remoteP?.isSpeaking,
            isCameraEnabled: remoteP?.isCameraEnabled,
            isMicrophoneEnabled: remoteP?.isMicrophoneEnabled,
            metadata: remoteP?.metadata
         };
      }
   }, [
      isLocalParticipant,
      localP,
      remoteP,
      localP?.localParticipant?.metadata,
      remoteP?.identity,
      remoteP?.name,
      remoteP?.isSpeaking,
      remoteP?.isCameraEnabled,
      remoteP?.isMicrophoneEnabled,
      remoteP?.metadata,
      localP?.isCameraEnabled,
      localP?.isMicrophoneEnabled,
      localP?.localParticipant?.identity,
      localP?.localParticipant?.name,
      localP?.isMicrophoneEnabled,
      localP?.isCameraEnabled,
      info?.identity,
      info?.name,
      info?.metadata,
      localParticipant?.identity,
      localParticipant?.name,
      localParticipant?.isMicrophoneEnabled,
      localParticipant?.isCameraEnabled,
      localParticipant?.metadata,
      localParticipant?.isSpeaking
   ]);

   const { participantHostRole, participantCoHostRole, participantFacilitatorRole } = useMemo(() => {
      if (!room?.metadata) {
         throw "Room metadata is not available"; // Handle this error
      }
      const metadata = Metadata.parseRoom({ md: room.metadata });
      const { isHost: isHost, isCoHost: isCoHost } = Metadata.parseRoles(metadata, participant.identity);

      return {
         participantHostRole: isHost,
         participantCoHostRole: isCoHost,
         participantFacilitatorRole: currentBreakout?.facilitatorIdentity === identity
      };
   }, [participantProps, metadata, currentBreakout, identity, localP, remoteP]);
   const handleRefresh = () => {
      setRefresh(true);
   };

   useTimeout(
      () => {
         if (refresh) {
            ILog.v("ParticipantContext_useTimeout", { loading, refresh, info, localParticipant, localP });
            setRefresh(false);
         }
      },
      refresh ? 10000 : null
   );

   ILog.v("ParticipantContext", { loading, refresh, info, localP: localP?.localParticipant?.name, localParticipant: localParticipant?.name });

   return {
      State: {
         isHost: participantHostRole,
         isCoHost: participantCoHostRole,
         isFacilitator: participantFacilitatorRole,
         isSpeaking,
         isCameraEnabled,
         isMicrophoneEnabled,
         metadata,
         identity,
         name,
         isLocalParticipant,
         participant,
         targetParticipantIsHost,
         targetParticipantIsCoHost,
         isLoading: loading
      },
      Set: {
         setRefresh,
         setLoading,
         grantRole: grantRole as GrantRoleMutationTrigger
      }
   };
}
