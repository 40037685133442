"use client";

import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import ButtonAuthentication from "app/(platform)/(authentication)/_components/ButtonAuthentication";
import { AuthStatusContext, LocalUserNameContext, UserIdContext } from "app/(platform)/(authentication)/_contexts/AuthenticatedSessionProvider";
import { getInitials } from "app/(platform)/profile/_components/UserCard";
import { Avatar } from "app/_components_v2/data-display/Avatar";
import _Icon from "app/_components_v2/data-display/_Icon";
import _Button from "app/_components_v2/inputs/_Button";
import { FloatingNav } from "app/_components_v2/layout/FloatingNav";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";
import _Stack from "app/_components_v2/layout/_Stack";
import { IRootState, useAppStore } from "app/_contexts/ReduxProvider";
import { useIsMobile } from "app/_hooks/useIsMobile";
import ILog from "app/_lib/global/Log";
import { usePathname } from "next/navigation";
import React, { Fragment, useContext } from "react";
import { useSelector } from "react-redux";
import { Nav } from "../../../_components_v2/todo/Nav";
import Notifications from "../_features/notifications/Notifications";
import SelectProfile from "./SelectView";

interface PersonalNavProps {
   noAbsolutePositioning?: boolean;
}
export default function PersonalNav({ noAbsolutePositioning }: PersonalNavProps) {
   const { status } = useContext(AuthStatusContext);
   const { userId } = useContext(UserIdContext);
   const { first_name, last_name } = useContext(LocalUserNameContext);
   const leftNavOpen = useSelector((state: IRootState) => state.app.leftNavOpen);
   const store = useAppStore();
   const pathname = usePathname();
   const fallbackName = `${first_name} ${last_name || ""}`;
   const initials = fallbackName ? getInitials(fallbackName) : "";
   const { isMobile } = useIsMobile();

   ILog.v("PersonalNav", { status, userId, first_name, last_name, leftNavOpen, pathname, initials, isMobile, noAbsolutePositioning });
   if (status !== "authenticated" && noAbsolutePositioning !== true) {
      return (
         <_DivUNSAFE className="absolute top-0 left-0 min-h-12 pr-1 pl-1 flex place-content-center z-50">{pathname !== "/sign-in" && pathname !== "/sign-up" && <ButtonAuthentication />}</_DivUNSAFE>
      );
   } else if (status !== "authenticated" && noAbsolutePositioning === true) {
      return <_DivUNSAFE className="top-0 left-0 min-h-12 pr-1 pl-1 flex place-content-start ">{<ButtonAuthentication cta="Login" />}</_DivUNSAFE>;
   }
   if (status === "authenticated" && userId) {
      return (
         <_Stack flexBehavior={"row"} className="m-2 " alignment={"between"}>
            <_Stack flexBehavior={"row"} gap={"md"}>
               <FloatingNav
                  referenceChild={
                     <_Button
                        testId="open-left-navigation-menu-button"
                        aria-label="Open Left Navigation Menu"
                        onButtonClick={() => {
                           if (!leftNavOpen) {
                              store.dispatch({ type: "app/setAllMenus", payload: { leftNavOpen: !leftNavOpen } });
                           }
                        }}
                     >
                        <_Icon variant={"primary"}>{({ iconClassname }) => <React.Fragment>{first_name ? <Avatar userId={userId} initials={initials} /> : null}</React.Fragment>}</_Icon>
                     </_Button>
                  }
                  floatingChild={({ setHovered }) => (
                     <React.Fragment>
                        <ButtonAuthentication />
                        <Nav.Item textSize={"lg"} label={"Dashboard"} href={"/dashboard"} />
                        <Nav.Item textSize={"lg"} testId={`goto-account-button`} label={"Account"} href={"/account"} />

                        <Nav.Item textSize={"lg"} label={"Profile"} href={`/profile/${userId}`} />
                     </React.Fragment>
                  )}
               />
               {leftNavOpen && (
                  <Fragment>
                     <Notifications />
                     <SelectProfile isNav />
                     <_Button href={"/learn"} testId="open-knowledgebase-button" aria-label="Open knowledgebase">
                        <_Icon variant={"primary"}>{({ iconClassname }) => <QuestionMarkIcon className={iconClassname} />}</_Icon>
                     </_Button>
                  </Fragment>
               )}
            </_Stack>
         </_Stack>
      );
   }
   return null;
}
