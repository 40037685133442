import { getMySession } from "app/(platform)/(authentication)/_helpers/getMySession";
import ILog from "app/_lib/global/Log";
import { TAGS } from "app/_services/redux/api/TAGS";
import { handleRTKError, handleRTKTags } from "app/_services/redux/api/_helpers/rtkHelpers";
import { apiService } from "app/_services/redux/api/apiService";
import { REST } from "app/_services/rest/REST";
import { PA } from "app/_types/PATypes";
import { createToken } from "../_actions/createToken";
import deleteRoom from "../_actions/deleteRoom";
import grantRole from "../_actions/grantRole";
import kickParticipant from "../_actions/kickParticipant";
import recordMeeting from "../_actions/recordMeeting";

export const apiRoom = apiService.injectEndpoints({
   endpoints: (builder) => ({
      getLivekitToken: builder.query<
         PA.Flows.Livekit.LivekitTokenCreate["Res"],
         {
            payload: PA.Flows.Livekit.LivekitTokenCreate["Req"];
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });

               const res = await createToken({ meetingPassword: arg.payload.meeting_password });
               return { data: res.data as PA.Flows.Livekit.LivekitTokenCreate["Res"] };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "getLivekitToken" });
            }
         },

         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query
            })
      }),

      grantRole: builder.mutation<string, { token: string | null; payload: PA.Flows.Livekit.GrantRole["Req"] }>({
         queryFn: async (arg, api) => {
            try {
               let token = arg.token;

               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const res = await grantRole({ livekitToken: token, targetIdentity: arg.payload.target_identity, targetRole: arg.payload.target_role });
               ILog.v("useGrantRole", { data: res.data });
               if (!res.data) throw new Error(TAGS.UNKNOWN_ERROR);
               return { data: res.data };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "grantRole" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query
            })
      }),
      kickParticipant: builder.mutation<PA.Flows.Livekit.BanParticipant["Res"], { token: string | undefined; payload: PA.Flows.Livekit.BanParticipant["Req"] }>({
         queryFn: async (arg, api) => {
            try {
               let token = arg.token;

               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const res = await kickParticipant({ livekitToken: token, targetIdentity: arg.payload.target_identity });

               return { data: res as unknown as PA.Flows.Livekit.BanParticipant["Res"] };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "kickParticipant" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query
            })
      }),
      toggleRecord: builder.mutation<PA.Flows.Livekit.RecordMeeting["Res"], { token: string | undefined; payload: PA.Flows.Livekit.RecordMeeting["Req"] }>({
         queryFn: async (arg, api) => {
            try {
               let token = arg.token;

               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const res = await recordMeeting({ livekitToken: token, breakoutID: arg.payload.breakout_id, action: arg.payload.action });

               return { data: res as unknown as PA.Flows.Livekit.RecordMeeting["Res"] };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "toggleRecord" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query
            })
      }),
      deleteRoom: builder.mutation<any["Res"], { token: string | undefined; payload: {} }>({
         queryFn: async (arg, api) => {
            try {
               let token = arg.token;

               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const res = await deleteRoom({ livekitToken: token });

               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "deleteRoom" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query
            })
      }),

      generateMeetingPassword: builder.mutation<PA.Flows.Livekit.MeetingPassword["Res"], { role: PA.RoleQueries | undefined; token?: string | undefined; meetingId: string | undefined }>({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const res = (await REST.MeetingService.generateMeetingPassword({ tokenOverride: token!, meetingId: arg.meetingId! })) as PA.Flows.Livekit.MeetingPassword["Res"];
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "generateMeetingPassword" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               resultTags: result && [
                  { type: TAGS.MEETING, id: "LIST" },
                  { type: TAGS.MEETING, id: query.meetingId || "" }
               ]
            })
      })
   }),
   overrideExisting: true
});
