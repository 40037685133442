import { VariantProps, cva } from "class-variance-authority";
import { Url } from "next/dist/shared/lib/router/router";
import Link, { LinkProps } from "next/link";
import * as React from "react";
import { cn } from "../../_styles/tailwindUtils";

const navVariants = cva("rounded-lg", {
   variants: {
      group: {
         relative: "group relative",
         none: ""
      }
   }
});

const navMenuVariants = cva("rounded-lg duration-100 ease-linear z-30", {
   variants: {
      alignment: {
         vertical: `flex flex-col`,
         horizontal: `flex flex-row gap-3 pl-4`
      },
      // Avoid using visibility variant to do tradition conditional rendering. {variable && <Component />} is better.
      visibility: {
         visible: "",
         invisible: "scale-0 group-focus-within:scale-100"
      },
      // Warning: Origin depends on the size of the selector. If the selector is small, then the origin can over-compensate. If the selector is large, then the origin may not be enough.
      // Absolute property can me maintained by the popoverOrigin variant, because we only need it when defining popoverOrigin.
      popoverOrigin: {
         topRight: "absolute -right-0 -top-0 ",
         topLeft: "absolute -left-0 -top-0",

         // These two aren't working yet
         bottomRight: "absolute -right-0 -bottom-0",
         bottomLeft: "absolute -left-0 -bottom-0"
      },
      bg: {
         md_shadow_rounded: "rounded-md bg-slate-50 shadow-md",
         lg_shadow_rounded: "rounded-lg bg-slate-100 shadow-md"
      },
      padding: {
         md: "p-2",
         lg: "p-5"
      }
   }
});

const selectorVariants = cva("", {
   variants: {
      size: {
         md: "mb-1 h-8 w-8"
      },
      alignment: {
         center: "flex justify-center items-center place-items-center place-content-center place-self-center"
      }
   }
});

const itemVariants = cva("whitespace-nowrap hover:underline cursor-pointer", {
   variants: {
      textSize: {
         xs: "text-xs",
         sm: "text-sm",
         md: "text-md",
         lg: "text-lg"
      },
      format: {
         bold: "font-bold"
      }
   },
   defaultVariants: {
      textSize: "md"
   }
});

function Nav(props: { testId?: string; className?: string; selector?: React.ReactNode; menu?: React.ReactNode; group?: VariantProps<typeof navVariants>["group"] }) {
   const { menu, selector, className, group, testId } = props;

   return (
      <nav data-testid={`${testId}`} className={cn(navVariants({ group }), className)}>
         {selector}
         {menu}
      </nav>
   );
}

function NavMenu(props: {
   className?: string;
   children?: React.ReactNode;
   alignment: VariantProps<typeof navMenuVariants>["alignment"];
   visibility: VariantProps<typeof navMenuVariants>["visibility"];
   popoverOrigin?: VariantProps<typeof navMenuVariants>["popoverOrigin"];
   bg?: VariantProps<typeof navMenuVariants>["bg"];
   padding?: VariantProps<typeof navMenuVariants>["padding"];
   subMenuClassName?: string;
}) {
   const { children, alignment, visibility, bg, subMenuClassName, popoverOrigin, padding } = props;

   return <div className={cn(navMenuVariants({ alignment, visibility, popoverOrigin, bg, padding }), subMenuClassName)}>{children}</div>;
}

interface SelectorProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
   size?: VariantProps<typeof selectorVariants>["size"];
   alignment?: VariantProps<typeof selectorVariants>["alignment"];
   icon: React.ReactNode;
   children?: React.ReactNode;
}

const Selector = React.forwardRef<HTMLButtonElement, SelectorProps>(({ size, alignment, icon, children, ...props }, ref) => {
   return (
      <button {...props} className={cn(selectorVariants({ size, alignment }))}>
         {icon}
         {children}
      </button>
   );
});

Selector.displayName = "Selector";

export interface ItemProps extends LinkProps {
   label: string;
   textSize?: VariantProps<typeof itemVariants>["textSize"];
   format?: VariantProps<typeof itemVariants>["format"];
   children?: React.ReactNode;
   href: Url;
   className?: string;
   testId?: string;
}

const Item = React.forwardRef<HTMLLinkElement, ItemProps>(({ label, children, textSize, format, href, className, testId, ...props }, ref) => {
   return (
      <Link {...props} data-testid={`${testId}`} href={href} className={className || cn(itemVariants({ textSize, format }))}>
         {label}
         {children}
      </Link>
   );
});

Item.displayName = "Item";

Nav.Selector = Selector;
Nav.Item = Item;
Nav.Menu = NavMenu;

export { Nav };
