import ILog from "app/_lib/global/Log";
import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useActiveHostIdentity, useBreakoutParticipants, useCurrentBreakout, useLatestSpeakerIdentity, usePreviousSpeakerIdentity, useScreenShareParticipant } from "../_contexts/BreakoutContext";
import { MeetingContext } from "../_contexts/MeetingContext";
import { selectAllListeningRequest } from "../_services/listeningRequestSlice";
import { selectActiveRound } from "../_services/roundSlice";
import { selectActiveTurn, selectIncompleteTurnBySpeakerIdentity, selectIncompleteTurnsOfActiveRoundByTimeSort } from "../_services/turnSlice";
import { IRootState } from "app/_contexts/ReduxProvider";

export default function useRoundContext() {
   const { localParticipant, isLocalRecorder } = useContext(MeetingContext);
   const [itemOffset, setItemOffset] = useState(0);
   const [selectedPageNumber, setSelectedPageNumber] = useState(0);
   const [isLastPage, setIsLastPage] = useState(false);
   const [isFirstPage, setIsFirstPage] = useState(true);

   const currentBreakout = useCurrentBreakout();
   const memoizedBreakoutParticipants = useBreakoutParticipants();
   const latestSpeakerIdentity = useLatestSpeakerIdentity();
   const previousSpeakerIdentity = usePreviousSpeakerIdentity();
   const activeHostIdentity = useActiveHostIdentity();
   const screenShareParticipant = useScreenShareParticipant();
   const activeTurn = useSelector((state: IRootState) => selectActiveTurn(state, currentBreakout?.id));
   const nextTurn = useSelector((state: IRootState) =>
      selectIncompleteTurnBySpeakerIdentity(state, {
         speakerIdentity: activeTurn?.listenerIdentity,
         breakoutId: currentBreakout?.id
      })
   );

   const activeRound = useSelector((state: IRootState) => selectActiveRound(state, currentBreakout?.id));
   const sortedInactiveTurns = useSelector((state: IRootState) => selectIncompleteTurnsOfActiveRoundByTimeSort(state, currentBreakout?.id));
   const meeting = useSelector((state: IRootState) => state.meeting);
   const allListeningRequests = useSelector((state: IRootState) => selectAllListeningRequest(state));

   const itemsPerPage = useSelector((state: IRootState) => state.meeting.itemsPerPage);
   // const [{}, setBreakoutParticipants] = useState<{
   //    breakoutParticipants: Participant[] | undefined;
   // }>({
   //    breakoutParticipants: []
   // });
   //    useEffect(() => {
   //       setBreakoutParticipants({
   //          breakoutParticipants: memoizedBreakoutParticipants
   //       });
   //    }, [memoizedBreakoutParticipants]);

   //    useEffect(() => {}, [breakoutParticipants, activeTurn, activeRound, meeting.viewMode]);
   const { pageCount } = useMemo(() => {
      const pageCount = memoizedBreakoutParticipants?.length ? Math.ceil(memoizedBreakoutParticipants.length / itemsPerPage) : 1;
      return {
         pageCount: pageCount
      };
   }, [memoizedBreakoutParticipants, itemsPerPage]);

   const { activeSpeaker, quietParticipants } = useMemo(() => {
      /*  if (activeSpeakerIdentity) { */
      const latest = latestSpeakerIdentity === localParticipant?.identity ? previousSpeakerIdentity : latestSpeakerIdentity;

      const activeSpeaker = memoizedBreakoutParticipants?.find((p) => p.identity === latest);
      const quietParticipants = memoizedBreakoutParticipants?.filter((p) => p.identity !== activeSpeaker?.identity);

      return {
         activeSpeaker,
         quietParticipants
      };
      /* } else {
         return { activeSpeaker: null, quietParticipants: [] };
      } */
   }, [memoizedBreakoutParticipants, memoizedBreakoutParticipants?.length, localParticipant, latestSpeakerIdentity, previousSpeakerIdentity]);
   ILog.v("useRoundContext", { activeSpeaker, memoizedBreakoutParticipants, quietParticipants, latestSpeakerIdentity, previousSpeakerIdentity });
   const { currentSpeaker } = useMemo(() => {
      if (activeTurn) {
         const speaker = memoizedBreakoutParticipants?.find((participant) => participant.identity === activeTurn.speakerIdentity);

         return { currentSpeaker: speaker };
      } else {
         return { currentSpeaker: undefined };
      }
   }, [activeTurn, memoizedBreakoutParticipants]);

   const { currentListener, nextSpeaker } = useMemo(() => {
      if (activeTurn && activeTurn.listenerIdentity) {
         const listener = memoizedBreakoutParticipants?.find((participant) => participant.identity === activeTurn.listenerIdentity);
         return { currentListener: listener, nextSpeaker: null };
      } else if (activeTurn && activeRound?.empathyMode === "active" && sortedInactiveTurns && sortedInactiveTurns.length >= 1) {
         const nextSpeaker = memoizedBreakoutParticipants?.find((p) => p.identity === sortedInactiveTurns[0]?.speakerIdentity);
         return { currentListener: null, nextSpeaker: nextSpeaker };
      } else {
         return {
            currentListener: null,
            nextSpeaker: null
         };
      }
   }, [activeTurn, currentSpeaker, activeRound, sortedInactiveTurns, memoizedBreakoutParticipants]);

   const { nextListener, thirdSpeaker } = useMemo(() => {
      if (activeTurn && activeTurn?.listenerIdentity && nextTurn && nextTurn?.listenerIdentity) {
         const nextListener = memoizedBreakoutParticipants?.find((participant) => participant.identity === nextTurn.listenerIdentity);
         // Don't re-render the next listener in Position 3 if they are the current speaker or current listener
         if (nextListener && nextListener.identity !== activeTurn.listenerIdentity && nextListener.identity !== activeTurn.speakerIdentity) {
            return { nextListener: nextListener, thirdSpeaker: null };
         } else {
            return { nextListener: null, thirdSpeaker: null };
         }
      } else if (activeTurn && activeRound?.empathyMode === "active" && sortedInactiveTurns && sortedInactiveTurns?.length >= 2) {
         const thirdSpeaker = memoizedBreakoutParticipants?.find((p) => p.identity === sortedInactiveTurns[1]?.speakerIdentity);
         return { nextListener: null, thirdSpeaker: thirdSpeaker };
      } else {
         return {
            nextListener: null,
            thirdSpeaker: null
         };
      }
   }, [memoizedBreakoutParticipants, activeTurn, nextTurn, activeRound, sortedInactiveTurns]);

   const { currentHost } = useMemo(() => {
      if (activeTurn && activeRound && activeRound.empathyMode === "reflective" && memoizedBreakoutParticipants && activeHostIdentity) {
         if (nextTurn) {
            if (activeTurn.speakerIdentity !== activeHostIdentity && activeTurn.listenerIdentity !== activeHostIdentity && nextTurn.listenerIdentity !== activeHostIdentity) {
               const host = memoizedBreakoutParticipants?.find((participant) => participant.identity === activeHostIdentity);

               return { currentHost: host };
            } else {
               return { currentHost: null };
            }
         } else if (activeTurn.speakerIdentity !== activeHostIdentity && activeTurn.listenerIdentity !== activeHostIdentity) {
            const host = memoizedBreakoutParticipants?.find((participant) => participant.identity === activeHostIdentity);
            return { currentHost: host };
         } else {
            return { currentHost: null };
         }
      } else if (activeRound && activeRound.empathyMode === "active" && activeTurn && activeHostIdentity && memoizedBreakoutParticipants) {
         if (
            sortedInactiveTurns.length >= 2 &&
            activeHostIdentity !== activeTurn.speakerIdentity &&
            activeHostIdentity !== sortedInactiveTurns[0]?.speakerIdentity &&
            activeHostIdentity !== sortedInactiveTurns[1]?.speakerIdentity
         ) {
            const host = memoizedBreakoutParticipants.find((participant) => participant.identity === activeHostIdentity);
            return { currentHost: host };
         } else if (sortedInactiveTurns.length === 1 && activeHostIdentity !== activeTurn.speakerIdentity && activeHostIdentity !== sortedInactiveTurns[0]?.speakerIdentity) {
            const host = memoizedBreakoutParticipants?.find((participant) => participant.identity === activeHostIdentity);
            return { currentHost: host };
         } else {
            return { currentHost: null };
         }
      } else {
         return { currentHost: null };
      }
   }, [currentSpeaker, currentListener, activeHostIdentity, nextTurn, activeTurn, nextSpeaker, thirdSpeaker, activeRound, sortedInactiveTurns, memoizedBreakoutParticipants]);

   const { paginatedParticipants } = useMemo(() => {
      const endOffset = itemOffset + itemsPerPage;
      if (!memoizedBreakoutParticipants) return { paginatedParticipants: [] };

      if (meeting.viewMode === "spotlight" && activeSpeaker) {
         // const currentParticipants = memoizedBreakoutParticipants.filter((p) => p.identity !== activeSpeaker.identity);
         // ILog.v("useRoundContext_spotlight1", { currentParticipants, activeSpeaker, memoizedBreakoutParticipants });
         const slicedParticipants = memoizedBreakoutParticipants.slice(itemOffset, endOffset);
         ILog.v("useRoundContext_spotlight2", { slicedParticipants });
         return { paginatedParticipants: slicedParticipants };
      } else if (activeRound && meeting.viewMode === "grid" && itemOffset === 0) {
         if (itemOffset === 0) {
            let currentParticipants = memoizedBreakoutParticipants.slice(itemOffset, endOffset);
            currentParticipants = currentParticipants.filter((p) => p.identity !== currentSpeaker?.identity && p.identity !== currentListener?.identity);
            // if (currentHost?.identity && currentHost?.identity !== currentSpeaker?.identity && currentHost?.identity !== currentListener?.identity) {
            //    currentParticipants.unshift(currentHost);
            // }
            if (currentListener?.identity) {
               currentParticipants.unshift(currentListener);
            }
            if (currentSpeaker?.identity) {
               currentParticipants.unshift(currentSpeaker);
            }
            // if currentParticipants is longer than itemsPerPage, slice it
            if (currentParticipants.length > itemsPerPage) {
               // currentParticipants = currentParticipants.slice(0, itemsPerPage);
            }
            ILog.v("useRoundContext", { currentParticipants });
            return { paginatedParticipants: currentParticipants };
         } else {
            let currentParticipants = memoizedBreakoutParticipants.slice(itemOffset, endOffset);
            currentParticipants = currentParticipants.filter((p) => p.identity !== currentSpeaker?.identity && p.identity !== currentListener?.identity);

            return { paginatedParticipants: currentParticipants };
         }
      } else {
         const currentParticipants = memoizedBreakoutParticipants?.slice(itemOffset, endOffset);
         return { paginatedParticipants: currentParticipants };
      }
   }, [
      memoizedBreakoutParticipants,
      meeting?.viewMode,
      itemOffset,
      itemsPerPage,
      activeRound,
      quietParticipants,
      activeTurn,
      currentHost,
      currentSpeaker,
      currentListener,
      nextListener,
      nextSpeaker,
      thirdSpeaker,
      screenShareParticipant,
      activeSpeaker,
      sortedInactiveTurns,
      localParticipant,
      activeHostIdentity,
      latestSpeakerIdentity,
      previousSpeakerIdentity,
      allListeningRequests,
      activeTurn?.listenerIdentity,
      activeTurn?.speakerIdentity,
      activeRound?.empathyMode,
      activeRound?.id
   ]);

   const handlePageIncrement = (event?: React.MouseEvent<HTMLButtonElement>) => {
      event?.preventDefault();
      ILog.v("handlePageIncrement", { itemOffset, itemsPerPage, memoizedBreakoutParticipants });
      if (memoizedBreakoutParticipants && itemOffset + itemsPerPage < memoizedBreakoutParticipants.length - 1) {
         const newOffset = itemOffset + itemsPerPage;
         setItemOffset(newOffset);
      }
   };

   const handlePageDecrement = (event?: React.MouseEvent<HTMLButtonElement>) => {
      event?.preventDefault();
      ILog.v("handlePageDecrement", { itemOffset, itemsPerPage, memoizedBreakoutParticipants });
      if (itemOffset - itemsPerPage >= 0) {
         const newOffset = itemOffset - itemsPerPage;
         setItemOffset(newOffset);
      }
   };

   const { listeningCount, gridCount, paginatedGridCount } = useMemo(() => {
      const countMap = [currentSpeaker, currentListener, nextListener, nextSpeaker, thirdSpeaker, currentHost];
      return {
         listeningCount: countMap.filter((p) => p?.identity).length,
         gridCount: paginatedParticipants?.length,
         paginatedGridCount: paginatedParticipants?.length
      };
   }, [currentSpeaker, currentListener, nextListener, nextSpeaker, thirdSpeaker, currentHost, paginatedParticipants]);

   useEffect(() => {
      if (paginatedGridCount === 0) {
         handlePageDecrement();
      }
   }, [paginatedGridCount, paginatedParticipants, itemOffset, itemsPerPage]);

   // With a useEffect, setSelectedPageNumber when itemOffset changes
   useEffect(() => {
      const pageNumber = Math.floor(itemOffset / itemsPerPage);
      if (pageNumber !== selectedPageNumber) {
         setSelectedPageNumber(pageNumber);
      }
      const _isLastPage = itemOffset + itemsPerPage >= (memoizedBreakoutParticipants?.length || 1) && pageCount > 1;
      if (_isLastPage !== isLastPage) {
         // setIsLastPage when itemOffset is the last page
         setIsLastPage(_isLastPage);
      }

      // setIsFirstPage when itemOffset is the first page
      const _isFirstPage = itemOffset === 0;
      if (_isFirstPage !== isFirstPage) {
         setIsFirstPage(_isFirstPage);
      }
   }, [itemOffset, itemsPerPage, memoizedBreakoutParticipants, selectedPageNumber, pageCount]);

   // Add this useEffect to update itemOffset when itemsPerPage changes
   useEffect(() => {
      if (itemOffset !== 0) {
         setItemOffset(0);
      }
   }, [itemsPerPage]);

   ILog.v("useRoundContext", {
      activeSpeaker,
      currentBreakout,
      currentSpeaker,
      currentListener,
      currentHost,
      nextListener,
      nextSpeaker,
      thirdSpeaker,
      participants: memoizedBreakoutParticipants,
      listeningCount,
      gridCount,
      paginatedGridCount,
      itemOffset,
      itemsPerPage,
      pageCount,
      handlePageIncrement,
      handlePageDecrement,
      screenShareParticipant,
      paginatedParticipants,
      activeRound,
      activeTurn,
      sortedInactiveTurns,
      allListeningRequests,
      viewMode: meeting.viewMode,
      isFirstPage
   });

   return {
      activeSpeaker,
      currentBreakout,
      currentSpeaker,
      currentListener,
      currentHost,
      nextListener,
      nextSpeaker,
      thirdSpeaker,
      participants: memoizedBreakoutParticipants,
      listeningCount,
      gridCount,
      paginatedGridCount,
      itemOffset,
      itemsPerPage,
      pageCount,
      handlePageIncrement,
      handlePageDecrement,
      screenShareParticipant,
      paginatedParticipants,
      activeRound,
      activeTurn,
      sortedInactiveTurns,
      allListeningRequests,
      viewMode: (isLocalRecorder && paginatedParticipants?.length > 4) || screenShareParticipant ? "spotlight" : meeting.viewMode,

      viewListeningGrid: activeRound && meeting.viewMode === "listening",
      selectedPageNumber,
      isLastPage,
      isFirstPage
   };
}
