import { IRootState, useAppStore } from "app/_contexts/ReduxProvider";
import { useIsMobile } from "app/_hooks/useIsMobile";
import ILog from "app/_lib/global/Log";
import { PublicEnv } from "app/_lib/global/PublicEnv";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocalStorage } from "usehooks-ts";

interface DragNavProps {
   leftSlot: React.ReactNode;
   rightSlot: React.ReactNode;
   dragKey: string;
   minWidth: number;
}

export default function LeftDragNav(props: DragNavProps) {
   const { dragKey, minWidth } = props;
   const [isDragging, setIsDragging] = useLocalStorage(dragKey, false);
   const leftPx = useSelector((state: IRootState) => state.app.leftNavPx);
   const leftNavOpen = useSelector((state: IRootState) => state.app.leftNavOpen || PublicEnv.AccordionOpenOverride === "true");
   const [startingLeftPx, setStartingLeftPx] = useState(0);
   const [endingLeftPx, setEndingLeftPx] = useState(0);
   const store = useAppStore();
   const { isMobile, width } = useIsMobile();

   useEffect(() => {
      ILog.v("LeftDragNav", { leftPx, leftNavOpen, isDragging, isMobile, width, style: leftNavOpen ? (isMobile ? `${width}px` : `${leftPx}px`) : undefined });
   }, [isMobile]);

   return (
      <div className={`flex flex-row ${PublicEnv.AccordionOpenOverride === "true" ? "h-[10000px]" : "h-screen"} `}>
         <div id="left-slot" style={{ width: leftNavOpen ? (isMobile ? `${width}px` : `${leftPx}px`) : undefined }} className={`${leftNavOpen ? "" : ""} border-r border-slate-200 h-full`}>
            {props.leftSlot}
         </div>
         {leftNavOpen && !isMobile && (
            <div
               id="left-drag"
               draggable={true}
               onPointerMove={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (!isDragging) return;

                  if (leftPx >= minWidth && e.clientX < minWidth) return;
                  if (e.clientX > window.innerWidth - minWidth) return;
                  setEndingLeftPx(e.clientX);
                  if (e.clientX === leftPx) return;
                  store.dispatch({ type: "app/setLeftNavPx", payload: e.clientX });
               }}
               onPointerUp={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.currentTarget.releasePointerCapture(e.pointerId);

                  setIsDragging(false);
                  store.dispatch({ type: "app/setIsDragging", payload: false });

                  setStartingLeftPx(0);
                  setEndingLeftPx(0);
                  if (endingLeftPx !== startingLeftPx) return;
                  ILog.v("LeftDragNav_onPointerUp", { e, bool: endingLeftPx === startingLeftPx });
                  store.dispatch({ type: "app/setLeftNavOpen", payload: !leftNavOpen });
               }}
               onPointerDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.currentTarget.setPointerCapture(e.pointerId);
                  setStartingLeftPx(e.clientX);
                  setEndingLeftPx(e.clientX);
                  ILog.v("LeftDragNav_onPointerDown", { e, timestamp: e.timeStamp });
                  e.currentTarget.setPointerCapture(e.pointerId);

                  setIsDragging(true);
                  store.dispatch({ type: "app/setIsDragging", payload: true });
               }}
               className={"h-full bg-slate-300 focused:bg-yellow-600 hover:bg-yellow-600 w-[10px] hover:cursor-ew-resize"}
            />
         )}

         <div id="right-slot" className={`w-full h-full ${leftNavOpen && isMobile ? "hidden" : ""}`}>
            {props.rightSlot}
         </div>
      </div>
   );
}
