export default function inputCalculation(value?: number) {
   if (value) {
      const minutes = Math.floor(value / 60);
      const seconds = value % 60;

      return { minutes, seconds };
   } else {
      const minutes = Math.floor(300 / 60);
      const seconds = 300 % 60;

      return { minutes, seconds };
   }
}
