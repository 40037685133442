import GavelRoundedIcon from "@mui/icons-material/GavelRounded";
import { MeetingContext } from "app/(platform)/meeting-room/_contexts/MeetingContext";
import { R } from "app/(platform)/meeting-room/_types";
import _Paragraph from "app/_components_v2/data-display/Paragraph";
import _Button from "app/_components_v2/inputs/_Button";
import _Stack from "app/_components_v2/layout/_Stack";
import { useContext, useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { BreakoutFormContext } from "../../_hooks/useBreakoutFormContext";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";

export default function Participants(props: { thisBreakout: R.Breakout.BreakoutRoom }) {
   const { thisBreakout } = props;
   const { roomParticipants } = useContext(MeetingContext);
   const {
      ParentForm: {
         RHF: {},
         UseFieldArray: { fields: allBreakouts },
         assignBreakout,
         assignFacilitator
      }
   } = useContext(BreakoutFormContext);
   const {
      control: participantControl,
      formState: { dirtyFields, touchedFields },
      reset,
      getValues
   } = useForm<{ participants: R.Breakout.FormBreakoutParticipantProps[] }>({
      defaultValues: {
         participants: thisBreakout.participants.map((identity) => {
            const participant = roomParticipants?.find((participant) => participant.identity === identity);
            return {
               identity: participant?.identity || "",
               name: participant?.name || "",
               breakoutId: thisBreakout.id
            };
         })
      }
   });

   const { fields, remove } = useFieldArray({
      control: participantControl,
      name: "participants",
      keyName: "participant-key"
   });

   useEffect(() => {
      const { participants } = getValues();
      if (participants?.length !== thisBreakout.participants.length) {
         reset({
            participants: thisBreakout.participants.map((identity) => {
               const participant = roomParticipants?.find((participant) => participant.identity === identity);
               return {
                  identity: participant?.identity || "",
                  name: participant?.name || "",
                  breakoutId: thisBreakout.id
               };
            })
         });
      }
   }, [thisBreakout.participants]);

   useEffect(() => {
      if (dirtyFields.participants) {
      }
   }, [dirtyFields, fields, touchedFields]);

   return (
      <_DivUNSAFE className="flex w-[100%] justify-center">
         <_DivUNSAFE className="grid grid-cols-1 gap-2 p-1">
            <_Stack padding={"xs"}>
               {fields.map((item, index) => {
                  return (
                     <_DivUNSAFE key={item.identity} className="grid grid-cols-2 grid-rows-2 gap-1 p-1 mt-2 bg-white rounded-md shadow-md border-slate-200">
                        <_DivUNSAFE className="flex row-start-1 row-end-2 justify-self-start">
                           <_Paragraph className="flex flex-wrap p-1 whitespace-normal place-self-center" label={item.name || item.identity} />
                        </_DivUNSAFE>

                        <_DivUNSAFE className="flex row-start-1 row-end-2 gap-3 p-1 flex-nowrap place-content-center place-items-center place-self-center">
                           <Controller
                              render={({ field: { onChange, value, ref } }) => (
                                 <select
                                    id={`participant.selectBreakout.${item.identity}`}
                                    data-testid={`participant-${item.name}-select`}
                                    ref={ref}
                                    className="mr-1 bg-white rounded-md border-slate-200 pr-9"
                                    onChange={(e) => onChange(assignBreakout(e, item.identity, thisBreakout.id))}
                                    defaultValue={value}
                                 >
                                    {allBreakouts.map((breakout, allBreakoutsIndex) => {
                                       return (
                                          <option
                                             id={`participant.selectBreakout.${breakout.id}`}
                                             data-testid={`select-participant-${item.name}-${allBreakoutsIndex}-option`}
                                             key={`participant.${item.identity}.selectBreakout.${breakout?.id}`}
                                             // selected={value === breakout.id}
                                             value={breakout.id}
                                          >{`${breakout.mainBreakout ? "Main Room" : `Breakout ${allBreakoutsIndex}`}`}</option>
                                       );
                                    })}
                                 </select>
                              )}
                              name={`participants.${index}.breakoutId`}
                              control={participantControl}
                           />
                        </_DivUNSAFE>

                        <_Button
                           variant={"primary"}
                           key={`participant.${item.identity}.selectFacilitator.${thisBreakout?.id}`}
                           testId={`assign-facilitator-${item.name}-button`}
                           className="flex flex-row col-start-1 col-end-3 row-start-2 row-end-3 gap-3 flex-nowrap place-self-center whitespace-nowrap"
                           onButtonClick={(e) => {
                              e.preventDefault();
                              const newFacilitator = thisBreakout?.facilitatorIdentity !== item?.identity;
                              assignFacilitator(item.identity, thisBreakout.id, newFacilitator);
                           }}
                        >
                           <_Paragraph className="place-self-center" label={`Breakout Facilitator`} />
                           <_DivUNSAFE className="flex w-6 h-6 border-2 rounded-md place-content-center border-slate-300">
                              {thisBreakout.facilitatorIdentity === item.identity && <GavelRoundedIcon className="flex place-self-center" style={{ height: "1rem", width: "1rem" }} />}
                           </_DivUNSAFE>
                        </_Button>
                     </_DivUNSAFE>
                  );
               })}
            </_Stack>
         </_DivUNSAFE>
      </_DivUNSAFE>
   );
}
