"use client";

import {
   ActiveHostIdentityContext,
   ActiveRecordingContext,
   BreakoutAudioTracksContext,
   BreakoutParticipantPropsContext,
   BreakoutParticipantsContext,
   CurrentBreakoutContext,
   FocusedRemoteParticipantsContext,
   IsLocalFacilitatorContext,
   IsLocalScreenShareContext,
   LatestSpeakerIdentityContext,
   PreviousSpeakerIdentityContext,
   ScreenShareIdentityContext,
   ScreenShareParticipantContext
} from "./(platform)/meeting-room/_contexts/BreakoutContext";
import { RoundContextProvider } from "./(platform)/meeting-room/_contexts/RoundContext";
import useBreakoutContext from "./(platform)/meeting-room/_hooks/useBreakoutContext";

export default function LivekitEnsureBreakoutContext({ children }: { children: React.ReactNode }) {
   const {
      activeHostIdentity,
      activeRecording,
      breakoutAudioTracks,
      breakoutParticipantProps,
      breakoutParticipants,
      currentBreakout,
      focusedRemoteParticipants,
      isLocalFacilitator,
      isLocalScreenShare,
      latestSpeakerIdentity,
      previousSpeakerIdentity,
      screenShareIdentity,
      screenShareParticipant
   } = useBreakoutContext();

   return (
      <ActiveHostIdentityContext.Provider value={activeHostIdentity}>
         <ActiveRecordingContext.Provider value={activeRecording}>
            <BreakoutAudioTracksContext.Provider value={breakoutAudioTracks}>
               <BreakoutParticipantPropsContext.Provider value={breakoutParticipantProps}>
                  <BreakoutParticipantsContext.Provider value={breakoutParticipants}>
                     <CurrentBreakoutContext.Provider value={currentBreakout}>
                        <FocusedRemoteParticipantsContext.Provider value={focusedRemoteParticipants}>
                           <IsLocalFacilitatorContext.Provider value={isLocalFacilitator}>
                              <IsLocalScreenShareContext.Provider value={isLocalScreenShare}>
                                 <LatestSpeakerIdentityContext.Provider value={latestSpeakerIdentity}>
                                    <PreviousSpeakerIdentityContext.Provider value={previousSpeakerIdentity}>
                                       <ScreenShareIdentityContext.Provider value={screenShareIdentity}>
                                          <ScreenShareParticipantContext.Provider value={screenShareParticipant}>
                                             <RoundContextProvider>{children}</RoundContextProvider>
                                          </ScreenShareParticipantContext.Provider>
                                       </ScreenShareIdentityContext.Provider>
                                    </PreviousSpeakerIdentityContext.Provider>
                                 </LatestSpeakerIdentityContext.Provider>
                              </IsLocalScreenShareContext.Provider>
                           </IsLocalFacilitatorContext.Provider>
                        </FocusedRemoteParticipantsContext.Provider>
                     </CurrentBreakoutContext.Provider>
                  </BreakoutParticipantsContext.Provider>
               </BreakoutParticipantPropsContext.Provider>
            </BreakoutAudioTracksContext.Provider>
         </ActiveRecordingContext.Provider>
      </ActiveHostIdentityContext.Provider>
   );
}
