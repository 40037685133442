import ILog from "app/_lib/global/Log";
import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { MeetingContext } from "../_contexts/MeetingContext";
import { selectAllBreakouts, selectBreakoutById, selectBreakoutByParticipantIdentity } from "../_services/breakoutSlice";
import useRoomMetadata from "./useRoomMetadata";
import { IRootState } from "app/_contexts/ReduxProvider";

export default function useSelectedBreakout() {
   const roomMetadata = useRoomMetadata();
   const { localParticipant, isLocalRecorder } = useContext(MeetingContext);
   const humanBreakout = useSelector((state: IRootState) => selectBreakoutByParticipantIdentity(state, localParticipant?.identity));
   const allBreakouts = useSelector((state: IRootState) => selectAllBreakouts(state));

   const recorderBreakoutId = useMemo(() => {
      if (isLocalRecorder === true && localParticipant?.identity && roomMetadata) {
         return JSON.parse(localParticipant.metadata || "{}")?.egress?.breakoutId;
      }
   }, [localParticipant?.identity, isLocalRecorder, roomMetadata]);

   const recorderBreakout = useSelector((state: IRootState) => selectBreakoutById(state, recorderBreakoutId));

   isLocalRecorder &&
      allBreakouts &&
      allBreakouts?.length > 0 &&
      ILog.v("useSelectedBreakout_FINAL", {
         isLocalRecorder,
         humanBreakout: humanBreakout,
         recorderBreakout,
         recorderBreakoutId,
         identity: localParticipant?.identity,
         roomMetadata,
         allBreakouts: JSON.stringify(allBreakouts)
      });
   return isLocalRecorder ? recorderBreakout : humanBreakout;
}
