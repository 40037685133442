"use client";
import { Participant, RemoteParticipant, Track } from "livekit-client";
import { useContext, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectAllBreakouts, selectCountActiveChildBreakouts, selectIsBreakoutFacilitator, selectMainBreakout } from "../_services/breakoutSlice";

import { TrackReference, useSpeakingParticipants, useTracks } from "@livekit/components-react";
import { R } from "app/(platform)/meeting-room/_types";
import { IRootState, useAppStore } from "app/_contexts/ReduxProvider";
import ILog from "app/_lib/global/Log";
import { apiNext } from "app/_services/redux/api/apiNext";
import { updateSpeakerOrder } from "../_actions/updateSpeakerOrder";
import { MeetingContext } from "../_contexts/MeetingContext";
import { ParticipantMetadata } from "../_types/metadata";
import useBreakoutConsistency from "./useBreakoutConsistency";
import useBreakoutRecorder from "./useBreakoutRecorder";
import useRoomMetadata from "./useRoomMetadata";
import useScreenSync from "./useScreenSync";
import useSelectedBreakout from "./useSelectedBreakout";

export type BreakoutContextType = {
   activeHostIdentity: string | undefined;
   activeRecording: ParticipantMetadata | undefined;
   breakoutAudioTracks: TrackReference[] | undefined;
   breakoutParticipantProps: R.Breakout.FormBreakoutParticipantProps[] | undefined;
   breakoutParticipants: Participant[] | undefined;
   currentBreakout: R.Breakout.BreakoutRoom | undefined;
   focusedRemoteParticipants: RemoteParticipant[] | undefined;
   isLocalFacilitator: boolean | undefined;
   isLocalScreenShare: boolean | undefined;
   latestSpeakerIdentity: string | undefined;
   previousSpeakerIdentity: string | undefined;
   screenShareIdentity: string | undefined;
   screenShareParticipant: Participant | undefined;
};

export type SpeakerOrderMapType = { identity: string; name: string };
export default function useBreakoutContext(): BreakoutContextType {
   const dispatch = useDispatch();
   const { roomParticipants, localParticipant, localHost, remoteParticipants, LK, isLocalRecorder, room } = useContext(MeetingContext);
   const roomMetadata = useRoomMetadata();
   const store = useAppStore();
   const selectedBreakout = useSelectedBreakout();
   const mainBreakout = useSelector((state: IRootState) => selectMainBreakout(state));
   const roomAudioTracks = useTracks([Track.Source.Microphone]);

   const isLocalFacilitator = useSelector((state: IRootState) =>
      selectIsBreakoutFacilitator(state, {
         targetIdentity: localParticipant?.identity,
         breakoutId: selectedBreakout?.id
      })
   );
   const activeChildBreakouts = useSelector((state: IRootState) => selectCountActiveChildBreakouts(state));

   const { activeBreakoutRecorderMetadata } = useBreakoutRecorder({ breakoutId: selectedBreakout?.id, localParticipant, isLocalRecorder });

   const { activeBreakoutRecorderMetadata: mainRoomRecorderMetadata } = useBreakoutRecorder({
      breakoutId: mainBreakout?.id,
      localParticipant,
      isLocalRecorder
   });

   const { currentBreakout, breakoutParticipants, breakoutAudioTracks, focusedRemoteParticipants, allowedSubscribers } = useMemo(() => {
      if (!mainBreakout && localHost) {
         const focusedRemoteParticipants = (roomParticipants.filter((p) => p.identity !== localParticipant?.identity) || []) as RemoteParticipant[];
         const { remoteAudioTracks, allowedSubscribers } = LK.Breakout({}).getTrackReferences({
            focusedParticipants: focusedRemoteParticipants,
            focusedRecorder: undefined,
            trackReferences: roomAudioTracks
         });
         return {
            currentBreakout: undefined,
            breakoutParticipants: roomParticipants,
            breakoutAudioTracks: remoteAudioTracks,
            focusedRemoteParticipants: remoteParticipants,
            allowedSubscribers
         };
      } else if (activeChildBreakouts === 0 && mainBreakout && !isLocalRecorder) {
         // When breakouts are paused, AND when there are no breakout child rooms.
         const focusedRemoteParticipants = (roomParticipants.filter((p) => p.identity !== localParticipant?.identity) || []) as RemoteParticipant[];
         const { remoteAudioTracks, allowedSubscribers } = LK.Breakout({ breakout: mainBreakout }).getTrackReferences({
            focusedParticipants: focusedRemoteParticipants,
            focusedRecorder: mainRoomRecorderMetadata,
            trackReferences: roomAudioTracks
         });
         isLocalRecorder && ILog.v("useBreakoutContext", "Main_breakout_context", { mainBreakout, mainRoomRecorderParticipant: mainRoomRecorderMetadata, focusedRemoteParticipants });

         return {
            currentBreakout: mainBreakout,
            breakoutParticipants: roomParticipants,
            breakoutAudioTracks: remoteAudioTracks,
            focusedRemoteParticipants: remoteParticipants,
            allowedSubscribers
         };
      } else if (selectedBreakout) {
         const breakoutParticipants = roomParticipants.filter((rp) => selectedBreakout.participants.find((p) => p === rp.identity));
         const focusedRemoteParticipants = (breakoutParticipants?.filter((p) => p.identity !== localParticipant?.identity) || []) as RemoteParticipant[];

         const { remoteAudioTracks, allowedSubscribers } = LK.Breakout({ breakout: selectedBreakout }).getTrackReferences({
            focusedParticipants: focusedRemoteParticipants,
            focusedRecorder: isLocalRecorder ? undefined : activeBreakoutRecorderMetadata,
            trackReferences: roomAudioTracks
         });
         isLocalRecorder &&
            ILog.v("useBreakoutContext", "Selected_breakout_context", {
               selectedBreakout,
               localHost,
               roomParticipants,
               localParticipant,
               remoteParticipants,
               remoteAudioTracks
            });

         return {
            currentBreakout: selectedBreakout,
            breakoutParticipants: breakoutParticipants,
            focusedRemoteParticipants: focusedRemoteParticipants,
            breakoutAudioTracks: remoteAudioTracks,
            allowedSubscribers
         };
      }
      isLocalRecorder &&
         ILog.v("useBreakoutContext", "No_breakout_context", {
            mainBreakout,
            localHost,
            roomParticipants,
            localParticipant,
            activeChildBreakouts,
            selectedBreakout,
            allBreakouts: selectAllBreakouts(store.getState())
         });

      // return {
      //    currentBreakout: mainBreakout,
      //    breakoutParticipants: roomParticipants,
      //    breakoutAudioTracks: roomAudioTracks,
      //    focusedRemoteParticipants: remoteParticipants
      //    // ignoredRemoteParticipants: [],
      //    // allowedSubscribers: roomParticipants
      // };
      return {
         currentBreakout: undefined,
         breakoutParticipants: [],
         breakoutAudioTracks: [],
         focusedRemoteParticipants: [],
         allowedSubscribers: []
      };
   }, [
      selectedBreakout,
      mainBreakout,
      localHost,
      roomAudioTracks,
      roomParticipants,
      remoteParticipants,
      activeBreakoutRecorderMetadata,
      mainRoomRecorderMetadata,
      activeChildBreakouts,
      isLocalRecorder,
      roomMetadata,
      localParticipant
   ]);

   useEffect(() => {
      if (allowedSubscribers && allowedSubscribers.length > 0) {
         LK.Breakout({}).subscribeTracks({ allowedSubscribers, focusedRemoteParticipants });
      }
   }, [breakoutAudioTracks, focusedRemoteParticipants, breakoutParticipants, currentBreakout, allowedSubscribers]);

   const { screenShareParticipant, isLocalScreenShare } = useMemo(() => {
      const ssID = currentBreakout?.screenShareIdentity;
      const screenShareParticipant = breakoutParticipants?.find((participant) => participant.identity === ssID);
      const isLocalScreenShare = currentBreakout?.screenShareIdentity === localParticipant?.identity;
      if (isLocalScreenShare) {
         const activeScreenShares = breakoutParticipants?.filter((participant) => participant.isScreenShareEnabled);
         !activeScreenShares?.length &&
            dispatch({
               type: "breakout/setScreenShareIdentity",
               payload: {
                  id: currentBreakout?.id,
                  identity: undefined
               }
            });
      }
      return { screenShareParticipant, isLocalScreenShare };

      // return { screenShareParticipant: undefined, isLocalScreenShare: false };
   }, [currentBreakout?.screenShareIdentity, breakoutParticipants, roomParticipants]);

   const mainBreakoutCreatedRef = useRef(false);
   useEffect(() => {
      if (mainBreakout && !mainBreakoutCreatedRef.current) {
         mainBreakoutCreatedRef.current = true;
      }
      if (localHost && !mainBreakout && !currentBreakout && roomParticipants?.length && localParticipant && mainBreakoutCreatedRef.current === false) {
         mainBreakoutCreatedRef.current = true;
         ILog.v("useBreakoutContext", "Creating main breakout", { roomParticipants, localParticipant });
         LK.Breakout({}).create({ participants: roomParticipants });
      }
   }, [mainBreakout, localHost, roomParticipants, localParticipant, currentBreakout, LK]);

   const breakoutParticipantProps = useMemo(() => {
      if (!currentBreakout || !breakoutParticipants || !LK) return [];
      return LK.normalizeParticipantProps(breakoutParticipants, currentBreakout?.id);
   }, [breakoutParticipants, currentBreakout, LK]);

   useBreakoutConsistency();
   useScreenSync();

   const speakingParticipants = useSpeakingParticipants();
   const speakingOrderRef = useRef<Map<string, SpeakerOrderMapType>>(new Map());
   const [action, {}] = apiNext.useActionMutation();

   useEffect(() => {
      if (speakingParticipants && speakingParticipants.length && isLocalRecorder && localParticipant && room) {
         const speakersInBreakout = speakingParticipants.filter((participant) => breakoutParticipants?.find((p) => p.identity === participant.identity));
         if (!speakersInBreakout.length) return;
         let shouldDispatch = false;
         speakersInBreakout.forEach((participant, index) => {
            const existingEntry = speakingOrderRef.current.get(participant.identity);
            if (existingEntry) return;
            speakingOrderRef.current.set(participant.identity, { identity: participant.identity, name: participant.name || "Guest" });
            shouldDispatch = true;
         });
         if (shouldDispatch) {
            action(async () => {
               const recorderMetadata = JSON.parse(localParticipant.metadata || "{}") as ParticipantMetadata;
               const res = await updateSpeakerOrder({ meetingId: room.name, newOrder: Array.from(speakingOrderRef.current.values()), sectionAssetID: recorderMetadata.egress?.sectionAssetID! });
               return res;
            });
         }
      }
   }, [speakingParticipants, isLocalRecorder, localParticipant, room, breakoutParticipants]);

   isLocalRecorder &&
      ILog.v("useBreakoutContext", "Breakout_context_FINAL", {
         isLocalRecorder: isLocalRecorder,
         roomMetadata: roomMetadata,
         selectedBreakout: selectedBreakout!!,
         mainBreakout: mainBreakout!!,
         activeChildBreakouts: activeChildBreakouts,
         activeBreakoutRecorderParticipant: activeBreakoutRecorderMetadata,
         mainRoomRecorderParticipant: mainRoomRecorderMetadata,
         return: {
            currentBreakout,
            screenShareIdentity: currentBreakout?.screenShareIdentity,
            screenShareParticipant,
            isLocalScreenShare,
            activeHostIdentity: currentBreakout?.activeHostIdentity,
            latestSpeakerIdentity: currentBreakout?.latestSpeakerIdentity,
            previousSpeakerIdentity: currentBreakout?.previousSpeakerIdentity,
            breakoutAudioTracks: breakoutAudioTracks,
            breakoutParticipants: breakoutParticipants,
            breakoutParticipantProps: breakoutParticipantProps,
            focusedRemoteParticipants: focusedRemoteParticipants,
            isLocalFacilitator
         }
      });
   return {
      activeRecording: activeBreakoutRecorderMetadata,
      currentBreakout,
      screenShareIdentity: currentBreakout?.screenShareIdentity,
      screenShareParticipant,
      isLocalScreenShare,
      activeHostIdentity: currentBreakout?.activeHostIdentity,
      latestSpeakerIdentity: currentBreakout?.latestSpeakerIdentity,
      previousSpeakerIdentity: currentBreakout?.previousSpeakerIdentity,
      breakoutAudioTracks,
      breakoutParticipants,
      breakoutParticipantProps,
      focusedRemoteParticipants,
      isLocalFacilitator
   };
}
