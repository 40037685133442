import { AuthStatusContext } from "app/(platform)/(authentication)/_contexts/AuthenticatedSessionProvider";
import useAllCDURoleQueries from "app/(platform)/workspaces/[campaign_id]/_hooks/useAllCDUQueries";
import { IRootState } from "app/_contexts/ReduxProvider";
import ILog from "app/_lib/global/Log";
import { PublicEnv } from "app/_lib/global/PublicEnv";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { MeetingContext } from "../_contexts/MeetingContext";
import { selectIsBreakoutFacilitator } from "../_services/breakoutSlice";
import useRoomMetadata from "./useRoomMetadata";
import useSelectedBreakout from "./useSelectedBreakout";

export default function useScreenSync() {
   const router = useRouter();
   const { localParticipant, LK, isLocalRecorder } = useContext(MeetingContext);
   const roomMetadata = useRoomMetadata();
   const { status } = useContext(AuthStatusContext);
   const selectedBreakout = useSelectedBreakout();
   const { connectionRole } = useAllCDURoleQueries({ campaignId: roomMetadata?.meetingCampaignId });
   const isLocalFacilitator = useSelector((state: IRootState) =>
      selectIsBreakoutFacilitator(state, {
         targetIdentity: localParticipant?.identity,
         breakoutId: selectedBreakout?.id
      })
   );
   const inviteURLWithToken = useSelector((state: IRootState) => state.meeting.workspaceInviteToken);
   const pathname = usePathname();
   const params = useSearchParams();

   useEffect(() => {
      if (PublicEnv.AccordionOpenOverride === "true") return;
      if (isLocalFacilitator && selectedBreakout) {
         const url = `${pathname}${params.toString() ? `?${params.toString()}` : ""}`;
         // const validURL = isPotluckURL(url);
         if (url !== selectedBreakout.screenSyncPath && !url.includes("meeting-room")) {
            ILog.v("useScreenSync", "sending new path", { url });
            LK.Breakout({}).setScreenSyncPath({ breakoutId: selectedBreakout.id, screenSyncPath: url });
         } else {
         }
      }
   }, [pathname, params, selectedBreakout, isLocalFacilitator]);

   useEffect(() => {
      if (isLocalRecorder === true || isLocalRecorder === undefined) {
         return;
      }
      if (
         !!selectedBreakout &&
         !!selectedBreakout?.screenSyncPath &&
         pathname !== selectedBreakout.screenSyncPath &&
         status === "authenticated" &&
         !selectedBreakout.screenSyncPath.includes("meeting-room")
      ) {
         ILog.v("useScreenSync", "Redirecting to screen sync path", { selectedBreakout });
         router.push(selectedBreakout.screenSyncPath);
      } else if (!connectionRole && !!inviteURLWithToken) {
         ILog.v("useScreenSync", "Redirecting to invite token", { inviteURLWithToken });
         router.push(inviteURLWithToken);
      }
   }, [selectedBreakout?.screenSyncPath, inviteURLWithToken, connectionRole, isLocalRecorder]);
}
