import { Query } from "@directus/sdk";
import { current } from "@reduxjs/toolkit";
import { getMySession } from "app/(platform)/(authentication)/_helpers/getMySession";
import { apiWS } from "app/(platform)/@search/_api/apiWS";
import meetingPassword from "app/(platform)/meeting-room/_actions/meetingPassword";
import { tryDirectusWS } from "app/_lib/directus/ws";
import ILog from "app/_lib/global/Log";
import { REST } from "app/_services/rest/REST";
import { PA } from "app/_types/PATypes";
import { M } from "app/_types/Schema";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { TAGS } from "../../../../../_services/redux/api/TAGS";
import { handleRTKError, handleRTKTags } from "../../../../../_services/redux/api/_helpers/rtkHelpers";
import { apiService } from "../../../../../_services/redux/api/apiService";
import inviteEmailToMeeting from "../_actions/meetingInvite";
export const apiMeeting = apiService.injectEndpoints({
   endpoints: (builder) => ({
      getManyMeetingWS: builder.query<
         M.Meeting[],
         {
            query: Query<M.CustomDirectusTypes, M.Meeting[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
         }
      >({
         extraOptions: {
            maxRetries: 3
         },
         queryFn: async (arg, api, extraOptions, baseQuery) => {
            try {
               if (!arg.role) throw new Error("no token, roleId, or role found");
               const res = (await api
                  .dispatch(
                     apiWS.endpoints.emitWSMessage.initiate({
                        role: arg.role,
                        message: {
                           type: "items",
                           action: "read",
                           collection: "meeting",
                           query: arg.query
                        }
                     })
                  )
                  .unwrap()) as M.Meeting[] | null;

               if (!res) throw new Error("No data found");

               return { data: res };
            } catch (e) {
               return handleRTKError({ error: e, endpoint: "apiMeeting_getManyWS" });
            }
         },

         async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getCacheEntry, extra, dispatch }) {
            await tryDirectusWS<M.Meeting>({
               dispatch,
               cacheDataLoaded,
               role: arg.role,
               getCacheEntry,
               collection: "meeting",
               query: arg.query,
               onUpdateCachedData(message) {
                  const { type, data, uid, event, status } = message;
                  if (event === "init" && data?.length > 0) {
                     // updateCachedData((draft) => {
                     //    return data;
                     // });
                  } else if (event === "create") {
                     updateCachedData((draft) => {
                        // const updated = data;

                        let entries = draft?.filter((d) => !data.some((u) => u.id === d.id));
                        ILog.v("updateCachedData create", { data, draft: current(draft), entries });
                        entries?.push(...data);
                        return entries;
                     });
                  } else if (event === "update") {
                     updateCachedData((draft) => {
                        let entries = draft?.filter((d) => !data.some((u) => u.id === d.id));
                        entries?.push(...data);
                        ILog.v("updateCachedData update", { entries, data, draft: current(draft) });
                        return entries;
                        // draft?.filter((d) => !data.some((u) => u.id === d.id));
                        // //@ts-expect-error
                        // return draft?.push(...data);
                        // // return entries;
                     });
                  } else if (event === "delete") {
                     updateCachedData((draft) => {
                        let entries = draft?.filter((r) => !(data as unknown as string[]).includes(r?.id!));
                        ILog.v("updateCachedData delete", { data, draft: current(draft) });
                        return entries;
                        // ILog.v("updateCachedData delete-FINAL", { data, draft: current(draft) });
                     });
                  }
               },
               uidArgs: "",
               cacheEntryRemoved
            });
         },

         providesTags: (result, error, query) => {
            return handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.MEETING, includeList: true }]
            });
         }
      }),
      getOneMeeting: builder.query<
         M.Meeting | null,
         {
            query?: Query<M.CustomDirectusTypes, M.Meeting[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
            id?: string;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(`getOneMeeting No token provided`);
               const res = await REST.MeetingService.getOne({
                  token,
                  query: arg.query,
                  id: arg.id
               });
               if (!res) throw new Error("Meeting not found");
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "apiMeeting_getOne" });
            }
         },

         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.MEETING, includeList: true }]
            })
      }),
      getManyMeeting: builder.query<
         M.Meeting[],
         {
            query: Query<M.CustomDirectusTypes, M.Meeting[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               const res = await REST.MeetingService.getMany({
                  token: token,
                  query: arg.query
               });
               //ILog.v("getManyMeeting", { res });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "getManyMeeting" });
            }
         },

         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.MEETING, includeList: true }]
            })
      }),
      getManyMeetingLoop: builder.query<
         M.Meeting[],
         {
            query: Query<M.CustomDirectusTypes, M.Meeting[]>;
            roles: PA.RoleQueries[] | undefined;
            token?: string | undefined;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               if (!arg.roles) throw new Error("Roles not provided");
               let data = [] as M.Meeting[];
               for (const role of arg.roles) {
                  const { token } = await getMySession({ role: role, tokenOverride: arg.token, dispatch: api.dispatch });
                  const res = await REST.MeetingService.getMany({
                     token: token,
                     query: arg.query
                  });
                  if (res.length > 0) data = [...data, ...res];
               }

               return { data };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "getManyMeetingLoop" });
            }
         },

         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.MEETING, includeList: true }]
            })
      }),
      updateMeetingById: builder.mutation<M.Meeting, { role: PA.RoleQueries | undefined; token?: string | undefined; id: string; payload: Partial<M.Meeting> }>({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const response = await REST.MeetingService.updateById({ token, id: arg.id, payload: arg.payload });
               return { data: response };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "updateMeetingById" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({ result, error, query, tagSelectors: [{ collectionTag: TAGS.MEETING, includeList: true }] })
      }),
      createMeeting: builder.mutation<M.Meeting, { router?: AppRouterInstance; role: PA.RoleQueries | undefined; token?: string | undefined; payload: M.IOmit<M.Meeting> }>({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               ILog.v("createMeeting", { token, payload: arg.payload });
               const res = await REST.MeetingService.create({ token, payload: arg.payload });
               if (arg?.router) {
                  arg.router.push(`/workspaces/${arg.payload.meeting_campaign}/meetings/${res.id}`);
               }
               const passwordRes = await meetingPassword({ meetingID: res.id, regenerate: true, tokenOverride: token, userIdOverride: undefined });

               if (!passwordRes?.data?.meeting_password) throw new Error("Meeting password not found");
               // const password = (await REST.MeetingService.generateMeetingPassword({ tokenOverride: token!, meetingId: res.id! })) as PA.Flows.Livekit.MeetingPassword["Res"];
               return { data: { ...res, meeting_password: passwordRes.data.meeting_password } };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "createMeeting" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.MEETING, includeList: true }]
            })
      }),
      generateMeetingPassword: builder.mutation<PA.Flows.Livekit.MeetingPassword["Res"], { role: PA.RoleQueries | undefined; token?: string | undefined; meetingId: string | undefined }>({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const res = (await REST.MeetingService.generateMeetingPassword({ tokenOverride: token!, meetingId: arg.meetingId! })) as PA.Flows.Livekit.MeetingPassword["Res"];
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "generateMeetingPassword" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               resultTags: result && [
                  { type: TAGS.MEETING, id: "LIST" },
                  { type: TAGS.MEETING, id: query.meetingId || "" }
               ]
            })
      }),
      inviteEmailsToMeeting: builder.mutation<null, { role: PA.RoleQueries | undefined; token?: string | undefined; csvEmails: string; campaignId: string; meetingId: string }>({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const res = await inviteEmailToMeeting(arg);

               return { data: null };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "inviteEmailsToMeeting" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               resultTags: result
                  ? [
                       { type: TAGS.MEETING, id: "LIST" },
                       { type: TAGS.MEETING, id: query.meetingId || "" }
                    ]
                  : []
            })
      })
   }),
   overrideExisting: true
});
