import { useContext } from "react";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import { MeetingContext } from "../../_contexts/MeetingContext";
import { selectIncompleteAgendaItemsMainBreakout } from "../../_services/agendaItemSlice";
import { selectChildBreakouts, selectCountActiveChildBreakouts } from "../../_services/breakoutSlice";

import { BreakoutFormContext } from "../../_hooks/useBreakoutFormContext";

import Header from "app/_components_v2/data-display/Header";
import _Icon from "app/_components_v2/data-display/_Icon";
import DefaultXIcon from "app/_components_v2/icons/DefaultXIcon";
import _Button from "app/_components_v2/inputs/_Button";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";
import _Stack from "app/_components_v2/layout/_Stack";
import ErrorBase from "app/_components_v2/todo/Error";
import ILog from "app/_lib/global/Log";
import BreakoutBase from ".";
import { IRootState } from "app/_contexts/ReduxProvider";
export default function Form() {
   const { LK } = useContext(MeetingContext);
   const { Success, ParentForm, EditingState } = useContext(BreakoutFormContext);
   const { fields } = ParentForm.UseFieldArray;
   const childBreakouts = useSelector((state: IRootState) => selectChildBreakouts(state));
   const activeBreakoutCount = useSelector((state: IRootState) => selectCountActiveChildBreakouts(state));
   const allAgendaItems = useSelector((state: IRootState) => selectIncompleteAgendaItemsMainBreakout(state));
   ILog.v("BreakoutForm", { fields, childBreakouts, activeBreakoutCount, allAgendaItems });
   return (
      <_Stack gap="none" className=" text-slate-900">
         <_Stack gap="none">
            <_Stack gap="none" flexBehavior="row">
               {activeBreakoutCount >= 1 && (
                  <_Button
                     variant="primary"
                     testId={`pause-breakout-rooms-button`}
                     onButtonClick={() => {
                        LK.Breakout({}).allPause();
                     }}
                     // className="p-2 m-1 rounded-lg cursor-pointer bg-slate-300 hover:drop-shadow-sm"
                  >
                     Pause Breakout Rooms
                  </_Button>
               )}
               {childBreakouts && childBreakouts.length > 0 && activeBreakoutCount === 0 && (
                  <_Button
                     variant="primary"
                     testId={`resume-breakout-rooms-button`}
                     onButtonClick={() => {
                        LK.Breakout({}).allResume();
                     }}
                     // className="p-2 rounded-md bg-slate-300 hover:cursor-pointer hover:drop-shadow-sm"
                  >
                     Resume Breakout Rooms
                  </_Button>
               )}
            </_Stack>
            {activeBreakoutCount >= 1 && <BreakoutBase.Message />}
         </_Stack>

         <form className="grid grid-cols-1 gap-2 p-1" onSubmit={ParentForm.RHF.handleSubmit(ParentForm.onSubmit, ParentForm.onError)} data-form-index={`formBreakout`} data-testid={`breakout-form`}>
            <_Stack gap="none">
               <ul className="p-1">
                  {fields.map((item, index) => {
                     return (
                        <li key={item.id} className="mt-2 mb-2 border-b-4 border-slate-900">
                           <_Stack flexBehavior={"row"} alignment={"between"}>
                              <Header level="h2" className="text-lg" label={`${item.mainBreakout ? "Main Room" : `Breakout ${index}`}`}></Header>
                              {!item.mainBreakout && (
                                 <_Button
                                    variant="primary"
                                    className="m-1 cursor-pointer -shadow"
                                    type="button"
                                    testId={`breakout-remove-room-button-${index}`}
                                    onButtonClick={() => ParentForm.removeBreakout(index)}
                                 >
                                    <_Icon variant={"transparent"} size="sm">
                                       {({ iconClassname }) => <DefaultXIcon />}
                                    </_Icon>
                                 </_Button>
                              )}
                           </_Stack>
                           {!item.mainBreakout && (
                              <_DivUNSAFE className="flex flex-col gap-2 mt-2 mb-2">
                                 {allAgendaItems.map((a) => (
                                    <BreakoutBase.Agenda key={`${a.breakoutId}-${a.id}-breakout-base-agenda-item`} agendaItem={a} breakoutRoom={item} index={index} />
                                 ))}
                              </_DivUNSAFE>
                           )}
                           <_DivUNSAFE className="mb-2">{item.participants && <BreakoutBase.Participants thisBreakout={item} />}</_DivUNSAFE>
                        </li>
                     );
                  })}
               </ul>
               <_Stack className=" justify-center">
                  <_Button
                     variant="primary"
                     type="button"
                     id="buttonAddBreakoutRoom"
                     testId="breakout-add-room-button"
                     className="p-4 m-4 rounded-lg cursor-pointer duration bg-slate-300 hover:drop-shadow-sm"
                     onButtonClick={() => {
                        ParentForm.UseFieldArray.append({
                           id: v4(),
                           participants: [],
                           agendaPaused: false,
                           agendaStarted: false,
                           active: false,
                           facilitatorIdentity: undefined,
                           mainBreakout: false,
                           roomOpen: false,
                           roomLocked: false,
                           closed: false,
                           touchedIdentities: [],
                           agendaItems: [],
                           screenSyncPath: null
                        });
                     }}
                  >
                     Add Breakout Room
                  </_Button>
               </_Stack>
               {ParentForm.RHF.formState.errors && <ErrorBase.Many errors={ParentForm.RHF.formState.errors} />}
               {EditingState.editing && (
                  <_DivUNSAFE className="grid content-between grid-cols-2 grid-rows-1">
                     <_Button variant="primary" testId="breakout-form-cancel" type="reset" size={"md"} onButtonClick={ParentForm.handleReset}>
                        Cancel
                     </_Button>
                     <_Button variant="primary" id="breakout-form-submit" testId="breakout-form-submit" size={"md"} type="submit" onButtonClick={undefined}>
                        Submit
                     </_Button>
                  </_DivUNSAFE>
               )}
            </_Stack>
         </form>
      </_Stack>
   );
}
