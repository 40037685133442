"use client";
import { TrackReference } from "@livekit/components-core";
import { R } from "app/(platform)/meeting-room/_types";
import { Participant, RemoteParticipant } from "livekit-client";
import { createContext, useContext } from "react";
import { ParticipantMetadata } from "../_types/metadata";

// Define individual types
export type ActiveHostIdentityType = string | undefined;
export type ActiveRecordingType = ParticipantMetadata | undefined;
export type BreakoutAudioTracksType = TrackReference[] | undefined;
export type BreakoutParticipantPropsType = R.Breakout.FormBreakoutParticipantProps[] | undefined;
export type BreakoutParticipantsType = Participant[] | undefined;
export type CurrentBreakoutType = R.Breakout.BreakoutRoom | undefined;
export type FocusedRemoteParticipantsType = RemoteParticipant[] | undefined;
export type IsLocalFacilitatorType = boolean | undefined;
export type IsLocalScreenShareType = boolean | undefined;
export type LatestSpeakerIdentityType = string | undefined;
export type PreviousSpeakerIdentityType = string | undefined;
export type ScreenShareIdentityType = string | undefined;
export type ScreenShareParticipantType = Participant | undefined;

// Create contexts for each type
export const ActiveHostIdentityContext = createContext<ActiveHostIdentityType>(undefined);
export const ActiveRecordingContext = createContext<ActiveRecordingType>(undefined);
export const BreakoutAudioTracksContext = createContext<BreakoutAudioTracksType>(undefined);
export const BreakoutParticipantPropsContext = createContext<BreakoutParticipantPropsType>([]);
export const BreakoutParticipantsContext = createContext<BreakoutParticipantsType>([]);
export const CurrentBreakoutContext = createContext<CurrentBreakoutType>(undefined);
export const FocusedRemoteParticipantsContext = createContext<FocusedRemoteParticipantsType>(undefined);
export const IsLocalFacilitatorContext = createContext<IsLocalFacilitatorType>(undefined);
export const IsLocalScreenShareContext = createContext<IsLocalScreenShareType>(undefined);
export const LatestSpeakerIdentityContext = createContext<LatestSpeakerIdentityType>(undefined);
export const PreviousSpeakerIdentityContext = createContext<PreviousSpeakerIdentityType>(undefined);
export const ScreenShareIdentityContext = createContext<ScreenShareIdentityType>(undefined);
export const ScreenShareParticipantContext = createContext<ScreenShareParticipantType>(undefined);

// Create hooks for each context
export const useActiveHostIdentity = () => useContext(ActiveHostIdentityContext);
export const useActiveRecording = () => useContext(ActiveRecordingContext);
export const useBreakoutAudioTracks = () => useContext(BreakoutAudioTracksContext);
export const useBreakoutParticipantProps = () => useContext(BreakoutParticipantPropsContext);
export const useBreakoutParticipants = () => useContext(BreakoutParticipantsContext);
export const useCurrentBreakout = () => useContext(CurrentBreakoutContext);
export const useFocusedRemoteParticipants = () => useContext(FocusedRemoteParticipantsContext);
export const useIsLocalFacilitator = () => useContext(IsLocalFacilitatorContext);
export const useIsLocalScreenShare = () => useContext(IsLocalScreenShareContext);
export const useLatestSpeakerIdentity = () => useContext(LatestSpeakerIdentityContext);
export const usePreviousSpeakerIdentity = () => useContext(PreviousSpeakerIdentityContext);
export const useScreenShareIdentity = () => useContext(ScreenShareIdentityContext);
export const useScreenShareParticipant = () => useContext(ScreenShareParticipantContext);
