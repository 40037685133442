import { SuccessBase } from "app/_components_v2/todo/Success";
import { useSuccessRefresh } from "app/_hooks/useSuccessRefresh";
import ILog from "app/_lib/global/Log";
import React, { useContext } from "react";
import { MeetingContext } from "../../_contexts/MeetingContext";
import BreakoutAgenda from "./BreakoutAgenda";
import Card from "./Card";
import Context from "./Context";
import Form from "./Form";
import List from "./List";
import Message from "./Message";
import Participants from "./Participants";

function BreakoutBase() {
   const { localHost } = useContext(MeetingContext);

   const success = useSuccessRefresh();
   ILog.v("BreakoutBase", { success });
   return (
      <React.Fragment>
         {localHost ? (
            <React.Fragment>
               {(success.count === 5 || success.count === 0) && <BreakoutBase.Context success={success} />}
               {success.successCount < 5 && <SuccessBase />}
            </React.Fragment>
         ) : (
            <BreakoutBase.List />
         )}
      </React.Fragment>
   );
}

/*
TODO:

Render Join button if roomOpen

Breakouts are Paused indicator


*/

BreakoutBase.Form = Form;
BreakoutBase.Card = Card;
BreakoutBase.List = List;
BreakoutBase.Participants = Participants;
BreakoutBase.Agenda = BreakoutAgenda;
BreakoutBase.Message = Message;
BreakoutBase.Context = Context;

export default BreakoutBase;
