import ILog from "app/_lib/global/Log";

export default function outputCaluation(event: any) {
   event.preventDefault();
   const inputValue = event.target.value;
   const eventForm = event.target.form;

   const selectId = event.target.id;
   const index = selectId.split(".")[0];
   const selectType = selectId.split(".")[1];

   ILog.v("outputCalculation", { inputValue, selectId, index, selectType });

   if (selectType === "speaker-minutes-select") {
      const siblingSelectId = `${index}.speaker-seconds-select`; // get the id of the sibling select element
      const selectSpeakerSeconds = eventForm[siblingSelectId].value; // get the sibling select element
      const output = inputValue * 60 + selectSpeakerSeconds * 1;

      return output;
   } else if (selectType === "speaker-seconds-select") {
      const siblingSelectId = `${index}.speaker-minutes-select`; // get the id of the sibling select element
      const selectSpeakerMinutes = eventForm[siblingSelectId].value; // get the sibling select element
      const output = inputValue * 1 + selectSpeakerMinutes * 60;

      return output;
   } else if (selectType === "intermission-minutes-select") {
      const siblingSelectId = `${index}.intermission-seconds-select`; // get the id of the sibling select element
      const selectIntermissionSeconds = eventForm[siblingSelectId].value; // get the sibling select element
      const output = inputValue * 60 + selectIntermissionSeconds * 1;

      return output;
   } else if (selectType === "intermission-seconds-select") {
      const siblingSelectId = `${index}.intermission-minutes-select`; // get the id of the sibling select element
      const selectIntermissionMinutes = eventForm[siblingSelectId].value; // get the sibling select element
      const output = inputValue * 1 + selectIntermissionMinutes * 60;

      return output;
   }
}
