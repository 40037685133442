"use client";
import { R } from "app/(platform)/meeting-room/_types";
import Header from "app/_components_v2/data-display/Header";
import _Paragraph from "app/_components_v2/data-display/Paragraph";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";
import _Stack from "app/_components_v2/layout/_Stack";
import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useBreakoutParticipants, useCurrentBreakout, useIsLocalFacilitator } from "../../_contexts/BreakoutContext";
import { MeetingContext } from "../../_contexts/MeetingContext";
import { selectReflectiveListenersByTurn } from "../../_services/listeningRequestSlice";
import { selectActiveRound } from "../../_services/roundSlice";
import { selectReflectiveTurnBySpeakerIdentity, selectTurnWithoutListener, selectTurnsByRoundId } from "../../_services/turnSlice";
import ButtonSendListeningRequest from "../button/ButtonSendListeningRequest";
import SelectListeningRequest from "../button/SelectListeningRequest";
import CardListeningRequest from "../card/CardListeningRequest";
import { IRootState } from "app/_contexts/ReduxProvider";

export default function ContainerTurns(props: { roundIndex?: number }) {
   const { roundIndex } = props;

   const currentBreakout = useCurrentBreakout();
   const isLocalFacilitator = useIsLocalFacilitator();
   const breakoutParticipants = useBreakoutParticipants();

   const activeRound = useSelector((state: IRootState) => selectActiveRound(state, currentBreakout?.id));
   const { localParticipant } = useContext(MeetingContext);
   const activeRoundTurns = useSelector((state: IRootState) => selectTurnsByRoundId(state, activeRound?.id));
   const speakerTurn = useSelector((state: IRootState) =>
      selectReflectiveTurnBySpeakerIdentity(state, {
         speakerIdentity: localParticipant?.identity,
         breakoutId: currentBreakout?.id
      })
   );
   const withoutListener = useSelector((state: IRootState) =>
      selectTurnWithoutListener(state, {
         participantIdentity: isLocalFacilitator && localParticipant?.identity !== speakerTurn?.speakerIdentity ? speakerTurn?.speakerIdentity : localParticipant?.identity,
         breakoutId: currentBreakout?.id
      })
   );

   const { availableListeners, availableFinalListeners, requestSent, listeningRequestsForThisTurn } = useSelector((state: IRootState) =>
      selectReflectiveListenersByTurn(state, {
         targetTurn: speakerTurn,
         speakerIdentity: isLocalFacilitator && localParticipant?.identity !== speakerTurn?.speakerIdentity ? speakerTurn?.speakerIdentity : localParticipant?.identity,
         participants: currentBreakout?.participants
      })
   );

   const listeners = useMemo(() => {
      if (!breakoutParticipants) return undefined;
      if (availableListeners && availableListeners.length > 0) {
         return breakoutParticipants.filter((participant) => availableListeners.includes(participant.identity));
      } else if (availableFinalListeners && availableFinalListeners.length > 0) {
         return breakoutParticipants.filter((participant) => availableFinalListeners.includes(participant.identity));
      }
   }, [availableListeners, availableFinalListeners, breakoutParticipants]);

   if (speakerTurn && withoutListener) {
      return (
         <_DivUNSAFE className="flex flex-col items-center justify-center w-full h-full gap-1 overflow-y-auto ">
            <Header
               level="h2"
               className="mb-2 text-lg text-slate-900"
               label={
                  availableListeners.length === 0 && availableFinalListeners && availableFinalListeners.length > 0
                     ? "Choose Final Listener"
                     : availableListeners.length === 0 && availableFinalListeners.length === 0
                       ? "All Sent!"
                       : "Choose Available Listener"
               }
            />

            {listeningRequestsForThisTurn &&
               listeningRequestsForThisTurn.length > 0 &&
               listeningRequestsForThisTurn.map((listeningRequest: R.ListeningRequest.ListeningRequest) => {
                  return <CardListeningRequest key={`${listeningRequest.id}-card`} listeningRequest={listeningRequest} />;
               })}
            {listeners &&
               listeners.length > 0 &&
               listeners.map((participant) => {
                  if (!participant) return null;
                  return <ButtonSendListeningRequest key={participant?.identity} speakerTurn={speakerTurn} listener={participant} />;
               })}
            {!speakerTurn.listenerName && isLocalFacilitator && activeRound?.empathyMode === "reflective" && (
               <_Stack alignment={"startCenter"} flexBehavior={"row"} wrap={false} key={speakerTurn?.id} padding={"md"}>
                  <_Paragraph wrap={false} label={`${speakerTurn.speakerName} and ${speakerTurn?.speakerName && speakerTurn?.listenerName ? `${speakerTurn.listenerName}` : ""}`} />
                  <SelectListeningRequest speakerTurn={speakerTurn} />
               </_Stack>
            )}
         </_DivUNSAFE>
      );
   } else if (activeRound !== undefined && activeRound.roundActive) {
      return (
         <_DivUNSAFE className="flex flex-col w-full h-full gap-1 overflow-y-auto ">
            <Header label={`${roundIndex ? `Turns for Round ${roundIndex + 1}` : `Turns for this Round`}`} className="text-slate-900" level="h3" />
            {activeRoundTurns.length > 0 &&
               activeRoundTurns.map((turn) => (
                  <_Stack alignment={"startCenter"} flexBehavior={"row"} wrap={false} key={turn?.id} padding={"md"}>
                     <_Paragraph wrap={false} label={`${turn.speakerName} and ${turn?.speakerName && turn?.listenerName ? ` ${turn.listenerName}` : ""}`} />
                     {!turn.listenerName && isLocalFacilitator && activeRound.empathyMode === "reflective" && <SelectListeningRequest speakerTurn={turn} />}
                  </_Stack>
               ))}
         </_DivUNSAFE>
      );
   }

   return (
      <_DivUNSAFE className="flex flex-col w-full h-full gap-1 overflow-y-auto ">
         <Header className="text-slate-900" label={`No Listening Requests.`} level="h3" />
      </_DivUNSAFE>
   );
}
