import { MeetingContext } from "app/(platform)/meeting-room/_contexts/MeetingContext";
import { selectReflectiveListenersByTurn } from "app/(platform)/meeting-room/_services/listeningRequestSlice";
import { selectTurnWithoutListener } from "app/(platform)/meeting-room/_services/turnSlice";
import { R } from "app/(platform)/meeting-room/_types";
import _Select from "app/_components_v2/inputs/_Select";
import { DateTime } from "luxon";
import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import { useBreakoutParticipants, useCurrentBreakout } from "../../_contexts/BreakoutContext";
import { IRootState } from "app/_contexts/ReduxProvider";

export default function SelectListeningRequest(props: { speakerTurn: R.Turn.Turn }) {
   const { speakerTurn } = props;
   const { localParticipant, LK, localHost } = useContext(MeetingContext);
   const currentBreakout = useCurrentBreakout();
   const breakoutParticipants = useBreakoutParticipants();
   const withoutListener = useSelector((state: IRootState) =>
      selectTurnWithoutListener(state, {
         participantIdentity: speakerTurn?.speakerIdentity,
         breakoutId: currentBreakout?.id
      })
   );

   const { availableListeners, availableFinalListeners, requestSent, listeningRequestsForThisTurn } = useSelector((state: IRootState) =>
      selectReflectiveListenersByTurn(state, {
         targetTurn: speakerTurn,
         speakerIdentity: speakerTurn?.speakerIdentity,
         participants: currentBreakout?.participants
      })
   );

   const listeners = useMemo(() => {
      if (!breakoutParticipants) return [];
      if (availableListeners && availableListeners.length > 0) {
         return breakoutParticipants.filter((participant) => availableListeners.includes(participant.identity));
      } else if (availableFinalListeners && availableFinalListeners.length > 0) {
         return breakoutParticipants.filter((participant) => availableFinalListeners.includes(participant.identity));
      }
      return [];
   }, [availableListeners, availableFinalListeners, breakoutParticipants]);

   const finalSpeaker = useMemo(() => {
      if (speakerTurn && speakerTurn.speakerIdentity && breakoutParticipants) {
         return breakoutParticipants.find((participant) => participant.identity === speakerTurn.speakerIdentity);
      }
   }, [speakerTurn, breakoutParticipants, speakerTurn?.speakerIdentity]);

   const Options = useMemo(() => {
      const validOptions = listeners.filter((listener) => {
         return !!listener.identity;
      });
      return validOptions.map((listener) => {
         return { label: listener.name || "Guest", value: listener.identity, testId: `listener-${listener.name}-option` };
      });
   }, [listeners]);
   if (!speakerTurn || !finalSpeaker || !Options.length) return null;
   return (
      <_Select
         width={"full"}
         testId="listener-select"
         onChange={(e) => {
            e.preventDefault();
            const selected = listeners.find((listener) => listener.identity === e.target.value);
            if (selected) {
               if (!selected.name || !localParticipant?.name || !currentBreakout?.id) throw new Error("Error: No listener name, localParticipant name, or breakoutId");

               const LR = LK.ListeningRequest({
                  listeningRequest: {
                     id: v4(),
                     turnId: speakerTurn.id,
                     speakerIdentity: finalSpeaker?.identity,
                     //@ts-expect-error
                     speakerName: finalSpeaker?.name,
                     listenerIdentity: selected.identity,
                     listenerName: selected.name,
                     listenerAccepted: true,
                     timeAccepted: DateTime.now().toMillis(),
                     void: false,
                     breakoutId: currentBreakout?.id
                  }
               }).sendListeningRequest();
               if (localHost) {
                  LR.voidRemainingListeningRequests(speakerTurn.id);
               }
            }
         }}
         label={"Choose listener"}
         // value={values.campaign_visibility}
         options={Options}
      />
   );
}
