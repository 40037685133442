"use client";
import { useContext, useEffect } from "react";

import { apiRoom } from "app/(platform)/meeting-room/_api/apiRoom";
import _Button from "app/_components_v2/inputs/_Button";
import ILog from "app/_lib/global/Log";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useActiveRecording, useCurrentBreakout } from "../../_contexts/BreakoutContext";
import { MeetingContext } from "../../_contexts/MeetingContext";
import { IRootState } from "app/_contexts/ReduxProvider";

export default function ButtonRecord() {
   const { room, localHost, LK, localCoHost } = useContext(MeetingContext);

   const meetingId = room?.name;
   const currentBreakout = useCurrentBreakout();
   const activeRecording = useActiveRecording();

   const [toggleRecord, { data: recordData, isLoading: recordIsFetching, isSuccess: recordSuccess, reset: removeRecord, originalArgs, isError, requestId }] = apiRoom.useToggleRecordMutation();
   const token = useSelector((state: IRootState) => state.meeting.livekitToken);
   const isRecording = activeRecording !== undefined;

   const handleToggleRecord = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      if (!meetingId || !currentBreakout?.id || !token) {
         ILog.v("Missing required data to start recording.", {
            meetingId,
            breakoutId: currentBreakout?.id,
            token
         });
         return;
      }
      ILog.v("ButtonRecord", { meetingId, breakoutId: currentBreakout.id, isRecording, token });
      toggleRecord({
         token,
         payload: {
            meeting_id: meetingId,
            action: isRecording ? "stop" : "start",
            breakout_id: currentBreakout.id
         }
      });
      LK.sendDataPacket({
         payload: {
            type: "RECORDING_EVENT",
            payload: {
               toastId: `recording-${currentBreakout?.id}`,
               action: isRecording ? "stop" : "start",
               breakoutId: currentBreakout.id,
               message: isRecording ? "" : "Meeting recording will begin shortly."
            }
         }
      });
      if (!isRecording) {
         toast("Meeting recording will begin shortly.", {
            toastId: `recording-${currentBreakout?.id}`
         });
      }
   };
   useEffect(() => {
      if (isError) {
         toast.error(`Error: Unable to ${originalArgs?.payload.action} recording.`, {
            toastId: `recording-request-${requestId}`
         });
      }
   }, [isError]);

   useEffect(() => {
      if (isRecording) {
         toast.dismiss(`recording-${currentBreakout?.id}`);
      }
   }, [isRecording]);

   const stoppingRecording = originalArgs?.payload.action === "stop" && (isRecording || recordIsFetching) && !isError;
   const startingRecording = originalArgs?.payload.action === "start" && (!isRecording || recordIsFetching) && !isError;
   ILog.v("ButtonRecord", { isRecording, stoppingRecording, startingRecording, originalArgs, recordIsFetching, isError });
   if (localHost || localCoHost) {
      return (
         <_Button
            variant="primary"
            testId={`${isRecording ? "stop-recording-button" : "start-recording-button"}`}
            type="button"
            onButtonClick={handleToggleRecord}
            disabled={stoppingRecording || startingRecording}
         >
            {stoppingRecording ? "Stopping..." : startingRecording ? "Starting..." : isRecording ? "Stop Recording" : "Start Recording Meeting"}
         </_Button>
      );
   }
}
