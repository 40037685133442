import React from "react";
import { useMemo } from "react";
import { useCountdown } from "usehooks-ts";

export interface SuccessRefreshType {
    count: number;
    successCount: number;
    startCountdown: () => void;
    stopCountdown: () => void;
    resetCountdown: () => void;
    successStartCountdown: () => void;
    successStopCountdown: () => void;
    successResetCountdown: () => void;

}
export const SuccessRefreshContext = React.createContext<SuccessRefreshType>({} as SuccessRefreshType);

// TODO -- add params for all the numbers
export function useSuccessRefresh() {
   const [count, { startCountdown, stopCountdown, resetCountdown }] = useCountdown({
      countStart: 5,
      countStop: 0,
      intervalMs: 5
   });

   const [successCount, { startCountdown: successStartCountdown, stopCountdown: successStopCountdown, resetCountdown: successResetCountdown }] = useCountdown({
      countStart: 5,
      countStop: 0,
      intervalMs: 1000
   });
   useMemo(() => {
      if (count === 4) {
         successStartCountdown();
      }
      if (successCount === 0) {
         successStopCountdown();
         successResetCountdown();
      }
   }, [count, successCount]);

    return {
        count,
        successCount,
        startCountdown,
        stopCountdown,
        resetCountdown,
        successStartCountdown,
        successStopCountdown,
        successResetCountdown
    } as SuccessRefreshType;
}
