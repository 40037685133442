import { CheckIcon } from "@heroicons/react/24/solid";
import _Paragraph from "../data-display/Paragraph";

export function SuccessBase() {
   return (
      <div className="flex flex-row gap-3 p-3 transition duration-1000 ease-out bg-green-100 rounded-md place-self-center text-slate-900">
         <_Paragraph label={`Success`} className="place-self-center" />
         <CheckIcon className="h-8 text-slate-800 shrink-0 place-self-center" />
      </div>
   );
}
